// Arrow
.swiper-button-prev,
.swiper-button-next {
  cursor                      : pointer;
  user-select                 : none;
  outline                     : 0;
  -webkit-tap-highlight-color : transparent;
  flex                        : 0 0 46px;
  display                     : inline-block;
  vertical-align              : top;
  transition                  : opacity .3s ease;
  position                    : absolute;
  top                         : 50%;
  transform                   : translateY(-50%);
  z-index                     : 10;
  @media screen and (max-width : 600px) {
	top    : auto;
	bottom : 15px;
  }

  &:after {
	content  : '';
	position : absolute;
	top      : 0;
	left     : 0;
  }

  @media screen and (min-width : 1076px) {
	&:hover {
	  opacity : .7;
	}
  }

  &.swiper-button-disabled {
	opacity        : 0.3;
	cursor         : auto;
	pointer-events : none;
  }
}

.swiper-button-prev {
  left : 30px;
  @include svg-swiper-button-prev;
  @media screen and (max-width : 600px) {
	left : 15px;
	@include svg-swiper-button-prev-s;
  }
}

.swiper-button-next {
  right : 30px;
  @include svg-swiper-button-next;
  @media screen and (max-width : 600px) {
	right : 15px;
	@include svg-swiper-button-next-s;
  }
}

.slider__pagination {
  display         : flex;
  flex-flow       : row nowrap;
  justify-content : space-between;
}