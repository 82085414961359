@charset "UTF-8";
.clean-list,
.p0 {
  padding : 0
}

.smart-forms .ribbon-inner, body {
  -webkit-backface-visibility : hidden
}

a {
  text-decoration : none !important
}

.img-full {
  width : 100% !important
}

.clean-list li {
  display         : inline-block;
  list-style-type : none
}

input[type=text] {
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none
}

.black,
.black a {
  color : #000
}

.white,
.white a {
  color : #FFF
}

.gray-12,
.gray-12 a {
  color : #898989
}

.brown,
.brown a {
  color : #13395e
}

.bgbrown {
  background-color : #13395e
}

.bgwhite {
  background-color : #FFF
}

.bggray {
  background-color : #6D6D6D
}

@for $i from 0 through 5 {
  .op#{2 * $i} {
	opacity : 0.2 * $i;
  }
}


@for $i from 1 through 10 {
  .letter1#{$i} {
	letter-spacing : $i + px;
  }
}

@for $i from 1 through 20 {
  .fs#{10 + 2 * $i} {
	font-size : (10 + 2 * $i) + px;
  }
}

@for $i from 0 through 8 {
  .m#{5 * $i} {
	margin : 5px * $i;
  }
  .mt#{5 * $i} {
	margin-top : 5px * $i;
  }
  .mb#{5 * $i} {
	margin-bottom : 5px * $i;
  }
  .ml#{5 * $i} {
	margin-left : 5px * $i;
  }
  .mr#{5 * $i} {
	margin-right : 5px * $i;
  }
  .p#{5 * $i} {
	padding : 5px * $i;
  }
  .pt#{5 * $i} {
	padding-top : 5px * $i;
  }
  .pb#{5 * $i} {
	padding-bottom : 5px * $i;
  }
  .pl#{5 * $i} {
	padding-left : 5px * $i;
  }
  .pr#{5 * $i} {
	padding-right : 5px * $i;
  }
}


.mt100 {
  margin-top : 100px
}

.mt80 {
  margin-top : 80px
}

.mt70 {
  margin-top : 70px
}

.mt60 {
  margin-top : 60px
}

.mt-200 {
  margin-top : -200px
}

.fs45 {
  font-size : 45px
}

@media (min-width : 576px) {
  @for $i from 0 through 5 {
	.sm-op#{2 * $i} {
	  opacity : 0.2 * $i;
	}
  }

  @for $i from 1 through 10 {
	.sm-letter#{$i} {
	  letter-spacing : 1px * $i;
	}
  }
  @for $i from 1 through 20 {
	.sm-fs#{10 + 2 * $i} {
	  font-size : 10px + 2px * $i;
	}
  }
  @for $i from 0 through 8 {
	.sm-m#{5 * $i} {
	  margin : 5px * $i;
	}
	.sm-mt#{5 * $i} {
	  margin-top : 5px * $i;
	}
	.sm-mb#{5 * $i} {
	  margin-bottom : 5px * $i;
	}
	.sm-ml#{5 * $i} {
	  margin-left : 5px * $i;
	}
	.sm-mr#{5 * $i} {
	  margin-right : 5px * $i;
	}
	.sm-p#{5 * $i} {
	  padding : 5px * $i;
	}
	.sm-pt#{5 * $i} {
	  padding-top : 5px * $i;
	}
	.sm-pb#{5 * $i} {
	  padding-bottom : 5px * $i;
	}
	.sm-pl#{5 * $i} {
	  padding-left : 5px * $i;
	}
	.sm-pr#{5 * $i} {
	  padding-right : 5px * $i;
	}
  }
}

@media (min-width : 768px) {
  @for $i from 0 through 5 {
	.md-op#{2 * $i} {
	  opacity : 0.2 * $i;
	}
  }

  @for $i from 1 through 10 {
	.md-letter1#{$i} {
	  letter-spacing : 1px * $i;
	}
  }
  @for $i from 1 through 20 {
	.md-fs#{10 + 2 * $i} {
	  font-size : 10px + 2px * $i;
	}
  }
  @for $i from 0 through 8 {
	.md-m#{5 * $i} {
	  margin : 5px * $i;
	}
	.md-mt#{5 * $i} {
	  margin-top : 5px * $i;
	}
	.md-mb#{5 * $i} {
	  margin-bottom : 5px * $i;
	}
	.md-ml#{5 * $i} {
	  margin-left : 5px * $i;
	}
	.md-mr#{5 * $i} {
	  margin-right : 5px * $i;
	}
	.md-p#{5 * $i} {
	  padding : 5px * $i;
	}
	.md-pt#{5 * $i} {
	  padding-top : 5px * $i;
	}
	.md-pb#{5 * $i} {
	  padding-bottom : 5px * $i;
	}
	.md-pl#{5 * $i} {
	  padding-left : 5px * $i;
	}
	.md-pr#{5 * $i} {
	  padding-right : 5px * $i;
	}
  }
}

@media (min-width : 992px) {
  @for $i from 0 through 5 {
	.lg-op#{2 * $i} {
	  opacity : 0.2 * $i;
	}
  }

  @for $i from 1 through 10 {
	.lg-letter1#{$i} {
	  letter-spacing : 1px * $i;
	}
  }
  @for $i from 1 through 20 {
	.lg-fs#{10 + 2 * $i} {
	  font-size : 10px + 2px * $i;
	}
  }
  @for $i from 0 through 8 {
	.lg-m#{5 * $i} {
	  margin : 5px * $i;
	}
	.lg-mt#{5 * $i} {
	  margin-top : 5px * $i;
	}
	.lg-mb#{5 * $i} {
	  margin-bottom : 5px * $i;
	}
	.lg-ml#{5 * $i} {
	  margin-left : 5px * $i;
	}
	.lg-mr#{5 * $i} {
	  margin-right : 5px * $i;
	}
	.lg-p#{5 * $i} {
	  padding : 5px * $i;
	}
	.lg-pt#{5 * $i} {
	  padding-top : 5px * $i;
	}
	.lg-pb#{5 * $i} {
	  padding-bottom : 5px * $i;
	}
	.lg-pl#{5 * $i} {
	  padding-left : 5px * $i;
	}
	.lg-pr#{5 * $i} {
	  padding-right : 5px * $i;
	}
  }
}

@media (min-width : 1200px) {
  @for $i from 0 through 5 {
	.xl-op#{2 * $i} {
	  opacity : 0.2 * $i;
	}
  }

  @for $i from 1 through 10 {
	.xl-letter1#{$i} {
	  letter-spacing : 1px * $i;
	}
  }
  @for $i from 1 through 20 {
	.xl-fs#{10 + 2 * $i} {
	  font-size : 10px + 2px * $i;
	}
  }
  @for $i from 0 through 8 {
	.xl-m#{5 * $i} {
	  margin : 5px * $i;
	}
	.xl-mt#{5 * $i} {
	  margin-top : 5px * $i;
	}
	.xl-mb#{5 * $i} {
	  margin-bottom : 5px * $i;
	}
	.xl-ml#{5 * $i} {
	  margin-left : 5px * $i;
	}
	.xl-mr#{5 * $i} {
	  margin-right : 5px * $i;
	}
	.xl-p#{5 * $i} {
	  padding : 5px * $i;
	}
	.xl-pt#{5 * $i} {
	  padding-top : 5px * $i;
	}
	.xl-pb#{5 * $i} {
	  padding-bottom : 5px * $i;
	}
	.xl-pl#{5 * $i} {
	  padding-left : 5px * $i;
	}
	.xl-pr#{5 * $i} {
	  padding-right : 5px * $i;
	}
  }

}

.animated {
  animation-duration  : calc(1s);
  animation-fill-mode : both;

  &.infinite {
	animation-iteration-count : infinite
  }

  &.hinge {
	animation-duration : calc(1s * 2)
  }

  &.bounceIn,
  &.bounceOut,
  &.flipOutX,
  &.flipOutY {
	animation-duration : calc(1s * .75)
  }

  &.bounceIn,
  &.bounceOut,
  &.flipOutX,
  &.flipOutY {
	animation-duration : calc(1s * .75)
  }
}


@-webkit-keyframes fadeIn {
  0% {
	opacity : 0
  }
  100% {
	opacity : 1
  }
}

@keyframes fadeIn {
  0% {
	opacity : 0
  }
  100% {
	opacity : 1
  }
}

.fadeIn {
  animation-name : fadeIn
}

.smart-wrap {
  padding : 0 20px
}

.smart-forms,
.smart-forms * {
  box-sizing : border-box
}

.smart-container {
  background : #FFF;
  margin     : 30px auto;
  box-shadow : 0 1px 5px rgba(0, 0, 0, .65)
}

.wrap-0 {
  max-width : 952px
}

.wrap-1 {
  max-width : 852px
}

.wrap-2 {
  max-width : 652px
}

.wrap-3 {
  max-width : 452px
}

.smart-flat,
.smart-flat .form-header {
  border-radius : 0
}

.smart-forms {
  line-height : 1.231;
  font-weight : 400;
  font-size   : 14px;
  color       : #34495E;

  .gui-input:focus ~ .field-icon i,
  .gui-textarea:focus ~ .field-icon i,
  .select > select:focus + .arrow,
  .smart-link {
	color : #C5B893
  }

  .section {
	margin-bottom : 22px
  }

  .smart-link {
	text-decoration : none;

	&:hover {
	  text-decoration : underline
	}
  }

  .tagline {
	height     : 0;
	border-top : 1px solid #D9DDE5;
	text-align : center;

	span {
	  text-transform : uppercase;
	  display        : inline-block;
	  position       : relative;
	  padding        : 0 15px;
	  background     : #FFF;
	  top            : -10px
	}
  }

  .field {
	display  : block;
	position : relative;

	i {
	  color    : #A2A6A8;
	  position : relative;
	  top      : 0
	}
  }

  .field-label {
	display       : block;
	margin-bottom : 7px;

	&.colm {
	  padding-top : 12px
	}

	em {
	  color       : #E74C3C;
	  font-size   : 14px;
	  font-style  : normal;
	  display     : inline-block;
	  margin-left : 4px;
	  position    : relative;
	  top         : 3px
	}
  }

  .form-header {
	overflow : hidden;
	position : relative;
	padding  : 25px 30px;

	h4 {
	  text-align  : left;
	  font-weight : 300;
	  font-size   : 32px;
	  padding     : 0;
	  margin      : 0;

	  i {
		font-size    : 38px;
		position     : relative;
		margin-right : 10px;
		top          : 2px
	  }
	}
  }

  .header-primary {
	background-color : #C5B893;
	border-top       : 1px solid #23E0BA;
	border-bottom    : 5px solid #16A085;

	h4 {
	  color : #FFF
	}
  }

  .header-lite {
	background    : #F3F5FA;
	border-top    : 1px solid #23E0BA;
	border-bottom : 1px solid #D9DDE5;

	&:before {
	  content          : "";
	  background-color : #C5B893;
	  position         : absolute;
	  height           : 8px;
	  z-index          : 1;
	  top              : 0;
	  right            : 0;
	  left             : 0
	}

	h4 {
	  color       : #5D6A87;
	  padding-top : 5px
	}
  }

  .form-body {
	padding : 40px 30px 20px
  }

  .form-footer {
	overflow : hidden;
	padding  : 25px 25px 20px
  }

  input[type=search] {
	-webkit-appearance : textfield
  }

  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration {
	-webkit-appearance : none
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
	color : #919DA8
  }

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
	color : #D6DBE0
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
	color : #919DA8
  }

  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
	color : #D6DBE0
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
	color   : #919DA8;
	opacity : 1
  }

  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
	color   : #D6DBE0;
	opacity : 1
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
	color : #919DA8
  }

  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
	color : #D6DBE0
  }

  input,
  label,
  select,
  textarea {
	margin      : 0;
	font-size   : 14px;
	font-weight : 400;
	color       : #34495E;
	outline     : 0
  }

  .gui-input,
  .gui-textarea,
  .select-multiple select,
  .select > select,
  input[type=button],
  input[type=submit],
  input[type=search] {
	-webkit-tap-highlight-color : transparent;
	-webkit-border-radius       : 0;
	border-radius               : 0;
	-webkit-appearance          : none;
	-moz-appearance             : none;
	appearance                  : none
  }

  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
	display : none
  }

  .field-label,
  .option,
  .switch {
	font-size : 14px
  }

  .checkbox,
  .radio,
  .switch > label {
	-webkit-user-select : none;
	-moz-user-select    : none;
	user-select         : none
  }

  .checkbox,
  .gui-input,
  .gui-textarea,
  .notification,
  .radio,
  .select-multiple select,
  .select > select,
  .tooltip,
  input[type=search].gui-input {
	transition : all .5s ease-in-out;
	outline    : 0
  }

  .gui-input,
  .gui-textarea,
  .select,
  .select-multiple select,
  .select > select {
	background     : #FFF;
	position       : relative;
	vertical-align : top;
	border         : 2px solid #BDC3C7;
	display        : inline-block;
	color          : #34495E;
	outline        : 0;
	height         : 42px;
	width          : 100%
  }

  .gui-input,
  .gui-textarea {
	padding : 10px
  }

  .gui-textarea {
	resize      : none;
	line-height : 19px;
	overflow    : auto;
	max-width   : 100%;
	height      : 96px
  }

  .input-hint {
	padding       : 10px;
	display       : block;
	margin-top    : -4px;
	line-height   : 16px;
	position      : relative;
	background    : #F2F4FA;
	border        : 2px solid #BDC3C7;
	border-top    : 1px solid #BDC3C7;
	font-family   : Arial, Helvetica, sans-serif;
	border-radius : 0 0 3px 3px;
	font-size     : 11px;
	color         : #777
  }

  .select {
	border  : 0;
	z-index : 10;

	& > select {
	  display            : block;
	  padding            : 9px 10px;
	  -webkit-appearance : none;
	  -moz-appearance    : none;
	  appearance         : none;
	  outline            : 0;
	  text-indent        : .01px;
	  text-overflow      : '';
	  z-index            : 10;
	  margin             : 0;

	  &::-ms-expand {
		display : none
	  }
	}

	&:after,
	&:before {
	  content        : '';
	  position       : absolute;
	  pointer-events : none;
	  z-index        : 15
	}

	&:before {
	  top           : 2px;
	  right         : 2px;
	  width         : 34px;
	  bottom        : 2px;
	  background    : #F2F4FA;
	  border-left   : 1px solid #D3DAE7;
	  border-radius : 0 1px 1px 0
	}

	.arrow {
	  position       : absolute;
	  top            : 9px;
	  right          : 5px;
	  width          : 24px;
	  height         : 24px;
	  background     : #F2F4FA;
	  color          : #B5C1C7;
	  pointer-events : none;
	  z-index        : 16;

	  &:after,
	  &:before {
		content        : '';
		position       : absolute;
		font           : 12px Consolas, monospace;
		font-style     : normal;
		pointer-events : none;
		left           : 6px
	  }
	}
  }

  .file {
	display : block;
	width   : 100%;

	.gui-file {
	  width    : 100%;
	  height   : 100%;
	  cursor   : pointer;
	  padding  : 8px 10px;
	  position : absolute;
	  opacity  : 0;
	  z-index  : 11;
	  bottom   : 0;
	  right    : 0
	}

	.button {
	  position    : absolute;
	  top         : 4px;
	  right       : 4px;
	  float       : none;
	  height      : 34px;
	  line-height : 34px;
	  padding     : 0 16px;
	  z-index     : 10
	}
  }

  .file .gui-file:hover + .gui-input,
  .gui-input:focus,
  .gui-input:hover,
  .gui-input:hover ~ .input-hint,
  .gui-textarea:focus,
  .gui-textarea:hover,
  .gui-textarea:hover ~ .input-hint,
  .select-multiple select:focus,
  .select-multiple select:hover,
  .select > select:focus,
  .select > select:hover {
	border-color : #C5B893
  }

  .gui-textarea:focus {
	height : 120px
  }

  .select > select:focus {
	z-index : 10;
  }

  .file .gui-file:focus + .gui-input,
  .gui-input:focus ~ .input-hint,
  .gui-textarea:focus ~ .input-hint,
  .select-multiple select:focus {
	border-color : #C5B893
  }

  .option {
	position       : relative;
	padding-right  : 15px;
	display        : inline-block;
	vertical-align : middle;

	& > input {
	  position : absolute;
	  height   : inherit;
	  width    : inherit;
	  opacity  : 0;
	  left     : 0
	}
  }

  .checkbox,
  .radio {
	position     : relative;
	margin-right : 2px;
	background   : #F2F2F2;
	display      : inline-block;
	border       : 1px solid #E9E9E9;
	height       : 21px;
	width        : 21px;
	top          : 5px;

	&:before,
	&:before {
	  content : '';
	  display : none
	}
  }

  input:checked + .checkbox,
  input:checked + .radio {
	border : 1px solid #C5B893;

	&:before,
	&:before {
	  display : block
	}

  }

  .checkbox {
	&:before {
	  position     : absolute;
	  top          : 5px;
	  left         : 5px;
	  width        : 8px;
	  height       : 4px;
	  border       : solid #C5B893;
	  border-width : 0 0 2px 2px;
	  transform    : rotate(-45deg)
	}
  }

  input:focus,
  input:hover {
	& + .checkbox,
	& + .checkbox:before,
	& + .radio:before,
	& + .radio {
	  border-color : #C5B893
	}
  }

  .radio {
	border-radius : 20px;

	&:before {
	  margin        : 4px;
	  width         : 7px;
	  height        : 7px;
	  background    : #C5B893;
	  border-radius : 10px
	}
  }

  .switch {
	cursor        : pointer;
	position      : relative;
	padding-right : 10px;
	display       : inline-block;
	margin-bottom : 5px;
	height        : 26px;

	& > label {
	  cursor         : pointer;
	  display        : inline-block;
	  position       : relative;
	  height         : 25px;
	  width          : 58px;
	  color          : #FFF;
	  font-size      : 10px;
	  font-weight    : 700;
	  line-height    : 20px;
	  text-align     : center;
	  background     : #B5C1C7;
	  border         : 2px solid #B5C1C7;
	  text-transform : uppercase;
	  font-family    : Helvetica, Arial, sans-serif;
	  transition     : .3s ease-out;
	  border-radius  : 2px;

	  & + span {
		display      : inline-block;
		padding-left : 5px;
		position     : relative;
		top          : -7px
	  }

	  &:before {
		content  : attr(data-off);
		position : absolute;
		top      : 1px;
		right    : 3px;
		width    : 33px
	  }
	}
  }
}

.smart-forms .switch > label:after {
  content       : "";
  margin        : 1px;
  width         : 19px;
  height        : 19px;
  display       : block;
  background    : #FFF;
  border-radius : 1px
}

.smart-forms .switch > input {
  -webkit-appearance : none;
  position           : absolute;
  width              : inherit;
  height             : inherit;
  opacity            : 0;
  left               : 0;
  top                : 0
}

.smart-forms .switch > input:focus {
  outline : 0
}

.smart-forms .switch > input:focus + label {
  color        : #FFF;
  border-color : #A1A6A9;
  background   : #A1A6A9
}

.smart-forms .switch > input:focus + label:after {
  background : #FFF
}

.smart-forms .switch > input:checked + label {
  border-color : #C5B893;
  background   : #C5B893;
  padding-left : 33px;
  color        : #FFF
}

.smart-forms .switch > input:checked + label:before {
  content : attr(data-on);
  left    : 1px;
  top     : 1px
}

.smart-forms .switch > input:checked + label:after {
  margin     : 1px;
  width      : 19px;
  height     : 19px;
  background : #FFF
}

.smart-forms .switch > input:checked:focus + label {
  background   : #16A085;
  border-color : #16A085
}

.smart-forms .switch-round > label {
  border-radius : 13px
}

.smart-forms .switch-round > label + span {
  top : -2px
}

.smart-forms .switch-round > label:before {
  width : 33px
}

.smart-forms .switch-round > label:after {
  width         : 19px;
  color         : #B5C1C7;
  content       : "\2022";
  font          : 20px/20px Times, Serif;
  border-radius : 13px
}

.smart-forms .switch-round > input:checked + label {
  padding-left : 33px
}

.smart-forms .switch-round > input:checked + label:after {
  color : #C5B893
}

.smart-forms .btn-primary,
.smart-forms .btn-primary:active,
.smart-forms .btn-primary:focus,
.smart-forms .btn-primary:hover,
.smart-forms .facebook,
.smart-forms .facebook:focus,
.smart-forms .facebook:hover,
.smart-forms .googleplus,
.smart-forms .googleplus:focus,
.smart-forms .googleplus:hover,
.smart-forms .twitter,
.smart-forms .twitter:focus,
.smart-forms .twitter:hover {
  color       : #FFF;
  text-shadow : 0 1px rgba(0, 0, 0, .08)
}

.smart-forms .btn-primary {
  background-color : #C5B893
}

.smart-forms .btn-primary:focus,
.smart-forms .btn-primary:hover {
  background-color : #48C9B0
}

.smart-forms .btn-primary:active {
  background-color : #16A085
}

.smart-forms .btn-rounded {
  border-radius : 22px
}

.smart-forms .button-left,
.smart-forms .button-right {
  position : relative;
  z-index  : 9
}

.smart-forms .button-left:before,
.smart-forms .button-right:before {
  content          : '';
  z-index          : -1;
  width            : 32px;
  height           : 32px;
  position         : absolute;
  background-color : inherit;
  border           : none;
  top              : 5px
}

.smart-forms .button-left {
  border-left-width : 0;
  padding           : 0 18px 0 7px;
  border-radius     : 0 3px 3px 0;
  margin-left       : 20px
}

.smart-forms .button-left:before {
  left          : -15px;
  border-radius : 2px 5px 0;
  transform     : rotate(-45deg)
}

.smart-forms .button-right:before,
.smart-forms .ribbon-inner {
  transform : rotate(45deg)
}

.smart-forms .button-right {
  padding            : 0 7px 0 18px;
  border-right-width : 0;
  border-radius      : 3px 0 0 3px;
  margin-right       : 20px
}

.smart-forms .button-right:before {
  right         : -15px;
  border-radius : 5px 2px 5px 0;
  transform     : rotate(45deg)
}

.smart-forms .btn-pointed.button-left,
.smart-forms .btn-pointed.button-right {
  border-radius : 22px
}

.smart-forms .btn-rounded.button-left {
  border-radius : 0 22px 22px 0
}

.smart-forms .btn-rounded.button-right {
  border-radius : 22px 0 0 22px
}

.smart-forms .pushed {
  box-shadow : inset 0 -.3em 0 rgba(0, 0, 0, .2);
  position   : relative
}

.smart-forms .pushed:active {
  box-shadow : inset 0 -.15em 0 rgba(0, 0, 0, .2);
  top        : 2px
}

.smart-forms .pushed.button-left:before {
  box-shadow : inset .35em 0 0 rgba(0, 0, 0, .2)
}

.smart-forms .pushed:active.button-left:before {
  box-shadow : inset .2em 0 0 rgba(0, 0, 0, .2)
}

.smart-forms .pushed.button-right:before {
  box-shadow : inset -.35em 0 0 rgba(0, 0, 0, .2)
}

.smart-forms .pushed:active.button-right:before {
  box-shadow : inset -.2em 0 0 rgba(0, 0, 0, .2)
}

.smart-forms .form-footer .button {
  margin-right  : 10px;
  margin-bottom : 5px
}

.smart-forms .align-right .button {
  margin-right : 0;
  margin-left  : 10px
}

.smart-forms .facebook {
  background-color : #3B5998
}

.smart-forms .twitter {
  background-color : #00ACEE
}

.smart-forms .googleplus {
  background-color : #DD4B39
}

.smart-forms .facebook:focus,
.smart-forms .facebook:hover {
  background-color : #25385F
}

.smart-forms .twitter:focus,
.smart-forms .twitter:hover {
  background-color : #00749F
}

.smart-forms .googleplus:focus,
.smart-forms .googleplus:hover {
  background-color : #8D2418
}

.smart-forms .span-left {
  padding-left : 52px;
  text-align   : left
}

.smart-forms .btn-social {
  position      : relative;
  margin-bottom : 5px
}

.smart-forms .btn-social i {
  font-size : 22px;
  position  : relative;
  top       : 2px
}

.smart-forms .btn-social span {
  border-radius : 3px 0 0 3px;
  display       : inline-block;
  text-align    : center;
  position      : absolute;
  width         : 42px;
  left          : 0
}

.smart-forms .twitter span {
  background-color : #009AD5
}

.smart-forms .facebook span {
  background-color : #31497D
}

.smart-forms .googleplus span {
  background-color : #C03121
}

.smart-forms .rating {
  overflow : hidden
}

.smart-forms .rating.block {
  display : block;
  margin  : 10px 0
}

.smart-forms .rating label {
  color : #B5C1C7
}

.smart-forms .rating label i {
  font-size  : 17px;
  text-align : center;
  color      : inherit
}

.smart-forms .rating label span {
  font : 22px/22px Times, Serif
}

.smart-forms .rating-star {
  margin-left : 4px
}

.smart-forms .rating-input {
  position : absolute;
  left     : -9999px;
  top      : auto
}

.smart-forms .prepend-icon .field-icon,
.smart-forms .prepend-picker-icon button,
.smart-forms .sm-left .button {
  left : 0
}

.smart-forms .rating-input:checked ~ .rating-star,
.smart-forms .rating:hover .rating-star:hover,
.smart-forms .rating:hover .rating-star:hover ~ .rating-star {
  color : #C5B893
}

.smart-forms .rating-star,
.smart-forms .rating:hover .rating-star {
  width   : 18px;
  float   : right;
  display : block;
  cursor  : pointer;
  color   : #B5C1C7
}

.smart-forms .append-picker-icon,
.smart-forms .prepend-picker-icon,
.smart-forms .smart-widget {
  position : relative;
  display  : block
}

.smart-forms .append-picker-icon input,
.smart-forms .prepend-picker-icon input,
.smart-forms .smart-widget .field input {
  width : 100%
}

.smart-forms .append-picker-icon button,
.smart-forms .prepend-picker-icon button,
.smart-forms .smart-widget .button {
  border-radius : 3px;
  position      : absolute;
  height        : 42px;
  top           : 0
}

.smart-forms .append-picker-icon button,
.smart-forms .prepend-picker-icon .gui-input,
.smart-forms .sm-left .field input,
.smart-forms .sm-right .button {
  border-top-left-radius    : 0;
  border-bottom-left-radius : 0
}

.smart-forms .append-picker-icon .gui-input,
.smart-forms .prepend-picker-icon button,
.smart-forms .sm-left .button,
.smart-forms .sm-right .field input {
  border-top-right-radius    : 0;
  border-bottom-right-radius : 0
}

.smart-forms .append-picker-icon button,
.smart-forms .sm-right .button {
  right : 0
}

.smart-forms .prepend-picker-icon,
.smart-forms .sml-50 {
  padding-left : 50px
}

.smart-forms .prepend-picker-icon button,
.smart-forms .sml-50 .button {
  width : 50px
}

.smart-forms .sml-80 {
  padding-left : 80px
}

.smart-forms .sml-80 .button {
  width : 80px
}

.smart-forms .sml-120 {
  padding-left : 120px
}

.smart-forms .sml-120 .button {
  width : 120px
}

.smart-forms .append-picker-icon,
.smart-forms .smr-50 {
  padding-right : 50px
}

.smart-forms .append-picker-icon button,
.smart-forms .smr-50 .button {
  width : 50px
}

.smart-forms .smr-80 {
  padding-right : 80px
}

.smart-forms .smr-80 .button {
  width : 80px
}

.smart-forms .smr-120 {
  padding-right : 120px
}

.smart-forms .smr-120 .button {
  width : 120px
}

.smart-forms .append-picker-icon button,
.smart-forms .prepend-picker-icon button {
  background  : #BDC3C7;
  text-shadow : 0 1px rgba(255, 255, 255,
		  .2);
  text-align  : center;
  cursor      : pointer;
  color       : #243140;
  border      : 0
}

.smart-forms .append-picker-icon button:hover,
.smart-forms .prepend-picker-icon button:hover {
  color      : #243140;
  background : #CACFD2
}

.smart-forms .append-picker-icon button:active,
.smart-forms .prepend-picker-icon button:active {
  color      : #1D2938;
  background : #A1A6A9
}

.smart-forms .append-icon,
.smart-forms .prepend-icon {
  display        : inline-block;
  vertical-align : top;
  position       : relative;
  width          : 100%
}

.smart-forms .append-icon .field-icon,
.smart-forms .prepend-icon .field-icon {
  top            : 0;
  z-index        : 4;
  width          : 42px;
  height         : 42px;
  color          : inherit;
  line-height    : 42px;
  position       : absolute;
  text-align     : center;
  transition     : all .5s ease-out;
  pointer-events : none
}

.smart-forms .append-icon .field-icon i,
.smart-forms .prepend-icon .field-icon i {
  position  : relative;
  font-size : 14px
}

.smart-forms .append-icon .field-icon {
  right : 0
}

.smart-forms .prepend-icon > input,
.smart-forms .prepend-icon > textarea {
  padding-left : 36px
}

.smart-forms .append-icon > input,
.smart-forms .append-icon > textarea {
  padding-right : 36px;
  padding-left  : 10px
}

.smart-forms .append-icon > textarea {
  padding-right : 36px
}

.smart-forms .tooltip {
  position    : absolute;
  z-index     : -1;
  opacity     : 0;
  color       : #FFF;
  width       : 184px;
  left        : -9999px;
  top         : auto;
  font-size   : 11px;
  font-weight : 400;
  background  : #34495E;
  transition  : margin .6s, opacity .6s
}

.smart-forms .tooltip > em {
  padding    : 12px;
  font-style : normal;
  display    : block;
  position   : static
}

#wrapper header.fixed .header-logo,
.smart-forms .form-msg,
.smart-forms .progress-section {
  display : none
}

.smart-forms .tooltip:after {
  content  : '';
  position : absolute
}

.smart-forms .gui-input:focus + .tooltip,
.smart-forms .gui-textarea:focus + .tooltip {
  opacity : 1;
  z-index : 999
}

.smart-forms .tip-left {
  top          : 1px;
  margin-right : -20px
}

.smart-forms .tip-left:after {
  top           : 12px;
  left          : 100%;
  border-left   : 8px solid #34495E;
  border-top    : 8px solid transparent;
  border-bottom : 8px solid transparent
}

.smart-forms .gui-input:focus + .tip-left,
.smart-forms .gui-textarea:focus + .tip-left {
  margin-right : 5px;
  right        : 100%;
  left         : auto
}

.smart-forms .tip-right {
  top         : 1px;
  margin-left : -20px
}

.smart-forms .tip-right:after {
  top           : 12px;
  right         : 100%;
  border-right  : 8px solid #34495E;
  border-top    : 8px solid transparent;
  border-bottom : 8px solid transparent
}

.smart-forms .tip-left-bottom:after,
.smart-forms .tip-right-bottom:after {
  bottom        : 100%;
  border-bottom : 8px solid #34495E;
  border-right  : 8px solid transparent;
  border-left   : 8px solid transparent
}

.smart-forms .gui-input:focus + .tip-right,
.smart-forms .gui-textarea:focus + .tip-right {
  left        : 100%;
  margin-left : 5px
}

.smart-forms .tip-right-top {
  bottom        : 100%;
  margin-bottom : -20px
}

.smart-forms .tip-right-top:after {
  top          : 100%;
  right        : 12px;
  border-top   : 8px solid #34495E;
  border-right : 8px solid transparent;
  border-left  : 8px solid transparent
}

.smart-forms .gui-input:focus + .tip-right-top,
.smart-forms .gui-textarea:focus + .tip-right-top {
  right         : 0;
  left          : auto;
  margin-bottom : 10px
}

.smart-forms .tip-left-top {
  bottom        : 100%;
  margin-bottom : -20px
}

.smart-forms .tip-left-top:after {
  top          : 100%;
  left         : 12px;
  border-top   : 8px solid #34495E;
  border-right : 8px solid transparent;
  border-left  : 8px solid transparent
}

.smart-forms .gui-input:focus + .tip-left-top,
.smart-forms .gui-textarea:focus + .tip-left-top {
  left          : 0;
  right         : auto;
  margin-bottom : 10px
}

.smart-forms .tip-right-bottom {
  top        : 100%;
  margin-top : -20px
}

.smart-forms .tip-right-bottom:after {
  right : 12px
}

.smart-forms .gui-input:focus + .tip-right-bottom,
.smart-forms .gui-textarea:focus + .tip-right-bottom {
  margin-top : 10px;
  left       : auto;
  right      : 0
}

.smart-forms .tip-left-bottom {
  top        : 100%;
  margin-top : -20px
}

.smart-forms .tip-left-bottom:after {
  left : 12px
}

.smart-forms .gui-input:focus + .tip-left-bottom,
.smart-forms .gui-textarea:focus + .tip-left-bottom {
  margin-top : 10px;
  right      : auto;
  left       : 0
}

.smart-forms .smart-list {
  list-style : none;
  margin     : 0;
  padding    : 0
}

.smart-forms .smart-list li {
  margin-bottom : 20px
}

.smart-forms .notification {
  color    : #444;
  padding  : 15px;
  position : relative
}

.smart-forms .notification p {
  margin      : 0;
  padding     : 0 15px 0 5px;
  line-height : normal
}

.smart-forms .notification .close-btn {
  margin-top      : -7px;
  padding         : inherit;
  position        : absolute;
  text-decoration : none;
  font            : 700 20px/20px Arial, sans-serif;
  opacity         : .65;
  color           : inherit;
  display         : block;
  right           : 1px;
  top             : 14%
}

.smart-forms .notification .close-btn:hover {
  opacity : 1
}

.smart-forms .alert-info {
  color            : #163161;
  background-color : #CFE6FC
}

.smart-forms .alert-success {
  color            : #363;
  background-color : #D2F7AD
}

.smart-forms .alert-warning {
  color            : #C60;
  background-color : #FAE7A2
}

.smart-forms .alert-error {
  color            : #900;
  background-color : #FBDBCF
}

.smart-forms .state-error .checkbox,
.smart-forms .state-error .gui-input,
.smart-forms .state-error .gui-textarea,
.smart-forms .state-error .radio,
.smart-forms .state-error input:focus + .checkbox,
.smart-forms .state-error input:focus + .radio,
.smart-forms .state-error input:hover + .checkbox,
.smart-forms .state-error input:hover + .radio,
.smart-forms .state-error.select-multiple > select,
.smart-forms .state-error.select > select {
  background   : #FEEFE9;
  border-color : #E46B66
}

.smart-forms .state-error .gui-input ~ .field-icon i,
.smart-forms .state-error .gui-textarea ~ .field-icon i,
.smart-forms .state-error.select .arrow {
  color : #E74C3C
}

.smart-forms .state-error.select:before {
  border-left : 1px solid #E46B66
}

.smart-forms .state-error .gui-input ~ .input-hint,
.smart-forms .state-error .gui-textarea ~ .input-hint,
.smart-forms .state-error.file .gui-file:hover + .gui-input {
  border-color : #E46B66
}

.smart-forms .state-error + em {
  display     : block !important;
  margin-top  : 6px;
  padding     : 0 3px;
  font-family : Arial, Helvetica, sans-serif;
  font-style  : normal;
  line-height : normal;
  color       : #C00;
  font-size   : .85em
}

.smart-forms .state-success .checkbox,
.smart-forms .state-success .gui-input,
.smart-forms .state-success .gui-textarea,
.smart-forms .state-success .radio,
.smart-forms .state-success input:focus + .checkbox,
.smart-forms .state-success input:focus + .radio,
.smart-forms .state-success input:hover + .checkbox,
.smart-forms .state-success input:hover + .radio,
.smart-forms .state-success.select-multiple > select,
.smart-forms .state-success.select > select {
  background   : #EAFCD8;
  border-color : #2ECC71
}

.smart-forms .state-success .gui-input ~ .field-icon i,
.smart-forms .state-success .gui-textarea ~ .field-icon i,
.smart-forms .state-success.select .arrow {
  color : #2ECC71
}

.smart-forms .state-success.select:before {
  border-left : 1px solid #2ECC71
}

.smart-forms .state-success .gui-input ~ .input-hint,
.smart-forms .state-success .gui-textarea ~ .input-hint,
.smart-forms .state-success.file .gui-file:hover + .gui-input {
  border-color : #2ECC71
}

.smart-forms .button[disabled],
.smart-forms .state-disabled .button,
.smart-forms .switch > input[disabled] + label,
.smart-forms input[disabled] + .checkbox,
.smart-forms input[disabled] + .radio {
  cursor  : default;
  opacity : .5
}

.smart-forms .file .gui-file[disabled] + .gui-input,
.smart-forms .file .gui-file[disabled]:hover + .gui-input,
.smart-forms .gui-input[disabled],
.smart-forms .gui-input[disabled] ~ .input-hint,
.smart-forms .gui-textarea[disabled],
.smart-forms .gui-textarea[disabled] ~ .input-hint,
.smart-forms .select-multiple select[disabled],
.smart-forms .select > select[disabled] {
  background-color : #F4F6F6;
  border-color     : #D5DBDB !important;
  cursor           : default;
  color            : #D5DBDB;
  opacity          : .7
}

.smart-forms .select > select[disabled] + .arrow,
.smart-forms input[disabled] ~ .field-icon i,
.smart-forms textarea[disabled] ~ .field-icon i {
  opacity : .4
}

.ui-datepicker-buttonpane {
  border-top : 1px solid #D9DDE5;
  padding    : 10px
}

.ui-datepicker-buttonpane button {
  padding           : 8px 12px;
  margin-right      : .2em;
  position          : relative;
  line-height       : normal;
  display           : inline-block;
  -webkit-user-drag : none;
  text-shadow       : 0 1px rgba(255, 255, 255,
		  .2);
  border-radius     : 3px;
  vertical-align    : middle;
  background        : #BDC3C7;
  text-align        : center;
  overflow          : visible;
  cursor            : pointer;
  color             : #243140;
  border            : 0
}

.ui-datepicker-buttonpane button:hover {
  color      : #243140;
  background : #CACFD2
}

.ui-datepicker-buttonpane button:active {
  color      : #1D2938;
  background : #A1A6A9
}

.ui-monthpicker .ui-datepicker-header {
  margin-bottom : 3px
}

.smart-forms .slider-wrapper,
.smart-forms .sliderv-wrapper {
  background    : #E2E8F1;
  position      : relative;
  border-radius : 3px
}

.smart-forms .ui-slider {
  position   : relative;
  text-align : left
}

.smart-forms .ui-slider .ui-slider-handle {
  position         : absolute;
  z-index          : 2;
  width            : 1.5em;
  height           : 1.5em;
  cursor           : default;
  background       : #FFF;
  text-decoration  : none;
  border           : 3px solid #C5B893;
  border-radius    : 20px;
  -ms-touch-action : none;
  touch-action     : none;
  margin-top       : -3px;
  outline          : 0
}

.smart-forms .ui-slider .ui-slider-handle:before {
  content          : '';
  width            : 7px;
  height           : 7px;
  position         : absolute;
  background-color : #C5B893;
  border-radius    : 10px;
  z-index          : 2;
  left             : 4px;
  top              : 4px
}

.smart-forms .ui-slider .ui-slider-range {
  position            : absolute;
  z-index             : 1;
  font-size           : .7em;
  display             : block;
  border              : 0;
  background-position : 0 0;
  background-color    : #C5B893;
  border-radius       : 3px
}

.smart-forms .ui-slider.ui-state-disabled .ui-slider-handle,
.smart-forms .ui-slider.ui-state-disabled .ui-slider-range {
  filter : inherit
}

.smart-forms .ui-slider-horizontal {
  height : .5em
}

.smart-forms .ui-slider-horizontal .ui-slider-handle {
  top         : -.3em;
  margin-left : -.6em
}

.smart-forms .ui-slider-horizontal .ui-slider-range {
  top    : 0;
  height : 100%
}

.smart-forms .ui-slider-horizontal .ui-slider-range-min {
  left : 0
}

.smart-forms .ui-slider-horizontal .ui-slider-range-max {
  right : 0
}

.smart-forms .sliderv-wrapper,
.smart-forms .ui-slider-vertical {
  width  : .5em;
  height : 100px
}

.smart-forms .ui-slider-vertical .ui-slider-handle {
  left          : -.45em;
  margin-left   : 0;
  margin-bottom : -.6em
}

.smart-forms .ui-slider-vertical .ui-slider-range {
  left  : 0;
  width : 100%
}

.smart-forms .ui-slider-vertical .ui-slider-range-min {
  bottom : 0
}

.smart-forms .ui-slider-vertical .ui-slider-range-max {
  top : 0
}

.smart-forms .slider-input {
  color      : #F6931F !important;
  border     : 0;
  background : 0 0
}

.smart-forms .progress-bar,
.smart-forms .slider-tip {
  border-radius : 3px
}

.smart-forms .slider-group .sliderv-wrapper {
  height : 150px;
  float  : left;
  margin : 15px
}

.smart-forms .ui-slider .ui-state-active {
  cursor : grabbing
}

.smart-forms .slider-tip {
  display       : block;
  position      : absolute;
  text-align    : center;
  font          : 10pt Tahoma, Arial, sans-serif;
  background    : #34495E;
  border-radius : 3px;
  padding       : 10px;
  color         : #FFF
}

.smart-forms .slider-wrapper .slider-tip {
  top  : -50px;
  left : -15px
}

.smart-forms .slider-wrapper .slider-tip:after {
  content      : '';
  position     : absolute;
  top          : 98%;
  left         : 35%;
  border-top   : 8px solid #34495E;
  border-right : 8px solid transparent;
  border-left  : 8px solid transparent
}

.smart-forms .sliderv-wrapper .slider-tip {
  left : 30px;
  top  : -12px
}

.smart-forms .sliderv-wrapper .slider-tip:after {
  content       : '';
  position      : absolute;
  top           : 30%;
  right         : 98%;
  border-right  : 8px solid #34495E;
  border-top    : 8px solid transparent;
  border-bottom : 8px solid transparent
}

.smart-forms .yellow-slider .ui-slider .ui-slider-handle {
  border-color : #ED9C28
}

.smart-forms .yellow-slider .ui-slider .ui-slider-handle:before,
.smart-forms .yellow-slider .ui-slider .ui-slider-range {
  background-color : #ED9C28
}

.smart-forms .red-slider .ui-slider .ui-slider-handle {
  border-color : #E74C3C
}

.smart-forms .red-slider .ui-slider .ui-slider-handle:before,
.smart-forms .red-slider .ui-slider .ui-slider-range {
  background-color : #E74C3C
}

.smart-forms .purple-slider .ui-slider .ui-slider-handle {
  border-color : #9B59B6
}

.smart-forms .purple-slider .ui-slider .ui-slider-handle:before,
.smart-forms .purple-slider .ui-slider .ui-slider-range {
  background-color : #9B59B6
}

.smart-forms .blue-slider .ui-slider .ui-slider-handle {
  border-color : #3498DB
}

.smart-forms .blue-slider .ui-slider .ui-slider-handle:before,
.smart-forms .blue-slider .ui-slider .ui-slider-range {
  background-color : #3498DB
}

.smart-forms .black-slider .ui-slider .ui-slider-handle {
  border-color : #34495E
}

.smart-forms .black-slider .ui-slider .ui-slider-handle:before,
.smart-forms .black-slider .ui-slider .ui-slider-range {
  background-color : #34495E
}

.smart-forms .green-slider .ui-slider .ui-slider-handle {
  border-color : #2ECC71
}

.smart-forms .green-slider .ui-slider .ui-slider-handle:before,
.smart-forms .green-slider .ui-slider .ui-slider-range {
  background-color : #2ECC71
}

.ui-timepicker-div .ui-widget-header {
  position    : relative;
  line-height : 27px;
  font-size   : 15px;
  padding     : 10px
}

.ui-timepicker-div dl {
  text-align   : left;
  border       : 1px solid #D9DDE5;
  border-width : 1px 0 0;
  padding      : 15px 10px;
  margin       : 0
}

.ui-timepicker-div dl dt {
  float   : left;
  clear   : left;
  padding : 0 0 0 5px
}

.ui-timepicker-div dl dd {
  margin : 0 10px 20px 40%
}

.ui-timepicker-div dl .ui_tpicker_hour,
.ui-timepicker-div dl .ui_tpicker_millisec,
.ui-timepicker-div dl .ui_tpicker_minute,
.ui-timepicker-div dl .ui_tpicker_second {
  background : #E2E8F1;
  position   : relative;
  top        : 6px
}

.ui-timepicker-div td {
  font-size : 90%
}

.ui-tpicker-grid-label {
  background : 0 0;
  border     : none;
  margin     : 0;
  padding    : 0
}

.ui-timepicker-rtl {
  direction : rtl
}

.ui-timepicker-rtl dl {
  text-align : right;
  padding    : 0 5px 0 0
}

.ui-timepicker-rtl dl dt {
  float : right;
  clear : right
}

.ui-timepicker-rtl dl dd {
  margin : 0 40% 10px 10px
}

.smart-forms .progress-bar {
  position      : relative;
  background    : #EDF0F8;
  border-radius : 3px
}

.smart-forms .progress-bar .percent {
  position  : absolute;
  display   : inline-block;
  top       : -3px;
  right     : -24px;
  font-size : 9px;
  color     : #93A2AA
}

.smart-forms .progress-bar > .bar {
  width            : 60%;
  height           : 7px;
  display          : block;
  background-color : #BDC3C7;
  border-radius    : 3px
}

.smart-forms .bar-primary > .bar {
  background-color : #C5B893
}

.smart-forms .bar-blue > .bar {
  background-color : #3498DB
}

.smart-forms .bar-black > .bar {
  background-color : #34495E
}

.smart-forms .bar-green > .bar {
  background-color : #2ECC71
}

.smart-forms .bar-purple > .bar {
  background-color : #9B59B6
}

.smart-forms .bar-red > .bar {
  background-color : #E74C3C
}

.smart-forms .bar-yellow > .bar {
  background-color : #ED9C28
}

.smart-forms .progress-bar > .bar,
.smart-forms .progress > button[type=submit],
.smart-forms .progress > button[type=submit]:hover,
.smart-forms .ui-slider .ui-slider-range {
  background-size  : 16px 16px;
  background-image : linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255,
		  .3) 25%, rgba(255, 255, 255,
		  .3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255,
		  .3) 75%, rgba(255, 255, 255,
		  .3))
}

.smart-forms .progress-animated > .bar,
.smart-forms .progress > button[type=submit],
.smart-forms .progress > button[type=submit]:hover {
  animation : sfprogress .6s linear infinite
}

.smart-forms .progress > button[type=submit]:hover {
  cursor : wait
}

@-webkit-keyframes sfprogress {
  from {
	background-position : 0 0
  }
  to {
	background-position : -16px 0
  }
}

@-moz-keyframes sfprogress {
  from {
	background-position : 0 0
  }
  to {
	background-position : -16px 0
  }
}

@-o-keyframes sfprogress {
  from {
	background-position : 0 0
  }
  to {
	background-position : -16px 0
  }
}

@keyframes sfprogress {
  from {
	background-position : 0 0
  }
  to {
	background-position : -16px 0
  }
}

.smart-forms .map-container {
  padding : 10px;
  border  : 2px solid #BDC3C7
}

.smart-forms #map_canvas {
  width    : 100%;
  height   : 300px;
  overflow : hidden
}

.smart-forms .block {
  display : block
}

.smart-forms .frm-row {
  margin : 0 -10px
}

.smart-forms .frm-row:after,
.smart-forms .frm-row:before,
.smart-forms .slider-group:after,
.smart-forms .slider-group:before {
  display     : table;
  content     : "";
  line-height : 0
}

.smart-forms .frm-row:after,
.smart-forms .slider-group:after {
  clear : both
}

.smart-forms .frm-row .colm {
  min-height    : 1px;
  padding-left  : 10px;
  padding-right : 10px;
  position      : relative;
  float         : left
}

.smart-forms .frm-row .colm1 {
  width : 8.33%
}

.smart-forms .frm-row .colm2 {
  width : 16.66%
}

.smart-forms .frm-row .colm3 {
  width : 25%
}

.smart-forms .frm-row .colm4 {
  width : 33.33%
}

.smart-forms .frm-row .colm5 {
  width : 41.66%
}

.smart-forms .frm-row .colm6 {
  width : 50%
}

.smart-forms .frm-row .colm7 {
  width : 58.33%
}

.smart-forms .frm-row .colm8 {
  width : 66.66%
}

.smart-forms .frm-row .colm9 {
  width : 75%
}

.smart-forms .frm-row .colm10 {
  width : 83.33%
}

.smart-forms .frm-row .colm11 {
  width : 91.66%
}

.smart-forms .frm-row .colm12 {
  width : 100%
}

.smart-forms .frm-row .colm1-5 {
  width : 20%
}

.smart-forms .frm-row .colm1-8 {
  width : 12.5%
}

.smart-forms .spacer {
  border-top : 1px solid #D9DDE5;
  display    : block;
  height     : 0
}

.smart-forms .spacer-t10 {
  margin-top : 10px
}

.smart-forms .spacer-b10 {
  margin-bottom : 10px
}

.smart-forms .spacer-t15 {

}

.smart-forms .spacer-b15 {
  margin-bottom : 15px
}

.smart-forms .spacer-t20 {
  margin-top : 20px
}

.smart-forms .spacer-b20 {
  margin-bottom : 20px
}

.smart-forms .spacer-t25 {
  margin-top : 25px
}

.smart-forms .spacer-b25 {
  margin-bottom : 25px
}

.smart-forms .spacer-t30 {
  margin-top : 30px
}

.smart-forms .spacer-b30 {
  margin-bottom : 30px
}

.smart-forms .spacer-t40 {
  margin-top : 40px
}

.smart-forms .spacer-b40 {
  margin-bottom : 40px
}

.smart-forms .frm-row .pad-l10 {
  padding-left : 10px
}

.smart-forms .frm-row .pad-r10 {
  padding-right : 10px
}

.smart-forms .frm-row .pad-l20 {
  padding-left : 20px
}

.smart-forms .frm-row .pad-r20 {
  padding-right : 20px
}

.smart-forms .frm-row .pad-l30 {
  padding-left : 30px
}

.smart-forms .frm-row .pad-r30 {
  padding-right : 30px
}

.smart-forms .frm-row .pad-l40 {
  padding-left : 40px
}

.smart-forms .frm-row .pad-r40 {
  padding-right : 40px
}

.smart-forms .bdl {
  border-left : 1px solid #D9DDE5
}

.smart-forms .bdr {
  border-right : 1px solid #D9DDE5
}

.smart-forms .fine-grey {
  color : #919DA8
}

.smart-forms .small-text {
  font-size  : 11px;
  font-style : normal
}

.smart-forms .text-align {
  height      : 42px;
  line-height : 42px
}

.smart-forms .align-right {
  text-align : right
}

.smart-forms .align-center, .center {
  text-align : center
}

.smart-forms .price-box {
  padding     : 30px;
  text-align  : center;
  position    : relative;
  border      : 1px solid #D0D6DF;
  font-family : Arial, Helvetica, sans-serif;
  box-shadow  : 0 2px 0 0 rgba(0, 0, 0, .05)
}

.smart-forms .price-box p {
  line-height   : 1.5em;
  color         : #526066;
  margin-bottom : 0
}

.smart-forms .price-box h5 {
  text-transform : uppercase;
  font-weight    : 300;
  margin         : 0;
  font-size      : 15px;
  color          : #BACDD6;
  letter-spacing : 2px
}

.smart-forms .price-box h4 {
  font-size   : 60px;
  font-weight : 300;
  margin      : 0;
  color       : #547698
}

.smart-forms .selected-box h4 {
  color : #C5B893
}

.smart-forms .price-box h4 sup {
  position       : relative;
  font-size      : 30px;
  vertical-align : top;
  top            : 15px
}

.smart-forms .price-box h4 .per-month {
  font-size : 14px
}

.smart-forms .expand {
  height      : 50px;
  line-height : 50px !important
}

.smart-forms .ribbon,
.smart-forms .ribbon-large {
  width    : 75px;
  height   : 78px;
  overflow : hidden;
  position : absolute;
  right    : -2px;
  top      : -2px;
  z-index  : 1
}

.smart-forms .ribbon-inner {
  font-family    : "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow     : 0 2px 0 0 rgba(0, 0, 0, .15);
  transform      : rotate(45deg);
  background     : #C5B893;
  letter-spacing : 4px;
  text-align     : center;
  position       : relative;
  font-weight    : 700;
  font-size      : 14px;
  padding        : 7px 0;
  width          : 100px;
  color          : #FFF;
  z-index        : 1;
  left           : 3px;
  top            : 6px
}

.block-topcontent--block, .module-destination__content--item, #wrapper header.fixed .header-subpage {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.smart-forms .ribbon-inner:after,
.smart-forms .ribbon-inner:before {
  content      : "";
  border-top   : 3px solid #16A085;
  border-left  : 3px solid transparent;
  border-right : 3px solid transparent;
  position     : absolute;
  bottom       : -3px
}

.smart-forms .ribbon-inner:before {
  left : 0
}

.smart-forms .ribbon-inner:after {
  right : 0
}

.smart-forms .ribbon-large {
  width  : 115px;
  height : 118px
}

.smart-forms .ribbon-large .ribbon-inner {
  width : 160px;
  left  : -8px;
  top   : 28px
}

.smart-forms .sfcode {
  padding-left : 24px
}

.smart-forms .captcode {
  padding  : 0;
  position : relative
}

.smart-forms .captcode img {
  position      : relative;
  border-radius : 2px 0 0 2px;
  top           : 2px;
  left          : 1px
}

.smart-forms .refresh-captcha {
  position      : absolute;
  background    : #C5B893;
  border        : 3px solid #16A085;
  border-radius : 30px;
  right         : -15px;
  height        : 32px;
  width         : 32px;
  top           : 4px
}

#wrapper header .header-subpage nav ul li.has-subpage .dropdown ul li, #wrapper header .header-subpage nav ul li:hover .dropdown {
  height : 50px
}

.smart-forms .refresh-captcha i {
  position    : absolute;
  text-align  : center;
  line-height : 26px;
  font-size   : 17px;
  color       : #FFF;
  left        : 24%
}

.smart-forms .refresh-black {
  background   : #34495E;
  border-color : #2C3E50
}

.smart-forms .refresh-blue {
  background   : #3498DB;
  border-color : #2C81BA
}

.smart-forms .refresh-green {
  background   : #2ECC71;
  border-color : #27AD60
}

.smart-forms .refresh-purple {
  background   : #9B59B6;
  border-color : #8E44AD
}

.smart-forms .refresh-red {
  background   : #E74C3C;
  border-color : #C44133
}

.smart-forms .refresh-yellow {
  background   : #ED9C28;
  border-color : #D58512
}

@media screen and (-webkit-min-device-pixel-ratio : 0) {
  .smart-forms .captcode,
  .smart-forms .option,
  .smart-forms .rating,
  .smart-forms .switch {
	-webkit-animation : bugfix infinite 1s
  }

  @-webkit-keyframes bugfix {
	from, to {
	  padding : 0
	}
  }
  .smart-forms .switch {
	margin-right  : 10px;
	margin-bottom : 5px
  }

  .smart-forms .option {
	margin-right : 15px
  }
}

@media (max-width : 600px) {
  .smart-forms .frm-row {
	margin : 0
  }

  .smart-forms .frm-row .colm {
	width   : 100%;
	float   : none;
	padding : 0
  }

  .smart-forms .bdl {
	border-left : 0
  }

  .smart-forms .bdr {
	border-right : 0
  }

  .smart-forms .align-right {
	text-align : left
  }
}

#wrapper header .header-subpage nav ul li.has-subpage .dropdown ul li a {
  padding : 15px 0 16px
}

#wrapper header .header-subpage nav ul li.has-subpage .dropdown ul li a span {
  padding : 10px 0
}

#wrapper header .header-subpage--en nav ul li a {
  padding     : 15px 67px 15px 57px;
  font-size   : 14px;
  line-height : 17px
}

#wrapper header .header-subpage--en nav ul li a:before {
  top : 10px
}

#wrapper header .header-subpage--en nav ul li a:after {
  top : 24px
}

#wrapper header .header-subpage--ru nav ul li a, #wrapper header .header-subpage--ua nav ul li a {
  padding : 20px 41px 20px 31px
}

#wrapper header.fixed {
  position : fixed
}

#wrapper header.fixed .header-subpage {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

#wrapper header.fixed .menu-bg {
  background-color : #FFF
}

#wrapper main .lineunder:after {
  content       : "";
  width         : 70px;
  height        : 5px;
  display       : block;
  border-top    : 2px solid #C2C2C2;
  border-bottom : 2px solid #C2C2C2;
  margin        : 30px 0
}

#wrapper main h4 {
  -webkit-font-smoothing : antialiased;
  font-family            : 'Playfair Display', serif;;
  text-transform         : uppercase
}

#wrapper main p {
  -webkit-font-smoothing : antialiased;
  margin-bottom          : 5px
}

#wrapper form .error {
  border           : 1px solid #C62828;
  background-color : #FBE9E7
}

.module {
  position : relative
}

.module-subpage {
  -webkit-font-smoothing : antialiased
}

.module-subpage__title {
  position               : absolute;
  bottom                 : 0;
  left                   : 0;
  right                  : 0;
  text-align             : center;
  display                : block;
  text-transform         : uppercase;
  color                  : #FFF;
  text-shadow            : 4px 4px 10px #000;
  -webkit-font-smoothing : antialiased;
  letter-spacing         : 3px;
  z-index                : 2;

  &:after {
	content          : "";
	height           : 5px;
	width            : 70px;
	bottom           : 0;
	display          : block;
	left             : 50%;
	margin-left      : -35px;
	position         : absolute;
	background-color : #13395e
  }

  h1 {
	margin-bottom : 15px
  }
}


.module-subpage__content {
  @media screen and (max-width : 600px) {
	padding : 0 30px;
  }

  li {
	& + li {
	  margin-top : 6px;
	}
  }

  .list {
	ul {
	  list-style-type : none;
	  display         : block;
	  padding         : 0 10px;
	  margin          : 0;

	  li {
		display             : block;
		padding             : 5px 5px 5px 40px;
		/*background-image    : url(img/c365906bef951343340e3e8f72dcf561.svg);*/
		background-repeat   : no-repeat;
		background-size     : 10px 30px;
		background-position : 12px 2px;
		font-size           : 16px;
		position            : relative
	  }
	}
  }

  .graybox {
	position         : relative;
	background-color : #F2F2F2;
	padding          : 20px 20px 20px 0;

	&:after {
	  content          : "";
	  left             : -3000px;
	  width            : 3000px;
	  position         : absolute;
	  top              : 0;
	  height           : 100%;
	  background-color : #F2F2F2
	}
  }
}

.module-destination {
  margin-top : 0
}

.module-destination__viewbox {
  color   : #FFF;
  padding : 10px 0;

  a {
	cursor         : pointer;
	opacity        : .6;
	padding        : 5px 15px;
	display        : inline-block;
	color          : #FFF;
	text-transform : uppercase;

	.svg {
	  width  : auto;
	  height : 22px
	}

	&.active {
	  opacity : 1
	}

	&:hover {
	  color : #13395e
	}

	&.dest_view_1 {
	  margin-right : 30px
	}

	&.dest_view_2 {
	  margin-left : 30px
	}
  }
}

.module-destination__viewall {
  z-index       : 100;
  position      : absolute;
  top           : 10px;
  left          : 200px;
  display       : inline-block;
  max-height    : 40px;
  overflow      : hidden;
  border-radius : 1px;

  a {
	cursor           : pointer;
	display          : inline-block;
	padding          : 2px 10px;
	background-color : #FFF;
	color            : #333;

	span {
	  display : inline-block;

	  img {
		margin-top : -3px;
		width      : auto;
		height     : 40px;
		transform  : rotate(180deg)
	  }
	}
  }

  &.hidden {
	display : none
  }
}


.module-destination__content {
  display          : block;
  position         : relative;
  z-index          : 1;
  height           : 700px;
  width            : 560px;
  max-width        : 100%;
  background-color : rgba(255, 255, 255,
		  .95);
  margin-top       : -700px
}

.module-destination__content--desc {
  padding-top   : 30px;
  padding-left  : 70px;
  padding-right : 70px
}

.module-destination__content--hotels {
  position : absolute;
  bottom   : -30px;
  left     : 0;
  right    : 0
}

.module-destination__content--item {
  max-width  : 280px;
  margin     : auto auto 30px;
  text-align : center;
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2);

  a.button {
	position    : absolute;
	left        : 50%;
	transform   : translateX(-50%);
	bottom      : 30px;
	white-space : nowrap
  }

  img {
	width  : 100%;
	height : auto
  }

  .logo {
	background-color : #333;
	padding          : 10px 30px 40px;
	height           : 140px;
	text-align       : center;

	img {
	  height : 100%;
	  width  : auto;
	  margin : auto
	}

  }
}

.module-destination__list {
  padding-bottom : 30px
}

.module-destination__list--desc {
  span {
	display : block
  }

  .block-title--hr {
	margin-bottom : 5px
  }

  .button {
	font-size : 12px
  }

  p {
	text-align       : center;
	background-color : #333;
	padding          : 5px 0;

	span {
	  color : #FFF
	}
  }
}

.module-destination__map {
  position : relative
}

.module-destination__map--bg {
  position            : absolute;
  top                 : 0;
  left                : 0;
  right               : 0;
  bottom              : 0;
  background-position : center;
  background-size     : 100% auto;
  background-repeat   : no-repeat
}

.module-destination__map--menu {
  background-color : #FFF;
  overflow         : hidden;
  display          : inline-block;
  position         : absolute;
  z-index          : 10;
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0;

  ul {
	margin          : 0;
	padding         : 0;
	display         : block;
	list-style-type : none;
	width           : 100%;
	height          : 100%;

	li {
	  display : block;
	  width   : 100%;

	  a {
		color          : #13395e;
		padding        : 15px 20px;
		text-transform : uppercase;
		letter-spacing : 1px;
		display        : block
	  }

	  &:hover {
		cursor           : pointer;
		background-color : #13395e;

		a {
		  color : #FFF
		}
	  }

	  &:first-child {
		background-color : #FFF;

		a {
		  color   : #13395e;
		  display : inline-block;
		  padding : 0;

		  &.mhg {
			color            : #FFF;
			background-color : #6D6D6D;
			text-align       : center;
			display          : block;
			width            : 100%;
			padding          : 20px 0
		  }

		  svg {
			display          : inline-block;
			background-color : #13395e;
			height           : 60px;
			width            : 65px;
			padding          : 20px
		  }

		  label {
			width          : 190px;
			text-align     : center;
			text-transform : uppercase;
			letter-spacing : 1px;
			color          : #13395e;
			margin-bottom  : 0;

			img {
			  height  : 50px;
			  width   : auto;
			  margin  : auto;
			  display : inline-block
			}
		  }
		}
	  }

	  &.bggray {
		background-color : #6D6D6D;

		a {
		  label {
			color : #FFF
		  }
		}
	  }
	}
  }
}


.module-destination #map_content {
  height : 700px;
  width  : 100%;

  .gm-style-iw-c {
	position      : absolute;
	box-sizing    : border-box;
	overflow      : hidden;
	top           : 0;
	left          : 0;
	width         : 420px;
	max-width     : 100% !important;
	height        : 175px;
	transform     : translate(-50%, -100%);
	border-radius : 1px;
	padding       : 10px !important;
	box-shadow    : 0 2px 7px 1px rgba(0, 0, 0, .3)
  }

  .gm-style-iw-d {
	overflow : hidden !important
  }

  .gm-style-iw-d b,
  .gm-style-iw-d strong {
	font-weight : 700 !important
  }

  .gm-style div > div > div > div > div > img {
	width  : 100% !important;
	height : auto !important
  }

}


.module-destination.theme-black .module-destination__map--menu ul li:first-child,
.module-destination.theme-black .module-destination__map--menu ul li:first-child a.mhg,
.module-destination.theme-black .module-destination__map--menu ul li:first-child.bggray,
.module-destination.theme-black .module-destination__viewbox,
.module-packages.theme-black .block-pagelist--filter {
  background-color : #333
}


.module-meetings {
  padding : 30px 0;

  .block-form__textarea {
	height     : 120px;
	text-align : left
  }
}

.module-meetings__title {
  text-align : center;

  h2 {
	font-size      : 40px;
	color          : #6D6D6D;
	letter-spacing : 10px;
	text-transform : uppercase;
	position       : relative;
	display        : inline-block;
	padding        : 50px 0;
	margin         : auto;

	&:after, &:before {
	  content          : "";
	  height           : 2px;
	  background-color : #6D6D6D;
	  width            : 100%;
	  position         : absolute;
	  display          : block
	}

	&:before {
	  bottom : 40px
	}

	&:after {
	  bottom : 35px
	}

	span {
	  position         : absolute;
	  width            : 70px;
	  height           : 7px;
	  background-color : #13395e;
	  bottom           : 35px;
	  left             : 50%;
	  z-index          : 10;
	  transform        : translateX(-50%)
	}
  }
}

.module-booking {
  .tl-wrapper {
	max-width : 100%;
	margin    : 0 auto
  }

  #tl-anchor {
	font        : 14px "Open Sans", sans-serif;
	color       : #000;
	line-height : 25px
  }

  .tl-block__title {
	text-transform : uppercase;
	font           : 400 16px 'Open sans';
	flex-basis     : 130px;
	align-self     : center
  }

  .tl-container {
	max-width : 1440px;
	margin    : 0 auto;
	position  : relative;
	display   : block
  }

  #tl-block-select {
	padding          : 22px 21px;
	font-family      : 'Open Sans', Arial, sans-serif;
	font-size        : 17px;
	border           : 0;
	display          : flex;
	justify-content  : start;
	align-content    : center;
	color            : #BC966C;
	background-color : #333;
	margin-bottom    : 45px
  }

  #tl-hotel-select {
	display            : -webkit-box;
	display            : -ms-flexbox;
	display            : flex;
	-webkit-box-align  : center;
	-ms-flex-align     : center;
	align-items        : center;
	-webkit-box-pack   : start;
	-ms-flex-pack      : start;
	justify-content    : flex-start;
	color              : #FFF;
	width              : 100%;
	border             : 1px solid #FFF;
	border-radius      : 0;
	-webkit-appearance : none;
	-moz-appearance    : none;
	appearance         : none;
	padding            : 9px 20px;
	font-size          : 15px;
	font-family        : 'Open Sans', Arial, sans-serif;
	/*background         : url(arrow.png) 220px center no-repeat;*/
	flex-basis         : 250px;
	text-align-last    : center;

	option {
	  color : #000
	}

	&:hover {
	  -webkit-appearance : none;
	  box-shadow         : 0 0 0 1px #30343B, 0 0 0 0 transparent inset, 0 0 0 1px #30343B
	}
  }

}

.block-hoteloffer__desc-info a:link {
  color : #13395e
}


.block, .block-header {
  position : relative
}

.block-header--img {
  height              : 400px;
  width               : 100%;
  background-size     : cover;
  background-position : 50% 50%;
  background-repeat   : no-repeat
}

.block-header:after {
  content          : "";
  background-color : rgba(0, 0, 0, .3);
  position         : absolute;
  top              : 0;
  right            : 0;
  bottom           : 0;
  left             : 0;
  z-index          : 1
}

.block-breadcrumb {
  background-color : #F6F6F6;

  ul {
	list-style-type : none;
	padding         : 0;
	margin          : 0 auto;

	li {
	  padding        : 10px 60px 10px 0;
	  display        : inline-block;
	  text-transform : uppercase;
	  letter-spacing : 1px;
	  font-size      : 14px;
	  position       : relative;

	  &:not(.active):after {
		content  : "";
		display  : inline-block;
		position : absolute;
		top      : 10px;
		right    : 25px;
		@include svg-ico-breadcrumb;
	  }

	  &.active {
		color : #13395e
	  }

	  strong {
		font-weight : 400;
	  }
	}
  }
}


.block-topcontent {
  background-color : #F6F6F6;
  padding-top      : 30px;
  padding-bottom   : 30px
}

.block-topcontent--block {
  background-color : #FFF;
  box-shadow       : 0 5px 5px 1px rgba(0, 0, 0, .2);

  img {
	width         : 100%;
	height        : auto;
	border-bottom : 7px #FFF solid;

	&.height {
	  height : 380px
	}
  }
}

.block-topcontent--content {
  position : relative;
  height   : 100%;
  padding  : 0 30px 0 10px;

  h2 {
	text-transform : uppercase;
	font-size      : 28px;
	letter-spacing : 1px;
	font-family    : 'Playfair Display', serif;

  }

  h4 {
	font-size : 24px
  }
}

.block-topcontent--btn {
  position : absolute;
  bottom   : 7px;
  left     : 10px
}

.block-experience {
  & + .block-experience {
	margin-top : 15px;
  }
}

.block-experience__desc {
  position : relative;
  height   : 100%;

  .button-container {
	position   : absolute;
	bottom     : 15px;
	width      : 100%;
	padding    : 0;
	text-align : center;

	&:after,
	&:before {
	  content : none
	}
  }

  &.right {
	padding : 15px 20px 0 0;
	@media screen and (max-width : 600px) {
	  padding : 15px 15px 60px;
	}
  }

  &.left {
	padding : 15px 0 0 20px;
	@media screen and (max-width : 600px) {
	  padding : 15px 15px 60px;
	}
  }
}

.block-experience__extra {
  position : absolute;
  bottom   : 0;

  &.right {
	right   : 0;
	padding : 0 35px 0 15px
  }

  &.left {
	left    : 0;
	padding : 0 15px 0 35px
  }
}


.block-hoteloffer {
  border-bottom : solid 7px #FFF;
  box-shadow    : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.block-hoteloffer__image img {
  width  : 100%;
  height : auto
}

.block-hoteloffer__desc {
  display : block;
  width   : 100%
}

.block-hoteloffer__desc-logo {
  height           : 130px;
  background-color : #333;
  width            : 100%;
  text-align       : center;
  padding          : 20px 0;

  img {
	max-height : 100%;
	width      : auto;
	margin     : auto
  }
}


.block-hoteloffer__desc-info {
  padding : 15px 30px
}


.block-form__input {
  width      : 100%;
  text-align : center;
  padding    : 7px
}

.block-form__textarea {
  width      : 100%;
  text-align : left !important;
  padding    : 13px;
  resize     : none !important
}

.block-form__select {
  width : 100%;

  .dropdown {
	max-width : 100%
  }
}

.block-form--contact {
  width     : 400px;
  max-width : 100%;
  margin    : 15px auto
}

.block-form {
  padding : 0 15px;
}

.block-form--success {
  border-radius : 2px;
  padding       : 50px 0;
  margin-bottom : 30px
}

.block-form {
  .block-form__row {
	label {
	  display       : block;
	  margin-bottom : .25rem;
	}

	& + .block-form__row {
	  margin-top : .85em;

	  &.mt0 {
		margin-top : 0;
	  }
	}
  }

  .dropdown {
	max-width : 100%;
	width     : 100%
  }
}

.block-contact__list {
  margin-top : 50px;

  &:before {
	content          : "";
	position         : absolute;
	z-index          : 1;
	top              : 170px;
	bottom           : 230px;
	left             : -1000px;
	right            : -1000px;
	background-color : #F6F6F6
  }
}

.block-contact__list--item {
  position      : relative;
  z-index       : 2;
  text-align    : center;
  box-shadow    : 0 5px 5px 1px rgba(0, 0, 0, .2);
  margin-bottom : 100px;

  a.button {
	position    : absolute;
	left        : 50%;
	transform   : translateX(-50%);
	bottom      : -28px;
	white-space : nowrap
  }
}

.block-contact__list--image img {
  width  : 100%;
  height : auto
}

.block-contact__list--logo {
  border-bottom    : solid 7px #FFF;
  background-color : #333;
  padding          : 10px;
  height           : 130px;

  img {
	display : block;
	height  : 100%;
	width   : auto
  }
}

.block-map--contact {
  max-height : 370px;
  overflow   : hidden;
  text-align : center;

  img {
	margin : auto;
	width  : 100%;
	height : auto
  }
}

.block-map--route {
  height : 700px
}

.block-mapinfo {
  display   : inline-block;
  width     : 400px;
  max-width : 100%;

  .logo {
	text-align : center;

	img:first-child {
	  width     : 100%;
	  height    : auto;
	  max-width : 100%
	}

	img:last-child {
	  height : 50px;
	  width  : auto;
	  margin : 10px auto
	}
  }

  .desc {
	margin-left : -15px
  }
}


.block-list {
  background-color : #F6F6F6;
  border-top       : solid 5px #13395e;

  ul {
	list-style-type : none;
	display         : block;
	padding         : 10px 30px;
	margin          : 0;

	li {
	  display             : block;
	  padding             : 10px 10px 10px 40px;
	  /*background-image: url(../images/svg/angle-right.svg);*/
	  background-repeat   : no-repeat;
	  background-size     : 10px 30px;
	  background-position : 5%;
	  font-size           : 18px;
	  text-transform      : uppercase;
	  position            : relative
	}
  }

  &:after, &:before {
	content          : "";
	display          : inline-block;
	position         : absolute;
	top              : 25px;
	bottom           : 25px;
	left             : 50%;
	width            : 2px;
	background-color : #CACACA
  }

  &:before {
	margin-left : -5px
  }

  &:after {
	margin-right : -5px
  }
}


.block-history__content {
  position         : absolute;
  bottom           : 0;
  left             : 0;
  width            : 530px;
  padding          : 30px 70px;
  background-color : rgba(0, 0, 0, .8);
  color            : #FFF
}

.block-newsletter--widget {
  padding          : 10px;
  background-color : #333;
  color            : #FFF;
  margin-bottom    : 30px
}

#wrapper aside .widget-block {
  margin-bottom : 50px
}

#wrapper aside .widget-block__image img {
  width  : 100%;
  height : auto
}

#wrapper aside .widget-block__desc {
  padding : 20px;

  .date {
	font-size      : 14px;
	line-height    : 14px;
	text-transform : uppercase;
	color          : #13395e;
	margin         : 10px 0;
	display        : block
  }
}

aside .module-destination__list--item {
  margin-bottom : 0
}

footer {
  .footer {
	position         : relative;
	color            : #FFF;
	background-color : #333
  }

  .footer-subpage {
	ul {
	  list-style-type : none;
	  margin          : 0;
	  padding         : 5px 0;

	  li {
		-webkit-font-smoothing : antialiased;
		vertical-align         : middle;
		display                : inline-block;
		font-size              : 14px
	  }
	}
  }

  .footer-subpage__logo {
	width  : auto;
	height : 60px
  }

  .footer-subpage__social {
	padding : 0 20px;

	p {
	  display        : inline-block;
	  margin         : 0;
	  text-transform : uppercase;
	  vertical-align : middle
	}

	ul {
	  display : inline-block;
	  padding : 0 !important;

	  li {
		a {
		  vertical-align   : middle;
		  text-align       : center;
		  display          : inline-block;
		  width            : 32px;
		  background-color : #13395e;
		  padding          : 5px;
		  margin-right     : 5px;

		  &:hover {
			background-color : #13395e
		  }

		  img {
			height : 20px;
			width  : auto
		  }
		}
	  }
	}
  }
}


@media (max-width : 1199.98px) {
  .block---desc h3, .block-newsletter--widget h6 {
	font-size : 16px
  }

  #wrapper header .header-subpage--en nav ul li a {
	padding : 15px 40px 15px 35px
  }

  .module-destination__content {
	width : 500px
  }

  .module-destination__content--item .logo {
	padding : 10px 0 40px
  }

  .block-hoteloffer__desc-info {
	padding : 15px 10px
  }

  .block-list ul {
	padding : 10px
  }

  .block-newsletter--widget {
	padding : 10px 5px
  }

  .block-newsletter--widget .policy [type=checkbox] + span:not(.lever) {
	padding-left : 20px
  }
}

@media (max-width : 991.98px) {
  .module-destination {
	background-color : #FFF;
	height           : auto
  }

  .module-destination__content {
	width      : 100%;
	height     : auto;
	position   : relative;
	margin-top : 0
  }

  .module-destination__content--hotels {
	position : relative
  }

  .module-destination__content--item {
	max-width : 100%
  }

  .module-destination__content--item .logo {
	height : auto
  }

  .module-destination__content--item .logo img {
	max-width : 100%;
	height    : auto
  }

  .module-destination__list--nav ul li {
	padding   : 10px;
	font-size : 14px
  }

  #wrapper aside {
	margin-top : 30px
  }
}

@media (max-width : 767.98px) {
  .module-subpage__header {
	padding-top : 50px
  }

  .module-subpage__header--img {
	height : 250px
  }

  .module-destination__map {
	text-align : center
  }

  .module-destination__map #map_content {
	height : 500px
  }

  .module-destination__map--menu {
	margin   : auto;
	position : relative;
	top      : unset;
	left     : unset;
	right    : unset;
	bottom   : unset
  }

  .module-destination__map--menu ul li a {
	text-align : left
  }

  .module-destination__map--menu ul li.active a {
	width : 100%
  }

  .module-destination__list--nav {
	display : none
  }

  .module-route .smart-wrap {
	padding : 0
  }

  .module-route .smart-forms .form-body, .module-route .smart-forms .form-footer, .module-route .smart-forms .form-header {
	padding : 15px
  }

  .module-route .smart-forms .form-header h4 {
	font-size  : 26px;
	text-align : center
  }

  .module-route .smart-forms .tagline {
	height : auto
  }

  .module-route .smart-forms .spacer-b30 {
	margin-bottom : 10px
  }

  .module-route .smart-forms .section:last-child {
	margin-bottom : 0
  }

  .block-contact__list {
	margin-top : 0
  }

  .block-contact__list:before {
	content : none
  }

  .block-carousel .owl-nav {
	margin-top : -20px
  }

  .block-carousel .owl-nav img {
	height : 40px;
	margin : 10px;
  }

  .block-list ul li {
	padding   : 10px 10px 10px 30px;
	font-size : 16px
  }

  .block-history__content {
	width    : 100%;
	position : relative;
	display  : block;
	bottom   : inherit
  }

  .block-map--route {
	height : 300px
  }
}

@media (max-width : 575.98px) {
  #wrapper main h2 {
	font-size : 24px
  }

  .module-destination__content--desc h1, .module-subpage__title h1 {
	font-size : 30px
  }

  #wrapper main p {
	text-align : justify
  }

  .module-destination {
	margin-bottom : 30px
  }

  .module-destination__content--desc {
	padding : 20px 0
  }

  .module-destination__viewbox .dest_view_1 {
	margin-right : 10px !important
  }

  .module-destination__viewbox .dest_view_2 {
	margin-left : 10px !important
  }

  .block-header--img {
	height : 250px !important
  }

  .block-breadcrumb {
	display : none
  }

  .block-contact__list--item {
	margin-bottom : 50px
  }

  .block-contact__list--logo {
	height : auto
  }

  .block-contact__list--logo img {
	width  : 100%;
	height : auto
  }

  .block-list:after, .block-list:before {
	content : none
  }

  .block-list ul li {
	padding   : 10px 40px;
	font-size : 18px
  }

  .block-pagelist--filter {
	text-align : center !important
  }

  .block-history__content {
	padding : 20px
  }
}

@media (max-width : 480px) {
  #wrapper footer .footer-subpage ul {
	text-align : center
  }

  #wrapper footer .footer-subpage ul li {
	display       : block;
	margin-bottom : 5px
  }

  #wrapper footer .footer-subpage__logo {
	height : 100px;
	margin : 10px 0
  }

  #wrapper footer .footer-subpage__social {
	margin  : 5px 0;
	display : block
  }

  #wrapper footer .footer-subpage__social p {
	display : none
  }

  #wrapper footer .footer-subpage__social ul li {
	display : inline-block
  }

  #wrapper footer .footer-subpage__social ul li a {
	width : 50px
  }

  #wrapper footer .footer-subpage__social ul li a svg {
	height : 40px
  }
}