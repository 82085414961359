@mixin svg-fa-arrow-left() {
	background: url('../images/sprite.svg?v1619689757752') 79.34782608695652% 86.07594936708861% no-repeat;
	width: 22px;
	height: 22px;
	display: inline-block;
}
@mixin svg-fa-arrow-right() {
	background: url('../images/sprite.svg?v1619689757752') 100% 0 no-repeat;
	width: 22px;
	height: 22px;
	display: inline-block;
}
@mixin svg-facebook() {
	background: url('../images/sprite.svg?v1619689757752') 0 67.6470588235294% no-repeat;
	width: 44px;
	height: 44px;
	display: inline-block;
}
@mixin svg-ico-breadcrumb() {
	background: url('../images/sprite.svg?v1619689757752') 94.84536082474227% 13.75% no-repeat;
	width: 12px;
	height: 20px;
	display: inline-block;
}
@mixin svg-ico-grid() {
	background: url('../images/sprite.svg?v1619689757752') 53.84615384615385% 87.17948717948718% no-repeat;
	width: 24px;
	height: 24px;
	display: inline-block;
}
@mixin svg-ico-map() {
	background: url('../images/sprite.svg?v1619689757752') 67.03296703296704% 87.17948717948718% no-repeat;
	width: 24px;
	height: 24px;
	display: inline-block;
}
@mixin svg-instagram() {
	background: url('../images/sprite.svg?v1619689757752') 27.160493827160494% 67.6470588235294% no-repeat;
	width: 44px;
	height: 44px;
	display: inline-block;
}
@mixin svg-linkedin() {
	background: url('../images/sprite.svg?v1619689757752') 54.32098765432099% 67.6470588235294% no-repeat;
	width: 44px;
	height: 44px;
	display: inline-block;
}
@mixin svg-swiper-button-next() {
	background: url('../images/sprite.svg?v1619689757752') 0 0 no-repeat;
	width: 92px;
	height: 92px;
	display: inline-block;
}
@mixin svg-swiper-button-next-s() {
	background: url('../images/sprite.svg?v1619689757752') 81.48148148148148% 67.6470588235294% no-repeat;
	width: 44px;
	height: 44px;
	display: inline-block;
}
@mixin svg-swiper-button-prev() {
	background: url('../images/sprite.svg?v1619689757752') 80.70175438596492% 0 no-repeat;
	width: 92px;
	height: 92px;
	display: inline-block;
}
@mixin svg-swiper-button-prev-s() {
	background: url('../images/sprite.svg?v1619689757752') 0 100% no-repeat;
	width: 44px;
	height: 44px;
	display: inline-block;
}
@mixin svg-telegram() {
	background: url('../images/sprite.svg?v1619689757752') 24.58100558659218% 88.88888888888889% no-repeat;
	width: 27px;
	height: 27px;
	display: inline-block;
}
@mixin svg-viber() {
	background: url('../images/sprite.svg?v1619689757752') 39.66480446927374% 88.88888888888889% no-repeat;
	width: 27px;
	height: 27px;
	display: inline-block;
}

.icon-fa-arrow-left {
	@include svg-fa-arrow-left()
}
.icon-fa-arrow-right {
	@include svg-fa-arrow-right()
}
.icon-facebook {
	@include svg-facebook()
}
.icon-ico-breadcrumb {
	@include svg-ico-breadcrumb()
}
.icon-ico-grid {
	@include svg-ico-grid()
}
.icon-ico-map {
	@include svg-ico-map()
}
.icon-instagram {
	@include svg-instagram()
}
.icon-linkedin {
	@include svg-linkedin()
}
.icon-swiper-button-next {
	@include svg-swiper-button-next()
}
.icon-swiper-button-next-s {
	@include svg-swiper-button-next-s()
}
.icon-swiper-button-prev {
	@include svg-swiper-button-prev()
}
.icon-swiper-button-prev-s {
	@include svg-swiper-button-prev-s()
}
.icon-telegram {
	@include svg-telegram()
}
.icon-viber {
	@include svg-viber()
}
