@import "swiper/core";
@import "swiper/arrow";
@import "swiper/pagination";

// font-family: 'Montserrat', sans-serif;
// font-family: 'Playfair Display', serif;
.b-hero {
  .l__loyalty {
	position : absolute;
	right    : 5px;
	top      : 82px;
	z-index  : 99;
  }
}

.b-hero__main {
  text-align : center;
  position   : absolute;
  display    : inline-block;
  left       : 0;
  right      : 0;
  top        : 50%;
  transform  : translateY(-50%);
  color      : #FFF;
  z-index    : 11;

  .b-hero__title {
	position       : relative;
	font-size      : 60px;
	line-height    : 1;
	padding-bottom : 30px;
	text-shadow    : 4px 4px 10px #000;
	letter-spacing : -10px;
	transition     : letter-spacing 1.5s;
	@media screen and (max-width : 800px) {
	  font-size : 40px;
	}
	@media screen and (max-width : 600px) {
	  font-size : 30px;
	}

	.swiper-slide-active & {
	  letter-spacing : 5px
	}
  }

  .b-hero__subtitle {
	padding-top    : 25px;
	font-weight    : lighter;
	font-size      : 27px;
	text-shadow    : 4px 4px 10px #000;
	letter-spacing : -10px;
	transition     : letter-spacing 1.5s;
	@media screen and (max-width : 600px) {
	  font-size : 20px;
	}

	.swiper-slide-active & {
	  letter-spacing : 5px
	}
  }
}

.b-hero__image {
  img {
	width : 100%;
  }

  .big {
	display : none;
	@media screen and (min-width : 800px) {
	  display : block
	}
  }

  .small {
	display : none;
	@media screen and (max-width : 800px) {
	  display : block;
	}
  }
}

[data-slider="spots"] {
  .swiper-wrapper {

  }

  .button-brown {
	width : 100%;
  }

  .swiper-button-prev {
	float       : left;
	margin-left : -100px;
  }

  .swiper-button-next {
	float        : right;
	margin-right : -100px;
  }

}

[data-slider="partner"] {
  .swiper-slide {
	padding    : 0;
	box-sizing : border-box;
	width      : (100 / 2) * 1%;
	@media screen and (min-width : 336px) {
	  width : (100 / 3) * 1%;
	}
	@media screen and (min-width : 541px) {
	  width : (100 / 5) * 1%;
	}
	@media screen and (min-width : 679px) {
	  width : (100 / 6) * 1%;
	}
	@media screen and (min-width : 841px) {
	  width : (100 / 7) * 1%;
	}

  }
}