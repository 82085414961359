.page-wrapper {
	overflow : hidden;
}

.wrapper {
	-moz-box-sizing    : border-box;
	-webkit-box-sizing : border-box;
	box-sizing         : border-box;
	width              : (1500 + 40) * 1px;
	max-width          : 100%;
	margin             : auto;
	padding            : 0 10px;
	@media screen and (min-width : $break-table) {
		padding : 0 40px;
	}
	@media screen and (min-width : $break-desktop) {

	}
}