.swiper-container {
	margin-left  : auto;
	margin-right : auto;
	position     : relative;
	overflow     : hidden;
	z-index      : 1;
}

.no-flexbox .swiper-slide {
	float : left;
}

.swiper-container-vertical > .swiper-wrapper {
	flex-direction : column;
}

.swiper-wrapper {
	position            : relative;
	width               : 100%;
	height              : 100%;
	z-index             : 1;
	display             : flex;
	transition-property : transform;
	-webkit-box-sizing  : content-box;
	-moz-box-sizing     : content-box;
	box-sizing          : content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	-webkit-transform : translate3d(0px, 0, 0);
	-moz-transform    : translate3d(0px, 0, 0);
	-ms-transform     : translate3d(0px, 0, 0);
	transform         : translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap : wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
	flex-wrap      : wrap;
	flex-direction : column;
}

.swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function : ease-out;
	margin                     : 0 auto;
}

.swiper-slide {
	flex-shrink         : 0;
	width               : 100%;
	height              : 100%;
	position            : relative;
	transition-property : transform;
}

.swiper-slide-invisible-blank {
	visibility : hidden;
}

/* Auto Height */
.swiper-container-autoheight {
	&, .swiper-slide {
		height : auto;
	}

	.swiper-wrapper {
		align-items         : flex-start;
		transition-property : transform, height;
	}
}

/* CSS Mode */
.swiper-container-css-mode {
	> .swiper-wrapper {
		overflow           : auto;
		scrollbar-width    : none; /* For Firefox */
		-ms-overflow-style : none; /* For Internet Explorer and Edge */
		&::-webkit-scrollbar {
			display : none;
		}
	}

	> .swiper-wrapper > .swiper-slide {
		scroll-snap-align : start start;
	}
}

.swiper-container-horizontal.swiper-container-css-mode {
	> .swiper-wrapper {
		scroll-snap-type : x mandatory;
	}
}

.swiper-container-vertical.swiper-container-css-mode {
	> .swiper-wrapper {
		scroll-snap-type : y mandatory;
	}
}
