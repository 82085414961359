.cookie-panel {
  position         : fixed;
  bottom           : 1rem;
  left             : 1rem;
  max-width        : 650px;
  color            : rgb(255, 255, 255);
  background-color : rgb(27, 18, 9);
  z-index          : 1000;
  display          : none;

  &.is-show {
	display    : block;
	box-shadow : 0 0 15px rgba(0, 0, 0, .15);
	@media screen and (min-width : 571px) {
	  display         : flex;
	  flex-flow       : row nowrap;
	  justify-content : space-between;
	  align-items     : stretch;
	}
  }
}

.cookie-panel__text {
  padding            : 10px;
  display            : flex;
  flex-flow          : row nowrap;
  justify-content    : flex-start;
  align-items        : center;
  -webkit-box-sizing : border-box;
  -moz-box-sizing    : border-box;
  box-sizing         : border-box;
  flex               : 1 1 auto;
  font-size          : 13px;
  @media screen and (min-width : 571px) {
	font-size : 14px;
  }

  a {
	color : #f2d7c1;
  }
}

.cookie-panel__button {
  flex             : 0 0 auto;
  display          : flex;
  flex-flow        : row nowrap;
  justify-content  : center;
  align-items      : center;
  font-size        : .9em;
  border-width     : 2px;
  border-style     : solid;
  text-align       : center;
  white-space      : nowrap;
  color            : #2e2e2d;
  background-color : #f2d7c1;
  border-color     : transparent;
  margin           : 0;
  padding          : .8em 1.8em;
  transition       : opacity .3s ease;
  cursor           : pointer;

  &:hover {
	opacity : .8;
  }
}
