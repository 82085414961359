
:root {
  --blue                   : #007BFF;
  --indigo                 : #6610F2;
  --purple                 : #6F42C1;
  --pink                   : #E83E8C;
  --red                    : #DC3545;
  --orange                 : #FD7E14;
  --yellow                 : #FFC107;
  --green                  : #28A745;
  --teal                   : #20C997;
  --cyan                   : #17A2B8;
  --gray                   : #6C757D;
  --gray-dark              : #343A40;
  --primary                : #007BFF;
  --secondary              : #6C757D;
  --success                : #28A745;
  --info                   : #17A2B8;
  --warning                : #FFC107;
  --danger                 : #DC3545;
  --light                  : #F8F9FA;
  --dark                   : #343A40;
  --black                  : black;
  --white                  : white;
  --gray-12                : #898989;
  --brown                  : #13395e;
  --bgbrown                : #13395e;
  --bgwhite                : white;
  --bggray                 : #6D6D6D;
  --breakpoint-xs          : 0;
  --breakpoint-sm          : 576px;
  --breakpoint-md          : 768px;
  --breakpoint-lg          : 992px;
  --breakpoint-xl          : 1200px;
  --font-family-sans-serif : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace  : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*, ::after, ::before {
  box-sizing : border-box
}

html {
  font-family              : sans-serif;
  line-height              : 1.15;
  -webkit-text-size-adjust : 100%;
  -ms-text-size-adjust     : 100%;
  -ms-overflow-style       : scrollbar
}

body {
  margin           : 0;
  font-family      : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size        : 1rem;
  font-weight      : 400;
  line-height      : 1.5;
  color            : #212529;
  text-align       : left;
  background-color : #FFF
}

.clean-list, .p0 {
  padding : 0
}

[tabindex="-1"]:focus, a:focus {
  outline : 0 !important
}

a:not([href]):not([tabindex]):focus,
input[type=email],
input[type=email]:active,
input[type=email]:focus,
input[type=text],
input[type=text]:active,
input[type=text]:focus,
textarea,
textarea:active,
textarea:focus {
  outline : 0
}

img, svg {
  vertical-align : middle
}

pre, textarea {
  overflow : auto
}

address, legend {
  line-height : inherit
}

progress, sub, sup {
  vertical-align : baseline
}

.clean-list li, label, output {
  display : inline-block
}

button, hr, input {
  overflow : visible
}

.dropdown,
.dropleft,
.dropright,
.dropup,
sub,
sup {
  position : relative
}

.owl-carousel,
.owl-carousel .owl-item,
html {
  -webkit-tap-highlight-color : transparent
}

.owl-carousel .owl-item,
.owl-carousel .owl-video-play-icon,
body {
  -webkit-backface-visibility : hidden
}

.century, b, strong {
  font-family : 'Montserrat', sans-serif;
}

.century-bold {
  font-family : 'Montserrat', sans-serif;;
  font-weight : 700;
}

.playfair {
  font-family : 'Playfair Display', serif;
}

a {
  color : inherit
}

a:active, a:link {
  text-decoration : none
}

a:active:hover, a:link:hover {
  text-decoration : none;
  color           : inherit
}

.brown {
  color : #13395e
}

.pink {
  color : #f2d7c1
}


.img-full {
  width : 100% !important
}

.clean-list li {
  list-style-type : none
}

article, aside, figcaption, figure, footer, header, hgroup, legend, main, nav, section {
  display : block
}

input[type=text] {
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none
}

.black, .black a {
  color : #000
}

.white, .white a {
  color : #FFF
}

.gray-12, .gray-12 a {
  color : #898989
}

.brown, .brown a {
  color : #13395e
}

.bgbrown {
  background-color : #13395e
}

.bgwhite {
  background-color : #FFF
}

.bggray {
  background-color : #6D6D6D
}

.module-authentic, .module-heritage {
  margin-bottom : 30px
}

.module-slider .owl-item.active .title-slider,
.module-slider .owl-item.active .desc-slider,
.dtp .dtp-title {
  letter-spacing : 5px
}

dl, h1, h2, h3, h4, h5, h6, ol, p, pre, ul {
  margin-top : 0
}

address, dl, ol, p, pre, ul {
  margin-bottom : 1rem
}

dd, h1, h2, h3, h4, h5, h6, label {
  margin-bottom : .5rem
}

abbr[data-original-title], abbr[title] {
  text-decoration : underline;
  cursor          : help;
  border-bottom   : 0
}

.close:not(:disabled):not(.disabled), summary {
  cursor : pointer
}

address {
  font-style : normal
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom : 0
}

dt {
  font-weight : 700
}

dd {
  margin-left : 0
}

blockquote, figure {
  margin : 0 0 1rem
}

dfn {
  font-style : italic
}

b, strong {
  font-weight : bolder
}

small {
  font-size : 80%
}

sub, sup {
  font-size   : 75%;
  line-height : 0
}

sub {
  bottom : -.25em
}

sup {
  top : -.5em
}

a {
  text-decoration  : none !important;
  background-color : transparent;

  &:hover {
	color           : #0056B3;
	text-decoration : underline
  }

  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):focus,
  &:not([href]):not([tabindex]):hover {
	color           : inherit;
	text-decoration : none
  }
}

code, kbd, pre, samp {
  font-family : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size   : 1em
}

pre {
  -ms-overflow-style : scrollbar
}

img {
  border-style : none
}

svg {
  overflow : hidden
}

table {
  border-collapse : collapse
}

caption {
  padding-top    : .75rem;
  padding-bottom : .75rem;
  color          : #6C757D;
  text-align     : left;
  caption-side   : bottom
}

th {
  text-align : inherit
}

button {
  border-radius : 0;

  &:focus {
	outline : dotted 1px;
  }
}

button, input, optgroup, select, textarea {
  margin      : 0;
  font-family : inherit;
  font-size   : inherit;
  line-height : inherit
}

button, select {
  text-transform : none
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance : button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding      : 0;
  border-style : none
}

input[type=radio],
input[type=checkbox] {
  box-sizing : border-box;
  padding    : 0
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance : listbox
}

textarea {
  resize : vertical
}

fieldset {
  min-width : 0;
  padding   : 0;
  margin    : 0;
  border    : 0
}

legend {
  width         : 100%;
  max-width     : 100%;
  padding       : 0;
  margin-bottom : .5rem;
  font-size     : 1.5rem;
  color         : inherit;
  white-space   : normal
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height : auto
}

[type=search] {
  outline-offset     : -2px;
  -webkit-appearance : none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance : none
}

::-webkit-file-upload-button {
  font               : inherit;
  -webkit-appearance : button
}

.display-1, .display-2, .display-3, .display-4 {
  line-height : 1.2
}

summary {
  display : list-item
}

template {
  display : none
}

[hidden] {
  display : none !important
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom : .5rem;
  font-family   : inherit;
  font-weight   : 500;
  line-height   : 1.2;
  color         : inherit
}

.blockquote, hr {
  margin-bottom : 1rem
}

.display-1, .display-2, .display-3, .display-4, .lead {
  font-weight : 300
}

.h1, h1 {
  font-size   : 2.5rem;
  font-family : "Playfair Display", sans-serif;
}

.h2, h2 {
  font-size   : 2rem;
  font-family : "Playfair Display", sans-serif;
}

.h3, h3 {
  font-size   : 1.75rem;
  font-family : 'Playfair Display', serif;
}

.h4, h4 {
  font-size : 1.5rem
}


.lead {
  font-size : 1.25rem
}

.display-1 {
  font-size : 6rem
}

.display-2 {
  font-size : 5.5rem
}

.display-3 {
  font-size : 4.5rem
}

.display-4 {
  font-size : 3.5rem
}

hr {
  box-sizing : content-box;
  height     : 0;
  margin-top : 1rem;
  border     : 0;
  border-top : 1px solid rgba(0, 0, 0, .1)
}

.img-fluid, .img-thumbnail {
  max-width : 100%;
  height    : auto
}

.small, small {
  font-size   : 80%;
  font-weight : 400
}

.mark, mark {
  padding          : .2em;
  background-color : #FCF8E3
}

.list-inline, .list-unstyled {
  padding-left : 0;
  list-style   : none
}

.list-inline-item {
  display : inline-block
}

.list-inline-item:not(:last-child) {
  margin-right : .5rem
}

.initialism {
  font-size      : 90%;
  text-transform : uppercase
}

.blockquote {
  font-size : 1.25rem
}

.blockquote-footer {
  display   : block;
  font-size : 80%;
  color     : #6C757D
}

.blockquote-footer::before {
  content : "\2014 \00A0"
}

.img-thumbnail {
  padding          : .25rem;
  background-color : #FFF;
  border           : 1px solid #DEE2E6;
  border-radius    : .25rem
}

.container, .container-fluid {
  margin-right : auto;
  margin-left  : auto;
  width        : 100%;
}

.figure {
  display : inline-block
}

.figure-img {
  margin-bottom : .5rem;
  line-height   : 1
}

.figure-caption {
  font-size : 90%;
  color     : #6C757D
}

@media (min-width : 576px) {
  .container {
	max-width : 540px
  }
}

@media (min-width : 768px) {
  .container {
	max-width : 720px
  }
}

@media (min-width : 992px) {
  .container {
	max-width : 960px
  }
}

@media (min-width : 1200px) {
  .container {
	max-width : 1140px
  }
}

.row {
  display      : flex;
  flex-wrap    : wrap;
  margin-right : -15px;
  margin-left  : -15px
}

.no-gutters {
  margin-right : 0;
  margin-left  : 0
}

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right : 0;
  padding-left  : 0
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position      : relative;
  width         : 100%;
  min-height    : 1px;
  padding-right : 15px;
  padding-left  : 15px
}

.col {
  flex-basis : 0;
  flex-grow  : 1;
  max-width  : 100%
}

.col-auto {
  flex      : 0 0 auto;
  width     : auto;
  max-width : none
}

.col-1 {
  flex      : 0 0 8.33333%;
  max-width : 8.33333%
}

.col-2 {
  flex      : 0 0 16.66667%;
  max-width : 16.66667%
}

.col-3 {
  flex      : 0 0 25%;
  max-width : 25%
}

.col-4 {
  flex      : 0 0 33.33333%;
  max-width : 33.33333%
}

.col-5 {
  flex      : 0 0 41.66667%;
  max-width : 41.66667%
}

.col-6 {
  flex      : 0 0 50%;
  max-width : 50%
}

.col-7 {
  flex      : 0 0 58.33333%;
  max-width : 58.33333%
}

.col-8 {
  flex      : 0 0 66.66667%;
  max-width : 66.66667%
}

.col-9 {
  flex      : 0 0 75%;
  max-width : 75%
}

.col-10 {
  flex      : 0 0 83.33333%;
  max-width : 83.33333%
}

.col-11 {
  flex      : 0 0 91.66667%;
  max-width : 91.66667%
}

.col-12 {
  flex      : 0 0 100%;
  max-width : 100%
}

.order-first {
  order : -1
}

.order-last {
  order : 13
}

@for $i from 1 through 11 {
  .order-#{$i} {
	order : $i;
  }
}

.offset-1 {
  margin-left : 8.33333%
}

.offset-2 {
  margin-left : 16.66667%
}

.offset-3 {
  margin-left : 25%
}

.offset-4 {
  margin-left : 33.33333%
}

.offset-5 {
  margin-left : 41.66667%
}

.offset-6 {
  margin-left : 50%
}

.offset-7 {
  margin-left : 58.33333%
}

.offset-8 {
  margin-left : 66.66667%
}

.offset-9 {
  margin-left : 75%
}

.offset-10 {
  margin-left : 83.33333%
}

.offset-11 {
  margin-left : 91.66667%
}

@media (min-width : 576px) {
  .col-sm {
	flex-basis : 0;
	flex-grow  : 1;
	max-width  : 100%
  }

  .col-sm-auto {
	flex      : 0 0 auto;
	width     : auto;
	max-width : none
  }

  .col-sm-1 {
	flex      : 0 0 8.33333%;
	max-width : 8.33333%
  }

  .col-sm-2 {
	flex      : 0 0 16.66667%;
	max-width : 16.66667%
  }

  .col-sm-3 {
	flex      : 0 0 25%;
	max-width : 25%
  }

  .col-sm-4 {
	flex      : 0 0 33.33333%;
	max-width : 33.33333%
  }

  .col-sm-5 {
	flex      : 0 0 41.66667%;
	max-width : 41.66667%
  }

  .col-sm-6 {
	flex      : 0 0 50%;
	max-width : 50%
  }

  .col-sm-7 {
	flex      : 0 0 58.33333%;
	max-width : 58.33333%
  }

  .col-sm-8 {
	flex      : 0 0 66.66667%;
	max-width : 66.66667%
  }

  .col-sm-9 {
	flex      : 0 0 75%;
	max-width : 75%
  }

  .col-sm-10 {
	flex      : 0 0 83.33333%;
	max-width : 83.33333%
  }

  .col-sm-11 {
	flex      : 0 0 91.66667%;
	max-width : 91.66667%
  }

  .col-sm-12 {
	flex      : 0 0 100%;
	max-width : 100%
  }

  .order-sm-first {
	order : -1
  }

  @for $i from 1 through 12 {
	.order-sm-#{$i} {
	  order : $i;
	}
  }
  .order-sm-last {
	order : 13
  }

  .offset-sm-0 {
	margin-left : 0
  }

  .offset-sm-1 {
	margin-left : 8.33333%
  }

  .offset-sm-2 {
	margin-left : 16.66667%
  }

  .offset-sm-3 {
	margin-left : 25%
  }

  .offset-sm-4 {
	margin-left : 33.33333%
  }

  .offset-sm-5 {
	margin-left : 41.66667%
  }

  .offset-sm-6 {
	margin-left : 50%
  }

  .offset-sm-7 {
	margin-left : 58.33333%
  }

  .offset-sm-8 {
	margin-left : 66.66667%
  }

  .offset-sm-9 {
	margin-left : 75%
  }

  .offset-sm-10 {
	margin-left : 83.33333%
  }

  .offset-sm-11 {
	margin-left : 91.66667%
  }
}

@media (min-width : 768px) {
  .col-md {
	flex-basis : 0;
	flex-grow  : 1;
	max-width  : 100%
  }

  .col-md-auto {
	flex      : 0 0 auto;
	width     : auto;
	max-width : none
  }

  .col-md-1 {
	flex      : 0 0 8.33333%;
	max-width : 8.33333%
  }

  .col-md-2 {
	flex      : 0 0 16.66667%;
	max-width : 16.66667%
  }

  .col-md-3 {
	flex      : 0 0 25%;
	max-width : 25%
  }

  .col-md-4 {
	flex      : 0 0 33.33333%;
	max-width : 33.33333%
  }

  .col-md-5 {
	flex      : 0 0 41.66667%;
	max-width : 41.66667%
  }

  .col-md-6 {
	flex      : 0 0 50%;
	max-width : 50%
  }

  .col-md-7 {
	flex      : 0 0 58.33333%;
	max-width : 58.33333%
  }

  .col-md-8 {
	flex      : 0 0 66.66667%;
	max-width : 66.66667%
  }

  .col-md-9 {
	flex      : 0 0 75%;
	max-width : 75%
  }

  .col-md-10 {
	flex      : 0 0 83.33333%;
	max-width : 83.33333%
  }

  .col-md-11 {
	flex      : 0 0 91.66667%;
	max-width : 91.66667%
  }

  .col-md-12 {
	flex      : 0 0 100%;
	max-width : 100%
  }

  .order-md-first {
	order : -1
  }

  .order-md-last {
	order : 13
  }
  @for $i from 0 through 12 {
	.order-md-#{$i} {
	  order : $i;
	}
  }

  .offset-md-0 {
	margin-left : 0
  }

  .offset-md-1 {
	margin-left : 8.33333%
  }

  .offset-md-2 {
	margin-left : 16.66667%
  }

  .offset-md-3 {
	margin-left : 25%
  }

  .offset-md-4 {
	margin-left : 33.33333%
  }

  .offset-md-5 {
	margin-left : 41.66667%
  }

  .offset-md-6 {
	margin-left : 50%
  }

  .offset-md-7 {
	margin-left : 58.33333%
  }

  .offset-md-8 {
	margin-left : 66.66667%
  }

  .offset-md-9 {
	margin-left : 75%
  }

  .offset-md-10 {
	margin-left : 83.33333%
  }

  .offset-md-11 {
	margin-left : 91.66667%
  }
}

@media (min-width : 992px) {
  .col-lg {
	flex-basis : 0;
	flex-grow  : 1;
	max-width  : 100%
  }

  .col-lg-auto {
	flex      : 0 0 auto;
	width     : auto;
	max-width : none
  }

  .col-lg-1 {
	flex      : 0 0 8.33333%;
	max-width : 8.33333%
  }

  .col-lg-2 {
	flex      : 0 0 16.66667%;
	max-width : 16.66667%
  }

  .col-lg-3 {
	flex      : 0 0 25%;
	max-width : 25%
  }

  .col-lg-4 {
	flex      : 0 0 33.33333%;
	max-width : 33.33333%
  }

  .col-lg-5 {
	flex      : 0 0 41.66667%;
	max-width : 41.66667%
  }

  .col-lg-6 {
	flex      : 0 0 50%;
	max-width : 50%
  }

  .col-lg-7 {
	flex      : 0 0 58.33333%;
	max-width : 58.33333%
  }

  .col-lg-8 {
	flex      : 0 0 66.66667%;
	max-width : 66.66667%
  }

  .col-lg-9 {
	flex      : 0 0 75%;
	max-width : 75%
  }

  .col-lg-10 {
	flex      : 0 0 83.33333%;
	max-width : 83.33333%
  }

  .col-lg-11 {
	flex      : 0 0 91.66667%;
	max-width : 91.66667%
  }

  .col-lg-12 {
	flex      : 0 0 100%;
	max-width : 100%
  }

  .order-lg-first {
	order : -1
  }

  .order-lg-last {
	order : 13
  }
  @for $i from 0 through 12 {
	.order-lg-#{$i} {
	  order : $i;
	}
  }

  .offset-lg-0 {
	margin-left : 0
  }

  .offset-lg-1 {
	margin-left : 8.33333%
  }

  .offset-lg-2 {
	margin-left : 16.66667%
  }

  .offset-lg-3 {
	margin-left : 25%
  }

  .offset-lg-4 {
	margin-left : 33.33333%
  }

  .offset-lg-5 {
	margin-left : 41.66667%
  }

  .offset-lg-6 {
	margin-left : 50%
  }

  .offset-lg-7 {
	margin-left : 58.33333%
  }

  .offset-lg-8 {
	margin-left : 66.66667%
  }

  .offset-lg-9 {
	margin-left : 75%
  }

  .offset-lg-10 {
	margin-left : 83.33333%
  }

  .offset-lg-11 {
	margin-left : 91.66667%
  }
}

@media (min-width : 1200px) {
  .col-xl {
	flex-basis : 0;
	flex-grow  : 1;
	max-width  : 100%
  }

  .col-xl-auto {
	flex      : 0 0 auto;
	width     : auto;
	max-width : none
  }

  .col-xl-1 {
	flex      : 0 0 8.33333%;
	max-width : 8.33333%
  }

  .col-xl-2 {
	flex      : 0 0 16.66667%;
	max-width : 16.66667%
  }

  .col-xl-3 {
	flex      : 0 0 25%;
	max-width : 25%
  }

  .col-xl-4 {
	flex      : 0 0 33.33333%;
	max-width : 33.33333%
  }

  .col-xl-5 {
	flex      : 0 0 41.66667%;
	max-width : 41.66667%
  }

  .col-xl-6 {
	flex      : 0 0 50%;
	max-width : 50%
  }

  .col-xl-7 {
	flex      : 0 0 58.33333%;
	max-width : 58.33333%
  }

  .col-xl-8 {
	flex      : 0 0 66.66667%;
	max-width : 66.66667%
  }

  .col-xl-9 {
	flex      : 0 0 75%;
	max-width : 75%
  }

  .col-xl-10 {
	flex      : 0 0 83.33333%;
	max-width : 83.33333%
  }

  .col-xl-11 {
	flex      : 0 0 91.66667%;
	max-width : 91.66667%
  }

  .col-xl-12 {
	flex      : 0 0 100%;
	max-width : 100%
  }

  .order-xl-first {
	order : -1
  }

  .order-xl-last {
	order : 13
  }

  @for $i from 1 through 12 {
	.order-xl-#{$i} {
	  order : $i;
	}
  }

  .offset-xl-0 {
	margin-left : 0
  }

  .offset-xl-1 {
	margin-left : 8.33333%
  }

  .offset-xl-2 {
	margin-left : 16.66667%
  }

  .offset-xl-3 {
	margin-left : 25%
  }

  .offset-xl-4 {
	margin-left : 33.33333%
  }

  .offset-xl-5 {
	margin-left : 41.66667%
  }

  .offset-xl-6 {
	margin-left : 50%
  }

  .offset-xl-7 {
	margin-left : 58.33333%
  }

  .offset-xl-8 {
	margin-left : 66.66667%
  }

  .offset-xl-9 {
	margin-left : 75%
  }

  .offset-xl-10 {
	margin-left : 83.33333%
  }

  .offset-xl-11 {
	margin-left : 91.66667%
  }
}

.table {
  width            : 100%;
  margin-bottom    : 1rem;
  background-color : transparent
}

.table td, .table th {
  padding        : .75rem;
  vertical-align : top;
  border-top     : 1px solid #DEE2E6
}

.table thead th {
  vertical-align : bottom;
  border-bottom  : 2px solid #DEE2E6
}

.table tbody + tbody {
  border-top : 2px solid #DEE2E6
}

.table .table {
  background-color : #FFF
}

.table-sm td, .table-sm th {
  padding : .3rem
}

.table-bordered, .table-bordered td, .table-bordered th {
  border : 1px solid #DEE2E6
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width : 2px
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border : 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color : rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
  background-color : rgba(0, 0, 0, .075)
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color : #B8DAFF
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color : #9FCDFF
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color : #D6D8DB
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color : #C8CBCF
}

.table-success, .table-success > td, .table-success > th {
  background-color : #C3E6CB
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color : #B1DFBB
}

.table-info, .table-info > td, .table-info > th {
  background-color : #BEE5EB
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color : #ABDDE5
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color : #FFEEBA
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color : #FFE8A1
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color : #F5C6CB
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color : #F1B0B7
}

.table-light, .table-light > td, .table-light > th {
  background-color : #FDFDFE
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color : #ECECF6
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color : #C6C8CA
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color : #B9BBBE
}

.table-black, .table-black > td, .table-black > th {
  background-color : #B8B8B8
}

.table-hover .table-black:hover, .table-hover .table-black:hover > td, .table-hover .table-black:hover > th {
  background-color : #ABABAB
}

.table-white, .table-white > td, .table-white > th {
  background-color : #FFF
}

.table-hover .table-white:hover, .table-hover .table-white:hover > td, .table-hover .table-white:hover > th {
  background-color : #F2F2F2
}

.table-gray-12, .table-gray-12 > td, .table-gray-12 > th {
  background-color : #DEDEDE
}

.table-hover .table-gray-12:hover, .table-hover .table-gray-12:hover > td, .table-hover .table-gray-12:hover > th {
  background-color : #D1D1D1
}

.table-brown, .table-brown > td, .table-brown > th {
  background-color : #EFE3D7
}

.table-hover .table-brown:hover, .table-hover .table-brown:hover > td, .table-hover .table-brown:hover > th {
  background-color : #E8D6C5
}

.table-bgbrown, .table-bgbrown > td, .table-bgbrown > th {
  background-color : #EFE3D7
}

.table-hover .table-bgbrown:hover, .table-hover .table-bgbrown:hover > td, .table-hover .table-bgbrown:hover > th {
  background-color : #E8D6C5
}

.table-bgwhite, .table-bgwhite > td, .table-bgwhite > th {
  background-color : #FFF
}

.table-hover .table-bgwhite:hover, .table-hover .table-bgwhite:hover > td, .table-hover .table-bgwhite:hover > th {
  background-color : #F2F2F2
}

.table-bggray, .table-bggray > td, .table-bggray > th {
  background-color : #D6D6D6
}

.table-hover .table-bggray:hover, .table-hover .table-bggray:hover > td, .table-hover .table-bggray:hover > th {
  background-color : #C9C9C9
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color : rgba(0, 0, 0, .075)
}

.table .thead-dark th {
  color            : #FFF;
  background-color : #212529;
  border-color     : #32383E
}

.table .thead-light th {
  color            : #495057;
  background-color : #E9ECEF;
  border-color     : #DEE2E6
}

.table-dark {
  color            : #FFF;
  background-color : #212529
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color : #32383E
}

.table-dark.table-bordered, .table-responsive > .table-bordered {
  border : 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color : rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
  background-color : rgba(255, 255, 255, .075)
}

@media (max-width : 575.98px) {
  .table-responsive-sm {
	display                    : block;
	width                      : 100%;
	overflow-x                 : auto;
	-webkit-overflow-scrolling : touch;
	-ms-overflow-style         : -ms-autohiding-scrollbar
  }

  .table-responsive-sm > .table-bordered {
	border : 0
  }
}

@media (max-width : 767.98px) {
  .table-responsive-md {
	display                    : block;
	width                      : 100%;
	overflow-x                 : auto;
	-webkit-overflow-scrolling : touch;
	-ms-overflow-style         : -ms-autohiding-scrollbar
  }

  .table-responsive-md > .table-bordered {
	border : 0
  }
}

@media (max-width : 991.98px) {
  .table-responsive-lg {
	display                    : block;
	width                      : 100%;
	overflow-x                 : auto;
	-webkit-overflow-scrolling : touch;
	-ms-overflow-style         : -ms-autohiding-scrollbar
  }

  .table-responsive-lg > .table-bordered {
	border : 0
  }
}

@media (max-width : 1199.98px) {
  .table-responsive-xl {
	display                    : block;
	width                      : 100%;
	overflow-x                 : auto;
	-webkit-overflow-scrolling : touch;
	-ms-overflow-style         : -ms-autohiding-scrollbar
  }

  .table-responsive-xl > .table-bordered {
	border : 0
  }
}

.table-responsive {
  display                    : block;
  width                      : 100%;
  overflow-x                 : auto;
  -webkit-overflow-scrolling : touch;
  -ms-overflow-style         : -ms-autohiding-scrollbar
}

.dropdown-toggle::after, .dropup .dropdown-toggle::after {
  width          : 0;
  height         : 0;
  vertical-align : .255em;
  content        : ""
}

.dropdown-toggle::after {
  display       : inline-block;
  margin-left   : .255em;
  border-top    : .3em solid;
  border-right  : .3em solid transparent;
  border-bottom : 0;
  border-left   : .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left : 0
}

.dropdown-menu {
  position         : absolute;
  top              : 100%;
  left             : 0;
  z-index          : 1000;
  display          : none;
  float            : left;
  min-width        : 10rem;
  padding          : .5rem 0;
  margin           : .125rem 0 0;
  font-size        : 1rem;
  color            : #212529;
  text-align       : left;
  list-style       : none;
  background-color : #FFF;
  background-clip  : padding-box;
  border           : 1px solid rgba(0, 0, 0, .15);
  border-radius    : .25rem
}

.dropdown-menu-right {
  right : 0;
  left  : auto
}

.dropup .dropdown-menu {
  top           : auto;
  bottom        : 100%;
  margin-top    : 0;
  margin-bottom : .125rem
}

.dropup .dropdown-toggle::after {
  display       : inline-block;
  margin-left   : .255em;
  border-top    : 0;
  border-right  : .3em solid transparent;
  border-bottom : .3em solid;
  border-left   : .3em solid transparent
}

.dropleft .dropdown-toggle::before, .dropright .dropdown-toggle::after {
  width         : 0;
  border-top    : .3em solid transparent;
  border-bottom : .3em solid transparent;
  height        : 0;
  content       : ""
}

.dropup .dropdown-toggle:empty::after {
  margin-left : 0
}

.dropright .dropdown-menu {
  top         : 0;
  right       : auto;
  left        : 100%;
  margin-top  : 0;
  margin-left : .125rem
}

.dropright .dropdown-toggle::after {
  display        : inline-block;
  margin-left    : .255em;
  border-right   : 0;
  border-left    : .3em solid;
  vertical-align : 0
}

.dropright .dropdown-toggle:empty::after {
  margin-left : 0
}

.dropleft .dropdown-menu {
  top          : 0;
  right        : 100%;
  left         : auto;
  margin-top   : 0;
  margin-right : .125rem
}

.dropleft .dropdown-toggle::after {
  width          : 0;
  height         : 0;
  margin-left    : .255em;
  vertical-align : .255em;
  content        : "";
  display        : none
}

.dropleft .dropdown-toggle::before {
  display        : inline-block;
  margin-right   : .255em;
  border-right   : .3em solid;
  vertical-align : 0
}

.dropleft .dropdown-toggle:empty::after {
  margin-left : 0
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right  : auto;
  bottom : auto
}

.dropdown-divider {
  height     : 0;
  margin     : .5rem 0;
  overflow   : hidden;
  border-top : 1px solid #E9ECEF
}

.dropdown-item {
  display          : block;
  width            : 100%;
  padding          : .25rem 1.5rem;
  clear            : both;
  font-weight      : 400;
  color            : #212529;
  text-align       : inherit;
  white-space      : nowrap;
  background-color : transparent;
  border           : 0
}

.alert-link, .close {
  font-weight : 700
}

.dropdown-item:focus, .dropdown-item:hover {
  color            : #16181B;
  text-decoration  : none;
  background-color : #F8F9FA
}

.dropdown-item.active, .dropdown-item:active {
  color            : #FFF;
  text-decoration  : none;
  background-color : #007BFF
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color            : #6C757D;
  background-color : transparent
}

.dropdown-menu.show {
  display : block
}

.dropdown-header {
  display       : block;
  padding       : .5rem 1.5rem;
  margin-bottom : 0;
  font-size     : .875rem;
  color         : #6C757D;
  white-space   : nowrap
}

.dropdown-item-text {
  display : block;
  padding : .25rem 1.5rem;
  color   : #212529
}

.alert {
  position      : relative;
  padding       : .75rem 1.25rem;
  margin-bottom : 1rem;
  border        : 1px solid transparent;
  border-radius : .25rem
}

.alert-heading {
  color : inherit
}

.alert-dismissible {
  padding-right : 4rem
}

.alert-dismissible .close {
  position : absolute;
  top      : 0;
  right    : 0;
  padding  : .75rem 1.25rem;
  color    : inherit
}

.alert-primary {
  color            : #004085;
  background-color : #CCE5FF;
  border-color     : #B8DAFF
}

.alert-primary hr {
  border-top-color : #9FCDFF
}

.alert-primary .alert-link {
  color : #002752
}

.alert-secondary {
  color            : #383D41;
  background-color : #E2E3E5;
  border-color     : #D6D8DB
}

.alert-secondary hr {
  border-top-color : #C8CBCF
}

.alert-secondary .alert-link {
  color : #202326
}

.alert-success {
  color            : #155724;
  background-color : #D4EDDA;
  border-color     : #C3E6CB
}

.alert-success hr {
  border-top-color : #B1DFBB
}

.alert-success .alert-link {
  color : #0B2E13
}

.alert-info {
  color            : #0C5460;
  background-color : #D1ECF1;
  border-color     : #BEE5EB
}

.alert-info hr {
  border-top-color : #ABDDE5
}

.alert-info .alert-link {
  color : #062C33
}

.alert-warning {
  color            : #856404;
  background-color : #FFF3CD;
  border-color     : #FFEEBA
}

.alert-warning hr {
  border-top-color : #FFE8A1
}

.alert-warning .alert-link {
  color : #533F03
}

.alert-danger {
  color            : #721C24;
  background-color : #F8D7DA;
  border-color     : #F5C6CB
}

.alert-danger hr {
  border-top-color : #F1B0B7
}

.alert-danger .alert-link {
  color : #491217
}

.alert-light {
  color            : #818182;
  background-color : #FEFEFE;
  border-color     : #FDFDFE
}

.alert-light hr {
  border-top-color : #ECECF6
}

.alert-light .alert-link {
  color : #686868
}

.alert-dark {
  color            : #1B1E21;
  background-color : #D6D8D9;
  border-color     : #C6C8CA
}

.alert-dark hr {
  border-top-color : #B9BBBE
}

.alert-dark .alert-link {
  color : #040505
}

.alert-black {
  color            : #000;
  background-color : #CCC;
  border-color     : #B8B8B8
}

.alert-black hr {
  border-top-color : #ABABAB
}

.alert-black .alert-link {
  color : #000
}

.alert-white {
  color            : #858585;
  background-color : #FFF;
  border-color     : #FFF
}

.alert-white hr {
  border-top-color : #F2F2F2
}

.alert-white .alert-link {
  color : #6C6C6C
}

.alert-gray-12 {
  color            : #474747;
  background-color : #E7E7E7;
  border-color     : #DEDEDE
}

.alert-gray-12 hr {
  border-top-color : #D1D1D1
}

.alert-gray-12 .alert-link {
  color : #2E2E2E
}

.alert-brown {
  color            : #66513A;
  background-color : #F3EBE2;
  border-color     : #EFE3D7
}

.alert-brown hr {
  border-top-color : #E8D6C5
}

.alert-brown .alert-link {
  color : #453728
}

.alert-bgbrown {
  color            : #66513A;
  background-color : #F3EBE2;
  border-color     : #EFE3D7
}

.alert-bgbrown hr {
  border-top-color : #E8D6C5
}

.alert-bgbrown .alert-link {
  color : #453728
}

.alert-bgwhite {
  color            : #858585;
  background-color : #FFF;
  border-color     : #FFF
}

.alert-bgwhite hr {
  border-top-color : #F2F2F2
}

.alert-bgwhite .alert-link {
  color : #6C6C6C
}

.alert-bggray {
  color            : #393939;
  background-color : #E2E2E2;
  border-color     : #D6D6D6
}

.alert-bggray hr {
  border-top-color : #C9C9C9
}

.alert-bggray .alert-link {
  color : #202020
}

.media {
  display     : flex;
  align-items : flex-start
}

.media-body {
  flex : 1
}

.close {
  float       : right;
  font-size   : 1.5rem;
  line-height : 1;
  color       : #000;
  text-shadow : 0 1px 0 #FFF;
  opacity     : .5
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  color           : #000;
  text-decoration : none;
  opacity         : .75
}

button.close {
  padding            : 0;
  background-color   : transparent;
  border             : 0;
  -webkit-appearance : none
}

.modal-open {
  overflow : hidden
}

.modal-open .modal {
  overflow-x : hidden;
  overflow-y : auto
}

.modal {
  position : fixed;
  top      : 0;
  right    : 0;
  bottom   : 0;
  left     : 0;
  z-index  : 1050;
  display  : none;
  overflow : hidden;
  outline  : 0
}

.modal-dialog {
  position       : relative;
  width          : auto;
  margin         : .5rem;
  pointer-events : none
}

.modal.fade .modal-dialog {
  transition : transform .3s ease-out;
  transform  : translate(0, -25%)
}

@media screen and (prefers-reduced-motion : reduce) {
  .modal.fade .modal-dialog {
	transition : none
  }
}

.modal.show .modal-dialog {
  transform : translate(0, 0)
}

.modal-dialog-centered {
  display     : flex;
  align-items : center;
  min-height  : calc(100% - (.5rem * 2))
}

.modal-dialog-centered::before {
  display : block;
  height  : calc(100vh - (.5rem * 2));
  content : ""
}

.modal-content {
  position         : relative;
  display          : flex;
  flex-direction   : column;
  width            : 100%;
  pointer-events   : auto;
  background-color : #FFF;
  background-clip  : padding-box;
  border           : 1px solid rgba(0, 0, 0, .2);
  outline          : 0
}

.modal-backdrop {
  position         : fixed;
  top              : 0;
  right            : 0;
  bottom           : 0;
  left             : 0;
  z-index          : 1040;
  background-color : #000
}

.modal-backdrop.fade {
  opacity : 0
}

.modal-backdrop.show {
  opacity : .5
}

.modal-header {
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;
  padding         : 1rem;
  border-bottom   : 1px solid #E9ECEF
}

.modal-header .close {
  padding : 1rem;
  margin  : -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom : 0;
  line-height   : 1.5
}

.modal-body {
  position : relative;
  flex     : 1 1 auto;
  padding  : 1rem
}

.modal-footer {
  display         : flex;
  align-items     : center;
  justify-content : flex-end;
  padding         : 1rem;
  border-top      : 1px solid #E9ECEF
}

.modal-footer > :not(:first-child) {
  margin-left : .25rem
}

.modal-footer > :not(:last-child) {
  margin-right : .25rem
}

.modal-scrollbar-measure {
  position : absolute;
  top      : -9999px;
  width    : 50px;
  height   : 50px;
  overflow : scroll
}

.align-baseline {
  vertical-align : baseline !important
}

.align-top {
  vertical-align : top !important
}

.align-middle {
  vertical-align : middle !important
}

.align-bottom {
  vertical-align : bottom !important
}

.align-text-bottom {
  vertical-align : text-bottom !important
}

.align-text-top {
  vertical-align : text-top !important
}

.bg-primary {
  background-color : #007BFF !important
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color : #0062CC !important
}

.bg-secondary {
  background-color : #6C757D !important
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color : #545B62 !important
}

.bg-success {
  background-color : #28A745 !important
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color : #1E7E34 !important
}

.bg-info {
  background-color : #17A2B8 !important
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color : #117A8B !important
}

.bg-warning {
  background-color : #FFC107 !important
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color : #D39E00 !important
}

.bg-danger {
  background-color : #DC3545 !important
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color : #BD2130 !important
}

.bg-light {
  background-color : #F8F9FA !important
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color : #DAE0E5 !important
}

.bg-dark {
  background-color : #343A40 !important
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color : #1D2124 !important
}

.bg-black, a.bg-black:focus, a.bg-black:hover, button.bg-black:focus, button.bg-black:hover {
  background-color : #000 !important
}

a.bg-white:focus, a.bg-white:hover, button.bg-white:focus, button.bg-white:hover {
  background-color : #E6E6E6 !important
}

.bg-gray-12 {
  background-color : #898989 !important
}

a.bg-gray-12:focus, a.bg-gray-12:hover, button.bg-gray-12:focus, button.bg-gray-12:hover {
  background-color : #707070 !important
}

.bg-brown {
  background-color : #13395e !important
}

a.bg-brown:focus, a.bg-brown:hover, button.bg-brown:focus, button.bg-brown:hover {
  background-color : #B6834C !important
}

.bg-bgbrown {
  background-color : #13395e !important
}

a.bg-bgbrown:focus, a.bg-bgbrown:hover, button.bg-bgbrown:focus, button.bg-bgbrown:hover {
  background-color : #B6834C !important
}

.bg-bgwhite {
  background-color : #FFF !important
}

a.bg-bgwhite:focus, a.bg-bgwhite:hover, button.bg-bgwhite:focus, button.bg-bgwhite:hover {
  background-color : #E6E6E6 !important
}

.bg-bggray {
  background-color : #6D6D6D !important
}

a.bg-bggray:focus, a.bg-bggray:hover, button.bg-bggray:focus, button.bg-bggray:hover {
  background-color : #545454 !important
}

.bg-white {
  background-color : #FFF !important
}

.bg-transparent {
  background-color : transparent !important
}

.border {
  border : 1px solid #DEE2E6 !important
}

.border-top {
  border-top : 1px solid #DEE2E6 !important
}

.border-right {
  border-right : 1px solid #DEE2E6 !important
}

.border-bottom {
  border-bottom : 1px solid #DEE2E6 !important
}

.border-left {
  border-left : 1px solid #DEE2E6 !important
}

.border-0 {
  border : 0 !important
}

.rounded-right, .rounded-top {
  border-top-right-radius : .25rem !important
}

.rounded-bottom, .rounded-right {
  border-bottom-right-radius : .25rem !important
}

.rounded-left, .rounded-top {
  border-top-left-radius : .25rem !important
}

.rounded-bottom, .rounded-left {
  border-bottom-left-radius : .25rem !important
}

.border-top-0 {
  border-top : 0 !important
}

.border-right-0 {
  border-right : 0 !important
}

.border-bottom-0 {
  border-bottom : 0 !important
}

.border-left-0 {
  border-left : 0 !important
}

.border-primary {
  border-color : #007BFF !important
}

.border-secondary {
  border-color : #6C757D !important
}

.border-success {
  border-color : #28A745 !important
}

.border-info {
  border-color : #17A2B8 !important
}

.border-warning {
  border-color : #FFC107 !important
}

.border-danger {
  border-color : #DC3545 !important
}

.border-light {
  border-color : #F8F9FA !important
}

.border-dark {
  border-color : #343A40 !important
}

.border-black {
  border-color : #000 !important
}

.border-gray-12 {
  border-color : #898989 !important
}

.border-bgbrown, .border-brown {
  border-color : #13395e !important
}

.border-bgwhite {
  border-color : #FFF !important
}

.border-bggray {
  border-color : #6D6D6D !important
}

.border-white {
  border-color : #FFF !important
}

.rounded {
  border-radius : .25rem !important
}

.rounded-circle {
  border-radius : 50% !important
}

.rounded-0 {
  border-radius : 0 !important
}

.clearfix::after {
  display : block;
  clear   : both;
  content : ""
}

.d-none {
  display : none !important
}

.d-inline {
  display : inline !important
}

.d-inline-block {
  display : inline-block !important
}

.d-block {
  display : block !important
}

.d-table {
  display : table !important
}

.d-table-row {
  display : table-row !important
}

.d-table-cell {
  display : table-cell !important
}

.d-flex {
  display : flex !important
}

.d-inline-flex {
  display : inline-flex !important
}

@media (min-width : 576px) {
  .modal-dialog {
	max-width : 500px;
	margin    : 1.75rem auto
  }

  .modal-dialog-centered {
	min-height : calc(100% - (1.75rem * 2))
  }

  .modal-dialog-centered::before {
	height : calc(100vh - (1.75rem * 2))
  }

  .modal-sm {
	max-width : 300px
  }

  .d-sm-none {
	display : none !important
  }

  .d-sm-inline {
	display : inline !important
  }

  .d-sm-inline-block {
	display : inline-block !important
  }

  .d-sm-block {
	display : block !important
  }

  .d-sm-table {
	display : table !important
  }

  .d-sm-table-row {
	display : table-row !important
  }

  .d-sm-table-cell {
	display : table-cell !important
  }

  .d-sm-flex {
	display : flex !important
  }

  .d-sm-inline-flex {
	display : inline-flex !important
  }
}

@media (min-width : 768px) {
  .d-md-none {
	display : none !important
  }

  .d-md-inline {
	display : inline !important
  }

  .d-md-inline-block {
	display : inline-block !important
  }

  .d-md-block {
	display : block !important
  }

  .d-md-table {
	display : table !important
  }

  .d-md-table-row {
	display : table-row !important
  }

  .d-md-table-cell {
	display : table-cell !important
  }

  .d-md-flex {
	display : flex !important
  }

  .d-md-inline-flex {
	display : inline-flex !important
  }
}

@media (min-width : 992px) {
  .modal-lg {
	max-width : 800px
  }

  .d-lg-none {
	display : none !important
  }

  .d-lg-inline {
	display : inline !important
  }

  .d-lg-inline-block {
	display : inline-block !important
  }

  .d-lg-block {
	display : block !important
  }

  .d-lg-table {
	display : table !important
  }

  .d-lg-table-row {
	display : table-row !important
  }

  .d-lg-table-cell {
	display : table-cell !important
  }

  .d-lg-flex {
	display : flex !important
  }

  .d-lg-inline-flex {
	display : inline-flex !important
  }
}

@media (min-width : 1200px) {
  .d-xl-none {
	display : none !important
  }

  .d-xl-inline {
	display : inline !important
  }

  .d-xl-inline-block {
	display : inline-block !important
  }

  .d-xl-block {
	display : block !important
  }

  .d-xl-table {
	display : table !important
  }

  .d-xl-table-row {
	display : table-row !important
  }

  .d-xl-table-cell {
	display : table-cell !important
  }

  .d-xl-flex {
	display : flex !important
  }

  .d-xl-inline-flex {
	display : inline-flex !important
  }
}

@media print {
  .d-print-none {
	display : none !important
  }

  .d-print-inline {
	display : inline !important
  }

  .d-print-inline-block {
	display : inline-block !important
  }

  .d-print-block {
	display : block !important
  }

  .d-print-table {
	display : table !important
  }

  .d-print-table-row {
	display : table-row !important
  }

  .d-print-table-cell {
	display : table-cell !important
  }

  .d-print-flex {
	display : flex !important
  }

  .d-print-inline-flex {
	display : inline-flex !important
  }
}

.embed-responsive {
  position : relative;
  display  : block;
  width    : 100%;
  padding  : 0;
  overflow : hidden
}

.embed-responsive::before {
  display : block;
  content : ""
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position : absolute;
  top      : 0;
  bottom   : 0;
  left     : 0;
  width    : 100%;
  height   : 100%;
  border   : 0
}

.embed-responsive-21by9::before {
  padding-top : 42.85714%
}

.embed-responsive-16by9::before {
  padding-top : 56.25%
}

.embed-responsive-4by3::before {
  padding-top : 75%
}

.embed-responsive-1by1::before {
  padding-top : 100%
}

.flex-row {
  flex-direction : row !important
}

.flex-column {
  flex-direction : column !important
}

.flex-row-reverse {
  flex-direction : row-reverse !important
}

.flex-column-reverse {
  flex-direction : column-reverse !important
}

.flex-wrap {
  flex-wrap : wrap !important
}

.flex-nowrap {
  flex-wrap : nowrap !important
}

.flex-wrap-reverse {
  flex-wrap : wrap-reverse !important
}

.flex-fill {
  flex : 1 1 auto !important
}

.flex-grow-0 {
  flex-grow : 0 !important
}

.flex-grow-1 {
  flex-grow : 1 !important
}

.flex-shrink-0 {
  flex-shrink : 0 !important
}

.flex-shrink-1 {
  flex-shrink : 1 !important
}

.justify-content-start {
  justify-content : flex-start !important
}

.justify-content-end {
  justify-content : flex-end !important
}

.justify-content-center {
  justify-content : center !important
}

.justify-content-between {
  justify-content : space-between !important
}

.justify-content-around {
  justify-content : space-around !important
}

.align-items-start {
  align-items : flex-start !important
}

.align-items-end {
  align-items : flex-end !important
}

.align-items-center {
  align-items : center !important
}

.align-items-baseline {
  align-items : baseline !important
}

.align-items-stretch {
  align-items : stretch !important
}

.align-content-start {
  align-content : flex-start !important
}

.align-content-end {
  align-content : flex-end !important
}

.align-content-center {
  align-content : center !important
}

.align-content-between {
  align-content : space-between !important
}

.align-content-around {
  align-content : space-around !important
}

.align-content-stretch {
  align-content : stretch !important
}

.align-self-auto {
  align-self : auto !important
}

.align-self-start {
  align-self : flex-start !important
}

.align-self-end {
  align-self : flex-end !important
}

.align-self-center {
  align-self : center !important
}

.align-self-baseline {
  align-self : baseline !important
}

.align-self-stretch {
  align-self : stretch !important
}

.float-left {
  float : left !important
}

.float-right {
  float : right !important
}

.float-none {
  float : none !important
}

.position-static {
  position : static !important
}

.position-relative {
  position : relative !important
}

.position-absolute {
  position : absolute !important
}

.position-fixed {
  position : fixed !important
}

.position-sticky {
  position : sticky !important
}

.fixed-bottom, .fixed-top {
  position : fixed;
  left     : 0;
  z-index  : 1030;
  right    : 0
}

.fixed-top {
  top : 0
}

.fixed-bottom {
  bottom : 0
}

@supports (position:sticky) {
  .sticky-top {
	position : sticky;
	top      : 0;
	z-index  : 1020
  }
}

.sr-only {
  position    : absolute;
  width       : 1px;
  height      : 1px;
  padding     : 0;
  overflow    : hidden;
  clip        : rect(0, 0, 0, 0);
  white-space : nowrap;
  border      : 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position    : static;
  width       : auto;
  height      : auto;
  overflow    : visible;
  clip        : auto;
  white-space : normal
}

.shadow-sm {
  box-shadow : 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
  box-shadow : 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
  box-shadow : 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow : none !important
}

.w-25 {
  width : 25% !important
}

.w-50 {
  width : 50% !important
}

.w-75 {
  width : 75% !important
}

.w-100 {
  width : 100% !important
}

.w-auto {
  width : auto !important
}

.h-25 {
  height : 25% !important
}

.h-50 {
  height : 50% !important
}

.h-75 {
  height : 75% !important
}

.h-100 {
  height : 100% !important
}

.h-auto {
  height : auto !important
}

.mw-100 {
  max-width : 100% !important
}

.mh-100 {
  max-height : 100% !important
}

.m-0 {
  margin : 0 !important
}

.mt-0, .my-0 {
  margin-top : 0 !important
}

.mr-0, .mx-0 {
  margin-right : 0 !important
}

.mb-0, .my-0 {
  margin-bottom : 0 !important
}

.ml-0, .mx-0 {
  margin-left : 0 !important
}

.m-1 {
  margin : .25rem !important
}

.mt-1, .my-1 {
  margin-top : .25rem !important
}

.mr-1, .mx-1 {
  margin-right : .25rem !important
}

.mb-1, .my-1 {
  margin-bottom : .25rem !important
}

.ml-1, .mx-1 {
  margin-left : .25rem !important
}

.m-2 {
  margin : .5rem !important
}

.mt-2, .my-2 {
  margin-top : .5rem !important
}

.mr-2, .mx-2 {
  margin-right : .5rem !important
}

.mb-2, .my-2 {
  margin-bottom : .5rem !important
}

.ml-2, .mx-2 {
  margin-left : .5rem !important
}

.m-3 {
  margin : 1rem !important
}

.mt-3, .my-3 {
  margin-top : 1rem !important
}

.mr-3, .mx-3 {
  margin-right : 1rem !important
}

.mb-3, .my-3 {
  margin-bottom : 1rem !important
}

.ml-3, .mx-3 {
  margin-left : 1rem !important
}

.m-4 {
  margin : 1.5rem !important
}

.mt-4, .my-4 {
  margin-top : 1.5rem !important
}

.mr-4, .mx-4 {
  margin-right : 1.5rem !important
}

.mb-4, .my-4 {
  margin-bottom : 1.5rem !important
}

.ml-4, .mx-4 {
  margin-left : 1.5rem !important
}

.m-5 {
  margin : 3rem !important
}

.mt-5, .my-5 {
  margin-top : 3rem !important
}

.mr-5, .mx-5 {
  margin-right : 3rem !important
}

.mb-5, .my-5 {
  margin-bottom : 3rem !important
}

.ml-5, .mx-5 {
  margin-left : 3rem !important
}

.p-0 {
  padding : 0 !important
}

.pt-0, .py-0 {
  padding-top : 0 !important
}

.pr-0, .px-0 {
  padding-right : 0 !important
}

.pb-0, .py-0 {
  padding-bottom : 0 !important
}

.pl-0, .px-0 {
  padding-left : 0 !important
}

.p-1 {
  padding : .25rem !important
}

.pt-1, .py-1 {
  padding-top : .25rem !important
}

.pr-1, .px-1 {
  padding-right : .25rem !important
}

.pb-1, .py-1 {
  padding-bottom : .25rem !important
}

.pl-1, .px-1 {
  padding-left : .25rem !important
}

.p-2 {
  padding : .5rem !important
}

.pt-2, .py-2 {
  padding-top : .5rem !important
}

.pr-2, .px-2 {
  padding-right : .5rem !important
}

.pb-2, .py-2 {
  padding-bottom : .5rem !important
}

.pl-2, .px-2 {
  padding-left : .5rem !important
}

.p-3 {
  padding : 1rem !important
}

.pt-3, .py-3 {
  padding-top : 1rem !important
}

.pr-3, .px-3 {
  padding-right : 1rem !important
}

.pb-3, .py-3 {
  padding-bottom : 1rem !important
}

.pl-3, .px-3 {
  padding-left : 1rem !important
}

.p-4 {
  padding : 1.5rem !important
}

.pt-4, .py-4 {
  padding-top : 1.5rem !important
}

.pr-4, .px-4 {
  padding-right : 1.5rem !important
}

.pb-4, .py-4 {
  padding-bottom : 1.5rem !important
}

.pl-4, .px-4 {
  padding-left : 1.5rem !important
}

.p-5 {
  padding : 3rem !important
}

.pt-5, .py-5 {
  padding-top : 3rem !important
}

.pr-5, .px-5 {
  padding-right : 3rem !important
}

.pb-5, .py-5 {
  padding-bottom : 3rem !important
}

.pl-5, .px-5 {
  padding-left : 3rem !important
}

.m-auto {
  margin : auto !important
}

.mt-auto, .my-auto {
  margin-top : auto !important
}

.mr-auto, .mx-auto {
  margin-right : auto !important
}

.mb-auto, .my-auto {
  margin-bottom : auto !important
}

.ml-auto, .mx-auto {
  margin-left : auto !important
}

.text-monospace {
  font-family : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.text-justify {
  text-align : justify !important
}

.text-nowrap {
  white-space : nowrap !important
}

.text-truncate {
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space   : nowrap
}

.text-left {
  text-align : left !important
}

.text-right {
  text-align : right !important
}

.text-center {
  text-align : center !important
}

@media (min-width : 576px) {
  .flex-sm-row {
	flex-direction : row !important
  }

  .flex-sm-column {
	flex-direction : column !important
  }

  .flex-sm-row-reverse {
	flex-direction : row-reverse !important
  }

  .flex-sm-column-reverse {
	flex-direction : column-reverse !important
  }

  .flex-sm-wrap {
	flex-wrap : wrap !important
  }

  .flex-sm-nowrap {
	flex-wrap : nowrap !important
  }

  .flex-sm-wrap-reverse {
	flex-wrap : wrap-reverse !important
  }

  .flex-sm-fill {
	flex : 1 1 auto !important
  }

  .flex-sm-grow-0 {
	flex-grow : 0 !important
  }

  .flex-sm-grow-1 {
	flex-grow : 1 !important
  }

  .flex-sm-shrink-0 {
	flex-shrink : 0 !important
  }

  .flex-sm-shrink-1 {
	flex-shrink : 1 !important
  }

  .justify-content-sm-start {
	justify-content : flex-start !important
  }

  .justify-content-sm-end {
	justify-content : flex-end !important
  }

  .justify-content-sm-center {
	justify-content : center !important
  }

  .justify-content-sm-between {
	justify-content : space-between !important
  }

  .justify-content-sm-around {
	justify-content : space-around !important
  }

  .align-items-sm-start {
	align-items : flex-start !important
  }

  .align-items-sm-end {
	align-items : flex-end !important
  }

  .align-items-sm-center {
	align-items : center !important
  }

  .align-items-sm-baseline {
	align-items : baseline !important
  }

  .align-items-sm-stretch {
	align-items : stretch !important
  }

  .align-content-sm-start {
	align-content : flex-start !important
  }

  .align-content-sm-end {
	align-content : flex-end !important
  }

  .align-content-sm-center {
	align-content : center !important
  }

  .align-content-sm-between {
	align-content : space-between !important
  }

  .align-content-sm-around {
	align-content : space-around !important
  }

  .align-content-sm-stretch {
	align-content : stretch !important
  }

  .align-self-sm-auto {
	align-self : auto !important
  }

  .align-self-sm-start {
	align-self : flex-start !important
  }

  .align-self-sm-end {
	align-self : flex-end !important
  }

  .align-self-sm-center {
	align-self : center !important
  }

  .align-self-sm-baseline {
	align-self : baseline !important
  }

  .align-self-sm-stretch {
	align-self : stretch !important
  }

  .float-sm-left {
	float : left !important
  }

  .float-sm-right {
	float : right !important
  }

  .float-sm-none {
	float : none !important
  }

  .m-sm-0 {
	margin : 0 !important
  }

  .mt-sm-0, .my-sm-0 {
	margin-top : 0 !important
  }

  .mr-sm-0, .mx-sm-0 {
	margin-right : 0 !important
  }

  .mb-sm-0, .my-sm-0 {
	margin-bottom : 0 !important
  }

  .ml-sm-0, .mx-sm-0 {
	margin-left : 0 !important
  }

  .m-sm-1 {
	margin : .25rem !important
  }

  .mt-sm-1, .my-sm-1 {
	margin-top : .25rem !important
  }

  .mr-sm-1, .mx-sm-1 {
	margin-right : .25rem !important
  }

  .mb-sm-1, .my-sm-1 {
	margin-bottom : .25rem !important
  }

  .ml-sm-1, .mx-sm-1 {
	margin-left : .25rem !important
  }

  .m-sm-2 {
	margin : .5rem !important
  }

  .mt-sm-2, .my-sm-2 {
	margin-top : .5rem !important
  }

  .mr-sm-2, .mx-sm-2 {
	margin-right : .5rem !important
  }

  .mb-sm-2, .my-sm-2 {
	margin-bottom : .5rem !important
  }

  .ml-sm-2, .mx-sm-2 {
	margin-left : .5rem !important
  }

  .m-sm-3 {
	margin : 1rem !important
  }

  .mt-sm-3, .my-sm-3 {
	margin-top : 1rem !important
  }

  .mr-sm-3, .mx-sm-3 {
	margin-right : 1rem !important
  }

  .mb-sm-3, .my-sm-3 {
	margin-bottom : 1rem !important
  }

  .ml-sm-3, .mx-sm-3 {
	margin-left : 1rem !important
  }

  .m-sm-4 {
	margin : 1.5rem !important
  }

  .mt-sm-4, .my-sm-4 {
	margin-top : 1.5rem !important
  }

  .mr-sm-4, .mx-sm-4 {
	margin-right : 1.5rem !important
  }

  .mb-sm-4, .my-sm-4 {
	margin-bottom : 1.5rem !important
  }

  .ml-sm-4, .mx-sm-4 {
	margin-left : 1.5rem !important
  }

  .m-sm-5 {
	margin : 3rem !important
  }

  .mt-sm-5, .my-sm-5 {
	margin-top : 3rem !important
  }

  .mr-sm-5, .mx-sm-5 {
	margin-right : 3rem !important
  }

  .mb-sm-5, .my-sm-5 {
	margin-bottom : 3rem !important
  }

  .ml-sm-5, .mx-sm-5 {
	margin-left : 3rem !important
  }

  .p-sm-0 {
	padding : 0 !important
  }

  .pt-sm-0, .py-sm-0 {
	padding-top : 0 !important
  }

  .pr-sm-0, .px-sm-0 {
	padding-right : 0 !important
  }

  .pb-sm-0, .py-sm-0 {
	padding-bottom : 0 !important
  }

  .pl-sm-0, .px-sm-0 {
	padding-left : 0 !important
  }

  .p-sm-1 {
	padding : .25rem !important
  }

  .pt-sm-1, .py-sm-1 {
	padding-top : .25rem !important
  }

  .pr-sm-1, .px-sm-1 {
	padding-right : .25rem !important
  }

  .pb-sm-1, .py-sm-1 {
	padding-bottom : .25rem !important
  }

  .pl-sm-1, .px-sm-1 {
	padding-left : .25rem !important
  }

  .p-sm-2 {
	padding : .5rem !important
  }

  .pt-sm-2, .py-sm-2 {
	padding-top : .5rem !important
  }

  .pr-sm-2, .px-sm-2 {
	padding-right : .5rem !important
  }

  .pb-sm-2, .py-sm-2 {
	padding-bottom : .5rem !important
  }

  .pl-sm-2, .px-sm-2 {
	padding-left : .5rem !important
  }

  .p-sm-3 {
	padding : 1rem !important
  }

  .pt-sm-3, .py-sm-3 {
	padding-top : 1rem !important
  }

  .pr-sm-3, .px-sm-3 {
	padding-right : 1rem !important
  }

  .pb-sm-3, .py-sm-3 {
	padding-bottom : 1rem !important
  }

  .pl-sm-3, .px-sm-3 {
	padding-left : 1rem !important
  }

  .p-sm-4 {
	padding : 1.5rem !important
  }

  .pt-sm-4, .py-sm-4 {
	padding-top : 1.5rem !important
  }

  .pr-sm-4, .px-sm-4 {
	padding-right : 1.5rem !important
  }

  .pb-sm-4, .py-sm-4 {
	padding-bottom : 1.5rem !important
  }

  .pl-sm-4, .px-sm-4 {
	padding-left : 1.5rem !important
  }

  .p-sm-5 {
	padding : 3rem !important
  }

  .pt-sm-5, .py-sm-5 {
	padding-top : 3rem !important
  }

  .pr-sm-5, .px-sm-5 {
	padding-right : 3rem !important
  }

  .pb-sm-5, .py-sm-5 {
	padding-bottom : 3rem !important
  }

  .pl-sm-5, .px-sm-5 {
	padding-left : 3rem !important
  }

  .m-sm-auto {
	margin : auto !important
  }

  .mt-sm-auto, .my-sm-auto {
	margin-top : auto !important
  }

  .mr-sm-auto, .mx-sm-auto {
	margin-right : auto !important
  }

  .mb-sm-auto, .my-sm-auto {
	margin-bottom : auto !important
  }

  .ml-sm-auto, .mx-sm-auto {
	margin-left : auto !important
  }

  .text-sm-left {
	text-align : left !important
  }

  .text-sm-right {
	text-align : right !important
  }

  .text-sm-center {
	text-align : center !important
  }
}

@media (min-width : 768px) {
  .flex-md-row {
	flex-direction : row !important
  }

  .flex-md-column {
	flex-direction : column !important
  }

  .flex-md-row-reverse {
	flex-direction : row-reverse !important
  }

  .flex-md-column-reverse {
	flex-direction : column-reverse !important
  }

  .flex-md-wrap {
	flex-wrap : wrap !important
  }

  .flex-md-nowrap {
	flex-wrap : nowrap !important
  }

  .flex-md-wrap-reverse {
	flex-wrap : wrap-reverse !important
  }

  .flex-md-fill {
	flex : 1 1 auto !important
  }

  .flex-md-grow-0 {
	flex-grow : 0 !important
  }

  .flex-md-grow-1 {
	flex-grow : 1 !important
  }

  .flex-md-shrink-0 {
	flex-shrink : 0 !important
  }

  .flex-md-shrink-1 {
	flex-shrink : 1 !important
  }

  .justify-content-md-start {
	justify-content : flex-start !important
  }

  .justify-content-md-end {
	justify-content : flex-end !important
  }

  .justify-content-md-center {
	justify-content : center !important
  }

  .justify-content-md-between {
	justify-content : space-between !important
  }

  .justify-content-md-around {
	justify-content : space-around !important
  }

  .align-items-md-start {
	align-items : flex-start !important
  }

  .align-items-md-end {
	align-items : flex-end !important
  }

  .align-items-md-center {
	align-items : center !important
  }

  .align-items-md-baseline {
	align-items : baseline !important
  }

  .align-items-md-stretch {
	align-items : stretch !important
  }

  .align-content-md-start {
	align-content : flex-start !important
  }

  .align-content-md-end {
	align-content : flex-end !important
  }

  .align-content-md-center {
	align-content : center !important
  }

  .align-content-md-between {
	align-content : space-between !important
  }

  .align-content-md-around {
	align-content : space-around !important
  }

  .align-content-md-stretch {
	align-content : stretch !important
  }

  .align-self-md-auto {
	align-self : auto !important
  }

  .align-self-md-start {
	align-self : flex-start !important
  }

  .align-self-md-end {
	align-self : flex-end !important
  }

  .align-self-md-center {
	align-self : center !important
  }

  .align-self-md-baseline {
	align-self : baseline !important
  }

  .align-self-md-stretch {
	align-self : stretch !important
  }

  .float-md-left {
	float : left !important
  }

  .float-md-right {
	float : right !important
  }

  .float-md-none {
	float : none !important
  }

  .m-md-0 {
	margin : 0 !important
  }

  .mt-md-0, .my-md-0 {
	margin-top : 0 !important
  }

  .mr-md-0, .mx-md-0 {
	margin-right : 0 !important
  }

  .mb-md-0, .my-md-0 {
	margin-bottom : 0 !important
  }

  .ml-md-0, .mx-md-0 {
	margin-left : 0 !important
  }

  .m-md-1 {
	margin : .25rem !important
  }

  .mt-md-1, .my-md-1 {
	margin-top : .25rem !important
  }

  .mr-md-1, .mx-md-1 {
	margin-right : .25rem !important
  }

  .mb-md-1, .my-md-1 {
	margin-bottom : .25rem !important
  }

  .ml-md-1, .mx-md-1 {
	margin-left : .25rem !important
  }

  .m-md-2 {
	margin : .5rem !important
  }

  .mt-md-2, .my-md-2 {
	margin-top : .5rem !important
  }

  .mr-md-2, .mx-md-2 {
	margin-right : .5rem !important
  }

  .mb-md-2, .my-md-2 {
	margin-bottom : .5rem !important
  }

  .ml-md-2, .mx-md-2 {
	margin-left : .5rem !important
  }

  .m-md-3 {
	margin : 1rem !important
  }

  .mt-md-3, .my-md-3 {
	margin-top : 1rem !important
  }

  .mr-md-3, .mx-md-3 {
	margin-right : 1rem !important
  }

  .mb-md-3, .my-md-3 {
	margin-bottom : 1rem !important
  }

  .ml-md-3, .mx-md-3 {
	margin-left : 1rem !important
  }

  .m-md-4 {
	margin : 1.5rem !important
  }

  .mt-md-4, .my-md-4 {
	margin-top : 1.5rem !important
  }

  .mr-md-4, .mx-md-4 {
	margin-right : 1.5rem !important
  }

  .mb-md-4, .my-md-4 {
	margin-bottom : 1.5rem !important
  }

  .ml-md-4, .mx-md-4 {
	margin-left : 1.5rem !important
  }

  .m-md-5 {
	margin : 3rem !important
  }

  .mt-md-5, .my-md-5 {
	margin-top : 3rem !important
  }

  .mr-md-5, .mx-md-5 {
	margin-right : 3rem !important
  }

  .mb-md-5, .my-md-5 {
	margin-bottom : 3rem !important
  }

  .ml-md-5, .mx-md-5 {
	margin-left : 3rem !important
  }

  .p-md-0 {
	padding : 0 !important
  }

  .pt-md-0, .py-md-0 {
	padding-top : 0 !important
  }

  .pr-md-0, .px-md-0 {
	padding-right : 0 !important
  }

  .pb-md-0, .py-md-0 {
	padding-bottom : 0 !important
  }

  .pl-md-0, .px-md-0 {
	padding-left : 0 !important
  }

  .p-md-1 {
	padding : .25rem !important
  }

  .pt-md-1, .py-md-1 {
	padding-top : .25rem !important
  }

  .pr-md-1, .px-md-1 {
	padding-right : .25rem !important
  }

  .pb-md-1, .py-md-1 {
	padding-bottom : .25rem !important
  }

  .pl-md-1, .px-md-1 {
	padding-left : .25rem !important
  }

  .p-md-2 {
	padding : .5rem !important
  }

  .pt-md-2, .py-md-2 {
	padding-top : .5rem !important
  }

  .pr-md-2, .px-md-2 {
	padding-right : .5rem !important
  }

  .pb-md-2, .py-md-2 {
	padding-bottom : .5rem !important
  }

  .pl-md-2, .px-md-2 {
	padding-left : .5rem !important
  }

  .p-md-3 {
	padding : 1rem !important
  }

  .pt-md-3, .py-md-3 {
	padding-top : 1rem !important
  }

  .pr-md-3, .px-md-3 {
	padding-right : 1rem !important
  }

  .pb-md-3, .py-md-3 {
	padding-bottom : 1rem !important
  }

  .pl-md-3, .px-md-3 {
	padding-left : 1rem !important
  }

  .p-md-4 {
	padding : 1.5rem !important
  }

  .pt-md-4, .py-md-4 {
	padding-top : 1.5rem !important
  }

  .pr-md-4, .px-md-4 {
	padding-right : 1.5rem !important
  }

  .pb-md-4, .py-md-4 {
	padding-bottom : 1.5rem !important
  }

  .pl-md-4, .px-md-4 {
	padding-left : 1.5rem !important
  }

  .p-md-5 {
	padding : 3rem !important
  }

  .pt-md-5, .py-md-5 {
	padding-top : 3rem !important
  }

  .pr-md-5, .px-md-5 {
	padding-right : 3rem !important
  }

  .pb-md-5, .py-md-5 {
	padding-bottom : 3rem !important
  }

  .pl-md-5, .px-md-5 {
	padding-left : 3rem !important
  }

  .m-md-auto {
	margin : auto !important
  }

  .mt-md-auto, .my-md-auto {
	margin-top : auto !important
  }

  .mr-md-auto, .mx-md-auto {
	margin-right : auto !important
  }

  .mb-md-auto, .my-md-auto {
	margin-bottom : auto !important
  }

  .ml-md-auto, .mx-md-auto {
	margin-left : auto !important
  }

  .text-md-left {
	text-align : left !important
  }

  .text-md-right {
	text-align : right !important
  }

  .text-md-center {
	text-align : center !important
  }
}

@media (min-width : 992px) {
  .flex-lg-row {
	flex-direction : row !important
  }

  .flex-lg-column {
	flex-direction : column !important
  }

  .flex-lg-row-reverse {
	flex-direction : row-reverse !important
  }

  .flex-lg-column-reverse {
	flex-direction : column-reverse !important
  }

  .flex-lg-wrap {
	flex-wrap : wrap !important
  }

  .flex-lg-nowrap {
	flex-wrap : nowrap !important
  }

  .flex-lg-wrap-reverse {
	flex-wrap : wrap-reverse !important
  }

  .flex-lg-fill {
	flex : 1 1 auto !important
  }

  .flex-lg-grow-0 {
	flex-grow : 0 !important
  }

  .flex-lg-grow-1 {
	flex-grow : 1 !important
  }

  .flex-lg-shrink-0 {
	flex-shrink : 0 !important
  }

  .flex-lg-shrink-1 {
	flex-shrink : 1 !important
  }

  .justify-content-lg-start {
	justify-content : flex-start !important
  }

  .justify-content-lg-end {
	justify-content : flex-end !important
  }

  .justify-content-lg-center {
	justify-content : center !important
  }

  .justify-content-lg-between {
	justify-content : space-between !important
  }

  .justify-content-lg-around {
	justify-content : space-around !important
  }

  .align-items-lg-start {
	align-items : flex-start !important
  }

  .align-items-lg-end {
	align-items : flex-end !important
  }

  .align-items-lg-center {
	align-items : center !important
  }

  .align-items-lg-baseline {
	align-items : baseline !important
  }

  .align-items-lg-stretch {
	align-items : stretch !important
  }

  .align-content-lg-start {
	align-content : flex-start !important
  }

  .align-content-lg-end {
	align-content : flex-end !important
  }

  .align-content-lg-center {
	align-content : center !important
  }

  .align-content-lg-between {
	align-content : space-between !important
  }

  .align-content-lg-around {
	align-content : space-around !important
  }

  .align-content-lg-stretch {
	align-content : stretch !important
  }

  .align-self-lg-auto {
	align-self : auto !important
  }

  .align-self-lg-start {
	align-self : flex-start !important
  }

  .align-self-lg-end {
	align-self : flex-end !important
  }

  .align-self-lg-center {
	align-self : center !important
  }

  .align-self-lg-baseline {
	align-self : baseline !important
  }

  .align-self-lg-stretch {
	align-self : stretch !important
  }

  .float-lg-left {
	float : left !important
  }

  .float-lg-right {
	float : right !important
  }

  .float-lg-none {
	float : none !important
  }

  .m-lg-0 {
	margin : 0 !important
  }

  .mt-lg-0, .my-lg-0 {
	margin-top : 0 !important
  }

  .mr-lg-0, .mx-lg-0 {
	margin-right : 0 !important
  }

  .mb-lg-0, .my-lg-0 {
	margin-bottom : 0 !important
  }

  .ml-lg-0, .mx-lg-0 {
	margin-left : 0 !important
  }

  .m-lg-1 {
	margin : .25rem !important
  }

  .mt-lg-1, .my-lg-1 {
	margin-top : .25rem !important
  }

  .mr-lg-1, .mx-lg-1 {
	margin-right : .25rem !important
  }

  .mb-lg-1, .my-lg-1 {
	margin-bottom : .25rem !important
  }

  .ml-lg-1, .mx-lg-1 {
	margin-left : .25rem !important
  }

  .m-lg-2 {
	margin : .5rem !important
  }

  .mt-lg-2, .my-lg-2 {
	margin-top : .5rem !important
  }

  .mr-lg-2, .mx-lg-2 {
	margin-right : .5rem !important
  }

  .mb-lg-2, .my-lg-2 {
	margin-bottom : .5rem !important
  }

  .ml-lg-2, .mx-lg-2 {
	margin-left : .5rem !important
  }

  .m-lg-3 {
	margin : 1rem !important
  }

  .mt-lg-3, .my-lg-3 {
	margin-top : 1rem !important
  }

  .mr-lg-3, .mx-lg-3 {
	margin-right : 1rem !important
  }

  .mb-lg-3, .my-lg-3 {
	margin-bottom : 1rem !important
  }

  .ml-lg-3, .mx-lg-3 {
	margin-left : 1rem !important
  }

  .m-lg-4 {
	margin : 1.5rem !important
  }

  .mt-lg-4, .my-lg-4 {
	margin-top : 1.5rem !important
  }

  .mr-lg-4, .mx-lg-4 {
	margin-right : 1.5rem !important
  }

  .mb-lg-4, .my-lg-4 {
	margin-bottom : 1.5rem !important
  }

  .ml-lg-4, .mx-lg-4 {
	margin-left : 1.5rem !important
  }

  .m-lg-5 {
	margin : 3rem !important
  }

  .mt-lg-5, .my-lg-5 {
	margin-top : 3rem !important
  }

  .mr-lg-5, .mx-lg-5 {
	margin-right : 3rem !important
  }

  .mb-lg-5, .my-lg-5 {
	margin-bottom : 3rem !important
  }

  .ml-lg-5, .mx-lg-5 {
	margin-left : 3rem !important
  }

  .p-lg-0 {
	padding : 0 !important
  }

  .pt-lg-0, .py-lg-0 {
	padding-top : 0 !important
  }

  .pr-lg-0, .px-lg-0 {
	padding-right : 0 !important
  }

  .pb-lg-0, .py-lg-0 {
	padding-bottom : 0 !important
  }

  .pl-lg-0, .px-lg-0 {
	padding-left : 0 !important
  }

  .p-lg-1 {
	padding : .25rem !important
  }

  .pt-lg-1, .py-lg-1 {
	padding-top : .25rem !important
  }

  .pr-lg-1, .px-lg-1 {
	padding-right : .25rem !important
  }

  .pb-lg-1, .py-lg-1 {
	padding-bottom : .25rem !important
  }

  .pl-lg-1, .px-lg-1 {
	padding-left : .25rem !important
  }

  .p-lg-2 {
	padding : .5rem !important
  }

  .pt-lg-2, .py-lg-2 {
	padding-top : .5rem !important
  }

  .pr-lg-2, .px-lg-2 {
	padding-right : .5rem !important
  }

  .pb-lg-2, .py-lg-2 {
	padding-bottom : .5rem !important
  }

  .pl-lg-2, .px-lg-2 {
	padding-left : .5rem !important
  }

  .p-lg-3 {
	padding : 1rem !important
  }

  .pt-lg-3, .py-lg-3 {
	padding-top : 1rem !important
  }

  .pr-lg-3, .px-lg-3 {
	padding-right : 1rem !important
  }

  .pb-lg-3, .py-lg-3 {
	padding-bottom : 1rem !important
  }

  .pl-lg-3, .px-lg-3 {
	padding-left : 1rem !important
  }

  .p-lg-4 {
	padding : 1.5rem !important
  }

  .pt-lg-4, .py-lg-4 {
	padding-top : 1.5rem !important
  }

  .pr-lg-4, .px-lg-4 {
	padding-right : 1.5rem !important
  }

  .pb-lg-4, .py-lg-4 {
	padding-bottom : 1.5rem !important
  }

  .pl-lg-4, .px-lg-4 {
	padding-left : 1.5rem !important
  }

  .p-lg-5 {
	padding : 3rem !important
  }

  .pt-lg-5, .py-lg-5 {
	padding-top : 3rem !important
  }

  .pr-lg-5, .px-lg-5 {
	padding-right : 3rem !important
  }

  .pb-lg-5, .py-lg-5 {
	padding-bottom : 3rem !important
  }

  .pl-lg-5, .px-lg-5 {
	padding-left : 3rem !important
  }

  .m-lg-auto {
	margin : auto !important
  }

  .mt-lg-auto, .my-lg-auto {
	margin-top : auto !important
  }

  .mr-lg-auto, .mx-lg-auto {
	margin-right : auto !important
  }

  .mb-lg-auto, .my-lg-auto {
	margin-bottom : auto !important
  }

  .ml-lg-auto, .mx-lg-auto {
	margin-left : auto !important
  }

  .text-lg-left {
	text-align : left !important
  }

  .text-lg-right {
	text-align : right !important
  }

  .text-lg-center {
	text-align : center !important
  }
}

@media (min-width : 1200px) {
  .flex-xl-row {
	flex-direction : row !important
  }

  .flex-xl-column {
	flex-direction : column !important
  }

  .flex-xl-row-reverse {
	flex-direction : row-reverse !important
  }

  .flex-xl-column-reverse {
	flex-direction : column-reverse !important
  }

  .flex-xl-wrap {
	flex-wrap : wrap !important
  }

  .flex-xl-nowrap {
	flex-wrap : nowrap !important
  }

  .flex-xl-wrap-reverse {
	flex-wrap : wrap-reverse !important
  }

  .flex-xl-fill {
	flex : 1 1 auto !important
  }

  .flex-xl-grow-0 {
	flex-grow : 0 !important
  }

  .flex-xl-grow-1 {
	flex-grow : 1 !important
  }

  .flex-xl-shrink-0 {
	flex-shrink : 0 !important
  }

  .flex-xl-shrink-1 {
	flex-shrink : 1 !important
  }

  .justify-content-xl-start {
	justify-content : flex-start !important
  }

  .justify-content-xl-end {
	justify-content : flex-end !important
  }

  .justify-content-xl-center {
	justify-content : center !important
  }

  .justify-content-xl-between {
	justify-content : space-between !important
  }

  .justify-content-xl-around {
	justify-content : space-around !important
  }

  .align-items-xl-start {
	align-items : flex-start !important
  }

  .align-items-xl-end {
	align-items : flex-end !important
  }

  .align-items-xl-center {
	align-items : center !important
  }

  .align-items-xl-baseline {
	align-items : baseline !important
  }

  .align-items-xl-stretch {
	align-items : stretch !important
  }

  .align-content-xl-start {
	align-content : flex-start !important
  }

  .align-content-xl-end {
	align-content : flex-end !important
  }

  .align-content-xl-center {
	align-content : center !important
  }

  .align-content-xl-between {
	align-content : space-between !important
  }

  .align-content-xl-around {
	align-content : space-around !important
  }

  .align-content-xl-stretch {
	align-content : stretch !important
  }

  .align-self-xl-auto {
	align-self : auto !important
  }

  .align-self-xl-start {
	align-self : flex-start !important
  }

  .align-self-xl-end {
	align-self : flex-end !important
  }

  .align-self-xl-center {
	align-self : center !important
  }

  .align-self-xl-baseline {
	align-self : baseline !important
  }

  .align-self-xl-stretch {
	align-self : stretch !important
  }

  .float-xl-left {
	float : left !important
  }

  .float-xl-right {
	float : right !important
  }

  .float-xl-none {
	float : none !important
  }

  .m-xl-0 {
	margin : 0 !important
  }

  .mt-xl-0, .my-xl-0 {
	margin-top : 0 !important
  }

  .mr-xl-0, .mx-xl-0 {
	margin-right : 0 !important
  }

  .mb-xl-0, .my-xl-0 {
	margin-bottom : 0 !important
  }

  .ml-xl-0, .mx-xl-0 {
	margin-left : 0 !important
  }

  .m-xl-1 {
	margin : .25rem !important
  }

  .mt-xl-1, .my-xl-1 {
	margin-top : .25rem !important
  }

  .mr-xl-1, .mx-xl-1 {
	margin-right : .25rem !important
  }

  .mb-xl-1, .my-xl-1 {
	margin-bottom : .25rem !important
  }

  .ml-xl-1, .mx-xl-1 {
	margin-left : .25rem !important
  }

  .m-xl-2 {
	margin : .5rem !important
  }

  .mt-xl-2, .my-xl-2 {
	margin-top : .5rem !important
  }

  .mr-xl-2, .mx-xl-2 {
	margin-right : .5rem !important
  }

  .mb-xl-2, .my-xl-2 {
	margin-bottom : .5rem !important
  }

  .ml-xl-2, .mx-xl-2 {
	margin-left : .5rem !important
  }

  .m-xl-3 {
	margin : 1rem !important
  }

  .mt-xl-3, .my-xl-3 {
	margin-top : 1rem !important
  }

  .mr-xl-3, .mx-xl-3 {
	margin-right : 1rem !important
  }

  .mb-xl-3, .my-xl-3 {
	margin-bottom : 1rem !important
  }

  .ml-xl-3, .mx-xl-3 {
	margin-left : 1rem !important
  }

  .m-xl-4 {
	margin : 1.5rem !important
  }

  .mt-xl-4, .my-xl-4 {
	margin-top : 1.5rem !important
  }

  .mr-xl-4, .mx-xl-4 {
	margin-right : 1.5rem !important
  }

  .mb-xl-4, .my-xl-4 {
	margin-bottom : 1.5rem !important
  }

  .ml-xl-4, .mx-xl-4 {
	margin-left : 1.5rem !important
  }

  .m-xl-5 {
	margin : 3rem !important
  }

  .mt-xl-5, .my-xl-5 {
	margin-top : 3rem !important
  }

  .mr-xl-5, .mx-xl-5 {
	margin-right : 3rem !important
  }

  .mb-xl-5, .my-xl-5 {
	margin-bottom : 3rem !important
  }

  .ml-xl-5, .mx-xl-5 {
	margin-left : 3rem !important
  }

  .p-xl-0 {
	padding : 0 !important
  }

  .pt-xl-0, .py-xl-0 {
	padding-top : 0 !important
  }

  .pr-xl-0, .px-xl-0 {
	padding-right : 0 !important
  }

  .pb-xl-0, .py-xl-0 {
	padding-bottom : 0 !important
  }

  .pl-xl-0, .px-xl-0 {
	padding-left : 0 !important
  }

  .p-xl-1 {
	padding : .25rem !important
  }

  .pt-xl-1, .py-xl-1 {
	padding-top : .25rem !important
  }

  .pr-xl-1, .px-xl-1 {
	padding-right : .25rem !important
  }

  .pb-xl-1, .py-xl-1 {
	padding-bottom : .25rem !important
  }

  .pl-xl-1, .px-xl-1 {
	padding-left : .25rem !important
  }

  .p-xl-2 {
	padding : .5rem !important
  }

  .pt-xl-2, .py-xl-2 {
	padding-top : .5rem !important
  }

  .pr-xl-2, .px-xl-2 {
	padding-right : .5rem !important
  }

  .pb-xl-2, .py-xl-2 {
	padding-bottom : .5rem !important
  }

  .pl-xl-2, .px-xl-2 {
	padding-left : .5rem !important
  }

  .p-xl-3 {
	padding : 1rem !important
  }

  .pt-xl-3, .py-xl-3 {
	padding-top : 1rem !important
  }

  .pr-xl-3, .px-xl-3 {
	padding-right : 1rem !important
  }

  .pb-xl-3, .py-xl-3 {
	padding-bottom : 1rem !important
  }

  .pl-xl-3, .px-xl-3 {
	padding-left : 1rem !important
  }

  .p-xl-4 {
	padding : 1.5rem !important
  }

  .pt-xl-4, .py-xl-4 {
	padding-top : 1.5rem !important
  }

  .pr-xl-4, .px-xl-4 {
	padding-right : 1.5rem !important
  }

  .pb-xl-4, .py-xl-4 {
	padding-bottom : 1.5rem !important
  }

  .pl-xl-4, .px-xl-4 {
	padding-left : 1.5rem !important
  }

  .p-xl-5 {
	padding : 3rem !important
  }

  .pt-xl-5, .py-xl-5 {
	padding-top : 3rem !important
  }

  .pr-xl-5, .px-xl-5 {
	padding-right : 3rem !important
  }

  .pb-xl-5, .py-xl-5 {
	padding-bottom : 3rem !important
  }

  .pl-xl-5, .px-xl-5 {
	padding-left : 3rem !important
  }

  .m-xl-auto {
	margin : auto !important
  }

  .mt-xl-auto, .my-xl-auto {
	margin-top : auto !important
  }

  .mr-xl-auto, .mx-xl-auto {
	margin-right : auto !important
  }

  .mb-xl-auto, .my-xl-auto {
	margin-bottom : auto !important
  }

  .ml-xl-auto, .mx-xl-auto {
	margin-left : auto !important
  }

  .text-xl-left {
	text-align : left !important
  }

  .text-xl-right {
	text-align : right !important
  }

  .text-xl-center {
	text-align : center !important
  }
}

.text-lowercase {
  text-transform : lowercase !important
}

.text-uppercase {
  text-transform : uppercase !important
}

.text-capitalize {
  text-transform : capitalize !important
}

.button, .dtp .dtp-title {
  text-transform : uppercase
}

.font-weight-light {
  font-weight : 300 !important
}

.font-weight-normal {
  font-weight : 400 !important
}

.font-weight-bold {
  font-weight : 700 !important
}

.font-italic {
  font-style : italic !important
}

.text-primary {
  color : #007BFF !important
}

a.text-primary:focus, a.text-primary:hover {
  color : #0062CC !important
}

.text-secondary {
  color : #6C757D !important
}

a.text-secondary:focus, a.text-secondary:hover {
  color : #545B62 !important
}

.text-success {
  color : #28A745 !important
}

a.text-success:focus, a.text-success:hover {
  color : #1E7E34 !important
}

.text-info {
  color : #17A2B8 !important
}

a.text-info:focus, a.text-info:hover {
  color : #117A8B !important
}

.text-warning {
  color : #FFC107 !important
}

a.text-warning:focus, a.text-warning:hover {
  color : #D39E00 !important
}

.text-danger {
  color : #DC3545 !important
}

a.text-danger:focus, a.text-danger:hover {
  color : #BD2130 !important
}

.text-light {
  color : #F8F9FA !important
}

a.text-light:focus, a.text-light:hover {
  color : #DAE0E5 !important
}

.text-dark {
  color : #343A40 !important
}

a.text-dark:focus, a.text-dark:hover {
  color : #1D2124 !important
}

.text-black, a.text-black:focus, a.text-black:hover {
  color : #000 !important
}

.text-white {
  color : #FFF !important
}

a.text-white:focus, a.text-white:hover {
  color : #E6E6E6 !important
}

.text-gray-12 {
  color : #898989 !important
}

a.text-gray-12:focus, a.text-gray-12:hover {
  color : #707070 !important
}

.text-brown {
  color : #13395e !important
}

a.text-brown:focus, a.text-brown:hover {
  color : #B6834C !important
}

.text-bgbrown {
  color : #13395e !important
}

a.text-bgbrown:focus, a.text-bgbrown:hover {
  color : #B6834C !important
}

.text-bgwhite {
  color : #FFF !important
}

a.text-bgwhite:focus, a.text-bgwhite:hover {
  color : #E6E6E6 !important
}

.text-bggray {
  color : #6D6D6D !important
}

a.text-bggray:focus, a.text-bggray:hover {
  color : #545454 !important
}

.text-body {
  color : #212529 !important
}

.text-muted {
  color : #6C757D !important
}

.text-black-50 {
  color : rgba(0, 0, 0, .5) !important
}

.text-white-50 {
  color : rgba(255, 255, 255, .5) !important
}

.text-hide {
  font             : 0/0 a;
  color            : transparent;
  text-shadow      : none;
  background-color : transparent;
  border           : 0
}

.visible {
  visibility : visible !important
}

.invisible {
  visibility : hidden !important
}

.dtp .invisible, .owl-carousel.owl-refresh .owl-item {
  visibility : hidden
}

.dtp {
  position   : fixed;
  top        : 0;
  left       : 0;
  right      : 0;
  bottom     : 0;
  background : rgba(0, 0, 0, .4);
  z-index    : 2000;
  opacity    : 1;
  transition : opacity .6s
}

.dtp > .dtp-content {
  background : #FFF;
  max-width  : 350px;
  box-shadow : 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  position   : relative;
  left       : 50%
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background : #13395e;
  color      : #FFF;
  text-align : center;
  position   : static !important;
}

.dtp .dtp-title {
  font-size : 24px;
  padding   : .5rem
}

.dtp .dtp-actual-day {
  padding : .3rem
}

.dtp div.dtp-date, .dtp div.dtp-time {
  background : #424242;
  text-align : center;
  color      : #CCAA8B;
  padding    : 5px 10px 0
}

.dtp div.dtp-date > div {
  padding : 0;
  margin  : 0
}

.dtp div.dtp-actual-month {
  font-size : 1.35em;
  width     : 80%;
  position  : relative;
  display   : inline-block
}

.dtp div.dtp-actual-maxtime, .dtp div.dtp-actual-num {
  font-size   : 3em;
  line-height : .9
}

.dtp div.dtp-actual-year {
  font-size : 1.5em;
  color     : #FFF;
  width     : 80%;
  position  : relative;
  margin    : 0 auto;
}

.dtp div.dtp-picker {
  padding    : 10px 10px 0;
  text-align : center
}

.dtp div.dtp-actual-time, .dtp div.dtp-picker-month {
  font-weight    : 500;
  text-align     : center;
  text-transform : uppercase
}

.dtp .dtp-close {
  position : absolute;
  top      : 3px;
  right    : 5px
}

.dtp .dtp-close > a {
  color : #FFF
}

.dtp .dtp-close > a > i {
  font-size : 1em
}

.dtp table {
  width     : 100%;
  max-width : 100%
}

.dtp table.dtp-picker-days {
  margin     : 0;
  min-height : 251px;
  width      : 100%
}

.dtp table.dtp-picker-days, .dtp table.dtp-picker-days tr, .dtp table.dtp-picker-days tr > td {
  border : none
}

.dtp table.dtp-picker-days tr > td {
  font-weight : 700;
  text-align  : center;
  padding     : 0
}

.dtp table.dtp-picker-days tr > td > span.dtp-select-day {
  color : #BDBDBD !important
}

.dtp .dtp-picker-time > a, .dtp table.dtp-picker-days tr > td > a {
  color           : #212121;
  text-decoration : none;
  padding         : .4rem .5rem .5rem .6rem;
  border-radius   : 50% !important
}

.dtp table.dtp-picker-days tr > td > a.selected {
  background : #53534A;
  color      : #FFF
}

.dtp table.dtp-picker-days tr > th {
  color       : #757575;
  text-align  : center;
  font-weight : 700;
  padding     : .4rem .3rem
}

.dtp .p10 > a {
  color           : #FFF;
  text-decoration : none
}

.dtp .p10 {
  width : 10%
}

.dtp .p20 {
  width : 20%
}

.dtp .p60 {
  width : 60%
}

.dtp .p80 {
  width : 80%
}

.dtp a.dtp-meridien-am, .dtp a.dtp-meridien-pm {
  position        : relative;
  top             : 10px;
  color           : #212121;
  font-weight     : 500;
  padding         : .7rem .5rem;
  border-radius   : 50% !important;
  text-decoration : none;
  background      : #EEE;
  font-size       : 1rem
}

.dtp .dtp-actual-meridien a.selected {
  background : #7B7B7B;
  color      : #FFF
}

.dtp .dtp-picker-time > a {
  display     : block;
  line-height : 23px;
  padding     : .3rem
}

.dtp .dtp-picker-time {
  position      : absolute;
  width         : 30px;
  height        : 30px;
  font-size     : 1em;
  border-radius : 50%;
  cursor        : pointer;
  font-weight   : 500;
  text-align    : center !important
}

.dtp .dtp-picker-time > a.dtp-select-hour.selected {
  background : #53534A;
  color      : #FFF
}

.dtp .dtp-picker-time > a.dtp-select-hour.disabled, .dtp .dtp-picker-time > a.dtp-select-minute.disabled {
  color : #757575
}

.dtp .dtp-picker-time > a.dtp-select-minute.selected {
  background : #53534A;
  color      : #FFF
}

.dtp div.dtp-picker-clock {
  margin        : 1rem 2rem 0;
  padding       : 1rem;
  border-radius : 50% !important;
  background    : #EEE
}

.dtp-btn-cancel, .dtp-btn-ok {
  margin-right : 10px;
  color        : #FFF;
  border       : 0;
}

.dtp-clock-center {
  width         : 15px;
  height        : 15px;
  background    : #757575;
  border-radius : 50%;
  position      : absolute;
  z-index       : 50
}

.dtp .dtp-hand, .dtp .dtp-hour-hand {
  position         : absolute;
  width            : 4px;
  margin-left      : -2px;
  background       : #BDBDBD;
  transform        : rotate(0);
  transform-origin : bottom;
  z-index          : 1
}

.dtp .dtp-minute-hand {
  width       : 2px;
  margin-left : -1px
}

.dtp .dtp-hand.on {
  background : #8BC34A
}

.dtp .dtp-buttons {
  padding    : 0 1rem 1rem;
  text-align : right
}

.button, .dtp .center {
  text-align : center
}

.dtp .hidden, .dtp.hidden {
  display : none
}

.dtp .left {
  float : left
}

.dtp .right {
  float : right
}

.dtp .clearfix {
  clear : both
}

.dtp-btn-cancel {
  background : #9D9D9D
}

.dtp-btn-ok {
  background : #13395e
}

.dtp .fa-arrow-left, .dtp .fa-arrow-right {
  height            : 20px;
  width             : 20px;
  display           : inline-block;
  background-color  : transparent;
  background-repeat : no-repeat
}

.dtp .fa-arrow-left {
  @include svg-fa-arrow-left;
}

.dtp .fa-arrow-right {
  @include svg-fa-arrow-right;
}

.dtp .dtp-select-year-after {
  display : none !important
}

/*!
*/
.animated {
  -webkit-animation-duration  : calc(1s);
  animation-duration          : calc(1s);
  -webkit-animation-fill-mode : both;
  animation-fill-mode         : both
}

.animated.infinite {
  animation-iteration-count : infinite
}

.animated.hinge {
  -webkit-animation-duration : calc(1s * 2);
  animation-duration         : calc(1s * 2)
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  -webkit-animation-duration : calc(1s * .75);
  animation-duration         : calc(1s * .75)
}

@-webkit-keyframes fadeIn {
  0% {
	opacity : 0
  }
  100% {
	opacity : 1
  }
}

@keyframes fadeIn {
  0% {
	opacity : 0
  }
  100% {
	opacity : 1
  }
}

.button-mobile.open span:nth-child(2), .button-mobile.open:after {
  opacity : 0
}

.fadeIn {
  -webkit-animation-name : fadeIn;
  animation-name         : fadeIn
}

.button {
  position    : relative;
  display     : inline-block;
  border      : 2px solid #13395e;
  padding     : 7px 15px;
  font-size   : 14px;
  line-height : 17px;
  cursor      : pointer !important;
  transition  : all .3s ease-in
}

.dropdown-item, .button-mobile span {
  transition : all .2s ease-in-out
}

.button-brown {
  background-color : #13395e;
  color            : #FFF
}

.button-brown.black {
  color : #000
}

.button-brown:hover {
  background-color : #13395e;
  color            : #FFF !important;
  border-color     : #13395e
}

.button-pink {
  background-color : #f2d7c1;
  color            : #2e2e2d;

}

.button-pink:hover {
  background-color : #f2d7c1;
  color            : #2e2e2d !important;
  border-color     : #f2d7c1
}

.button-white {
  background-color : #FFF;
  color            : #13395e
}

.button-white.black {
  color : #000
}

.button-white:hover {
  background-color : #13395e;
  color            : #FFF !important;
  border-color     : #13395e
}

.button-mobile {
  position : relative;
  padding  : 8px 5px;
  height   : 50px;
  width    : 50px;
  bottom   : -4px;
}

.button-mobile span {
  transition       : all .2s ease-in-out;
  display          : block;
  width            : 100%;
  height           : 2px;
  background-color : #FFF;
  margin-bottom    : 8px;
}

.h-button span, .h-button:before {
  transition     : .25s cubic-bezier(.5, -1, .5, 2);
  text-transform : uppercase
}

.button-mobile span:last-child {
  margin-bottom : 0
}

.button-mobile:after {
  content    : "menu";
  position   : absolute;
  bottom     : -3px;
  left       : 0;
  right      : 0;
  padding    : 0;
  margin     : 0;
  display    : block;
  font-size  : 10px;
  text-align : center
}

.button-mobile.open span:first-child {
  position  : absolute;
  transform : rotate(45deg);
  top       : 22px;
  left      : 0
}

.button-mobile.open span:last-child {
  position  : absolute;
  transform : rotate(-45deg);
  top       : 22px;
  right     : 0
}

.button--booking {
  max-height     : 50px;
  overflow       : hidden;
  padding        : 15px 0;
  width          : 200px;
  display        : inline-block;
  vertical-align : middle;
  font-size      : 26px;
  color          : #FFF
}

.button-full {
  padding : 10px 15px;
  width   : 100%
}

.button-large {
  padding     : 10px 15px;
  font-size   : 20px;
  line-height : 22px
}

.button-container {
  position : relative;
  padding  : 15px 0
}

.button-container--left {
  text-align : left
}

.button-container--center {
  text-align : center
}

.button-container--right {
  text-align : right
}

.button-container:after, .button-container:before {
  position         : absolute;
  height           : 2px;
  width            : 100%;
  background-color : #CACACA;
  z-index          : 5;
  content          : "";
  left             : 0
}

.button-container:before {
  top : 10px
}

.button-container:after {
  bottom : 10px
}

.button-select {
  -webkit-appearance : button;
  width              : 200px;
  border-radius      : 1px;
  text-align         : center
}

.button:focus {
  outline : 0 !important
}

.button:disabled {
  cursor  : not-allowed !important;
  opacity : .6
}

.h-button span {
  display   : inline-block;
  min-width : .3em;
  opacity   : 0;
  transform : translate(0, -20px)
}

.h-button:before {
  content        : attr(data-text);
  position       : absolute;
  width          : 100%;
  left           : 0;
  letter-spacing : 3.5px;
  opacity        : 1;
  transform      : translate(0, 0)
}

.h-button:hover:before {
  opacity   : 0;
  transform : translate(0, 20px)
}

.h-button:hover span {
  opacity   : 1;
  transform : translate(0, 0)
}

.h-button span:nth-child(1) {
  transition-delay : 25ms
}

.h-button span:nth-child(2) {
  transition-delay : 50ms
}

.h-button span:nth-child(3) {
  transition-delay : 75ms
}

.h-button span:nth-child(4) {
  transition-delay : .1s
}

.h-button span:nth-child(5) {
  transition-delay : 125ms
}

.h-button span:nth-child(6) {
  transition-delay : .15s
}

.h-button span:nth-child(7) {
  transition-delay : 175ms
}

.h-button span:nth-child(8) {
  transition-delay : .2s
}

.h-button span:nth-child(9) {
  transition-delay : 225ms
}

.h-button span:nth-child(10) {
  transition-delay : .25s
}

.h-button span:nth-child(11) {
  transition-delay : 275ms
}

.h-button span:nth-child(12) {
  transition-delay : .3s
}

.h-button span:nth-child(13) {
  transition-delay : 325ms
}

.h-button span:nth-child(14) {
  transition-delay : .35s
}

.h-button span:nth-child(15) {
  transition-delay : 375ms
}

.pos-rel {
  position : relative;

  img {
	display : block;
	width   : 100%;
	height  : auto;
  }
}

.bg-shadow {
  background-color : rgba(0, 0, 0, .7)
}

.bg-brown-1 {
  background-color : #13395e
}

.bg-brown-2 {
  background-color : #EBCBA7
}

.bg-brown-3 {
  background-color : #13395e
}

.img-responsive {
  width     : 100%;
  height    : auto;
  max-width : 100% !important
}

hr.double {
  position      : relative;
  display       : block;
  width         : 100%;
  height        : 5px;
  border-top    : solid 1px #6D6D6D;
  border-bottom : solid 1px #6D6D6D
}

hr.double:before {
  content          : "";
  position         : absolute;
  top              : 0;
  bottom           : 0;
  left             : 0;
  width            : 70px;
  background-color : #13395e
}

.dot {
  position : relative;

  :before {
	content       : "";
	display       : inline-block;
	width         : 5px;
	height        : 5px;
	border-radius : 50%;
	margin        : 2px 7px
  }
}

.dot-white:before {
  background-color : #FFF
}

.dot-brown:before {
  background-color : #C58B39
}

.dot-gray:before {
  background-color : #898989
}

.hover-brown:hover {
  color : #13395e
}

.hover-pink:hover {
  color : #f2d7c1
}

#wrapper {
  font-family : 'Montserrat', sans-serif;;
  max-width   : 100%
}

header {
  position : absolute;
  z-index  : 100;
  top      : 0;
  left     : 0;
  right    : 0;

  nav {
	color   : #000;
	display : block;
	width   : 100%;

	ul {
	  width           : 100%;
	  list-style-type : none;
	  padding         : 0;
	  margin          : 0;
	  display         : flex;
	  justify-content : space-between;

	  li {
		width      : auto;
		text-align : center;
		display    : inline-block;

		a {
		  font-size      : 17px;
		  position       : relative;
		  display        : block;
		  color          : #FFF;
		  text-transform : uppercase;
		  cursor         : pointer;
		  padding        : 20px 41px 20px 31px;

		  &:before {
			content             : '';
			width               : 0;
			height              : 0;
			border              : 7px solid transparent;
			border-bottom-color : #FFF;
			position            : absolute;
			top                 : 18px;
			right               : -4px;
			@media screen and (max-width : 600px) {
			  display : none;
			}
		  }

		  &:after {
			content  : '';
			position : absolute;
			top      : 32px;
			right    : -4px;
			width    : 0;
			height   : 0;
			border   : 7px solid transparent;
			@media screen and (max-width : 600px) {
			  display : none;
			}
		  }

		  &:before {
			top : 18px;
		  }

		  &:after {
			top : 32px;
		  }

		  &.hover-brown {
			&:hover {
			  color : #13395e
			}
		  }
		}

		&.has-subpage {
		  a {
			span {
			  &:after {
				opacity             : 0;
				content             : "";
				position            : absolute;
				z-index             : 101;
				bottom              : -17px;
				left                : 45%;
				width               : 29px;
				height              : 17px;
				/*background-image: url(../images/menu-after.png);*/
				background-position : top center;
				background-size     : 100%;
				background-repeat   : no-repeat;
				transition          : opacity .3s ease-in-out
			  }
			}
		  }

		  &:hover {
			a {
			  span {
				&:after {
				  opacity : 1
				}
			  }
			}
		  }
		}

		&:first-child {
		  a {
			padding-left : 0
		  }
		}

		&:last-child {
		  a {
			padding-right : 0;

			&:before,
			&:after {
			  display : none;
			}
		  }
		}

		&:hover {
		  .dropdown {
			border-top : solid 2px #13395e;
			height     : 70px;
		  }
		}

		.dropdown {
		  max-width        : 100% !important;
		  position         : absolute;
		  z-index          : 100;
		  left             : 0;
		  right            : 0;
		  border-top       : solid 0 #13395e;
		  background-color : #F4F1ED;
		  height           : 0;
		  overflow         : hidden;
		  transition       : all .3s ease-in-out;

		  ul {
			width        : 100%;
			margin       : 0;
			padding      : 0;
			display      : table;
			table-layout : fixed;

			li {
			  display          : table-cell;
			  padding          : 0;
			  vertical-align   : middle;
			  background-color : rgba(197, 156, 112, 0);

			  a {
				padding     : 0 !important;
				color       : #010101;
				font-weight : 400;

				&:after,
				&:before {
				  display : none;
				}

				span {
				  width       : 100%;
				  display     : block;
				  padding     : 20px 0;
				  line-height : 28px;

				  &:after,
				  &:before {
					display : none;
					content : none;
				  }
				}

				&:hover {
				  color : #FFF;
				}
			  }

			  &:hover {
				background-color : #13395e;

				a {
				  color : #FFF
				}
			  }
			}
		  }
		}
	  }
	}
  }
}


header {
  .header {
	nav {
	  .nav-logo {
		display : none;

		a {
		  margin  : 0 !important;
		  padding : 0 !important;

		  img {
			max-width : 100%;
			width     : 60px
		  }
		}

		svg {
		  display : inline-block;
		  margin  : auto
		}
	  }

	  .header-logo__block {
		display : inline-block;
		margin  : auto
	  }
	}

	.header-logo {
	  transition       : all .1s ease-in-out;
	  background-color : rgba(255, 255, 255, 0);
	  padding-top      : 10px;
	  padding-bottom   : 10px;
	  text-align       : center;
	  max-height       : 130px;
	  overflow         : hidden;

	  svg {
		height : 100px;
		width  : auto
	  }

	  .button-loyalty {
		position         : absolute;
		top              : 50%;
		right            : -15px;
		background-color : #F8F9FA;
		color            : #898989;
		border           : none;
		margin-top       : -15px;
		outline          : 0 !important;
		font-size        : 18px;
		line-height      : 18px
	  }
	}
  }
}

header .header-light nav ul li a:before {
  border-bottom-color : #FFF
}

header .header-dark nav ul li a:before, header .header-light:hover nav ul li a:before {
  border-bottom-color : #13395e
}

header .header-light nav ul li a:after {
  border-top-color : #FFF
}

header .header-dark nav ul li a:after, header .header-light:hover nav ul li a:after {
  border-top-color : #13395e
}

header .header-light .header-logo svg {
  fill : #FFF
}

header .header-light .menu-bg {
  position         : relative;
  background-color : rgba(255, 255, 255, .3)
}

header .header-light:hover .header-logo, header .header-light:hover .menu-bg {
  background-color : #FFF
}

header .header-light:hover .header-logo svg #mozart_logo--head,
header .header-light:hover .header-logo svg #mozart_logo--lines,
header .header-light:hover .header-logo svg #mozart_logo--subtitle,
header .header-light:hover .header-logo svg #mozart_logo--title {
  fill : #13395e
}

header .header-light:hover .header-logo .button-loyalty {
  color            : #F8F9FA;
  background-color : #898989
}

header .header-light:hover nav ul li a {
  color : #000
}

header .header-dark .header-logo svg.mozart_logo--head, header .header-dark .header-logo svg.mozart_logo--lines {
  fill : #13395e
}

header .header-dark .header-logo svg.mozart_logo--subtitle, header .header-dark .header-logo svg.mozart_logo--title {
  fill : #000
}

header .header-dark .menu-bg {
  position         : relative;
  background-color : rgba(0, 0, 0, .3)
}


header .header .dropdowns {
  margin-top : -1px
}

header .header .dropdowns .overlay-menu {
  position   : absolute;
  top        : -2000px;
  background : rgba(0, 0, 0, .85);
  padding    : 22px 30px 30px;
  color      : #FFF;
  transition : top .6s ease-in-out;
  box-shadow : 0 3px 12px 0 rgba(0, 0, 0, .63);
  width      : 320px;
  z-index    : 9999
}

header .header .dropdowns .overlay-menu.main-menu {
  right : 15px
}

header .header .dropdowns .overlay-menu ul {
  list-style     : none;
  padding        : 0;
  margin         : 0;
  font-weight    : 400;
  font-size      : 16px;
  line-height    : 18px;
  text-transform : uppercase
}

header .header .dropdowns .overlay-menu ul li {
  border-bottom  : grey solid 1px;
  width          : 100%;
  display        : block;
  padding        : 8px 0;
  vertical-align : middle
}

header .header .dropdowns .overlay-menu ul li a {
  vertical-align : middle;
  color          : #FFF;
  position       : relative
}

header .header .dropdowns .overlay-menu ul li a:before {
  background       : #13395e;
  content          : "";
  height           : 0;
  width            : 3px;
  display          : inline-block;
  vertical-align   : text-bottom;
  transition       : all .3s ease-in-out;
  transform-origin : top
}

header .header .dropdowns .overlay-menu ul li.menu-title a:after, header .header .dropdowns .overlay-menu ul li.social-links ul li a:after {
  content : none
}

header .header .dropdowns .overlay-menu ul li a.has-dropdown.active:before, header .header .dropdowns .overlay-menu ul li a:hover:before, header .header .dropdowns .overlay-menu ul li.nav-path-selected a.nav-selected:before {
  height       : 18px;
  width        : 3px;
  margin-right : 10px
}

header .header .dropdowns .overlay-menu ul li a:hover {
  text-decoration : none
}

header .header .dropdowns .overlay-menu ul li a.btn-close:before {
  display : none
}

header .header .dropdowns .overlay-menu ul li.nav-path-selected ul.inner-dropdown {
  display : block
}

header .header .dropdowns .overlay-menu ul li.social-links {
  margin-top : 12px;
  border     : none
}

header .header .dropdowns .overlay-menu ul li.social-links a:before {
  display : none
}

header .header .dropdowns .overlay-menu ul li.social-links ul li {
  border       : none;
  float        : left;
  margin-right : 20px
}

header .header .dropdowns .overlay-menu ul li.social-links ul li:last-child {
  margin-right : 0
}

header .header .dropdowns .overlay-menu ul li.social-links ul li a {
  display             : block;
  background-repeat   : no-repeat;
  background-position : center center;
  height              : 21px
}

header .header .dropdowns .overlay-menu ul li.menu-title {
  padding-bottom : 12px;
  position       : relative
}

header .header .dropdowns .overlay-menu ul li.menu-title a {
  position        : absolute;
  top             : 0;
  right           : 0;
  background      : url(img/302a49e01bfa6e467f5639248912f885) center center no-repeat;
  background-size : contain;
  width           : 20px;
  height          : 100%
}

header .header .dropdowns .overlay-menu ul li ul.language-menu {
  font-size   : 16px;
  display     : none;
  padding-top : 7px
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown {
  padding-left : 10px
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li {
  border  : none;
  padding : 5px 0 8px
}

header.fixed .header-dark nav ul li a:before, header.fixed .header-light nav ul li a:before, header.relative .header-dark nav ul li a:before, header.relative .header-light nav ul li a:before {
  border-bottom-color : #13395e
}

header.fixed .header-dark nav ul li a:after, header.fixed .header-light nav ul li a:after, header.relative .header-dark nav ul li a:after, header.relative .header-light nav ul li a:after {
  border-top-color : #13395e
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li a {
  font-size : 16px
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li a:hover:before {
  height : 18px
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li ul {
  margin-left : 15px;
  font-size   : 16px
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li ul li {
  padding : 0
}

header .header .dropdowns .overlay-menu ul li ul.inner-dropdown li ul li a:hover:before {
  height : 14px
}

header .header .dropdowns .overlay-menu ul.inner-dropdown {
  font-size   : 16px;
  display     : none;
  padding-top : 7px
}

header.fixed .header-home nav ul li a, header.relative .header-home nav ul li a {
  padding-top    : 10px;
  padding-bottom : 10px
}

header .header .dropdowns .main-menu.open {
  top : 70px
}

header .header .dropdowns .scrolled .location-menu.open, header .header .dropdowns .scrolled .main-menu.open {
  top : 72px
}

header .header .dropdowns .location-menu .menu-title:before {
  content         : '';
  background      : url(img/b74e5e9b6448578a5b95b4d5d598fbdd) center center no-repeat;
  background-size : contain;
  display         : inline-block;
  width           : 20px;
  height          : 25px;
  margin-right    : 15px;
  margin-top      : 7px
}

header.fixed .header-logo, header.relative .header-logo {
  display : none
}

header .header .dropdowns .menu-langs {
  width : 30px !important
}

header .header .dropdowns .menu-langs a svg {
  width  : 30px !important;
  height : 21px !important
}

header.relative {
  position : relative
}

header.relative .header-home {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

header.relative .header-home nav ul li a:before {
  top : 8px
}

header.relative .header-home nav ul li a:after {
  top : 22px
}

header.relative .header-home nav ul li:hover .dropdown,
header.relative .header-home nav ul li:hover .dropdown ul li {
  height : 50px
}

header.relative .header-light .header-logo,
header.relative .header-light .menu-bg {
  background-color : rgba(255, 255, 255, .95)
}

header.relative .header-light .header-logo svg #mozart_logo--head,
header.relative .header-light .header-logo svg #mozart_logo--lines {
  fill : #13395e
}

header.relative .header-light .header-logo svg #mozart_logo--subtitle,
header.relative .header-light .header-logo svg #mozart_logo--title {
  fill : #000
}

header.relative .header-light nav ul li a {
  color : #000
}

header.relative .header-dark .menu-bg {
  background-color : rgba(0, 0, 0, .8)
}

header {
  @media (max-width : 992px) {
	.menu-bg {
	  max-height : 0;
	  overflow   : hidden;
	  transition : max-height .3s ease-out;

	  &.open {
		max-height : 650px;
		position   : relative;
		display    : block !important;
		transition : max-height .3s ease-in;

		ul {
		  display : block;
		}

		li {
		  display : block;

		  .dropdown {
			display : none;
		  }

		  a {
			color   : #003B64;
			padding : 10px 29px;
		  }
		}
	  }
	}
  }
}

header.relative .header-dark nav ul li a {
  color : #FFF
}

header.fixed {
  position : fixed
}

header.fixed .header-home {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

header.fixed .header-home nav ul li a:before {
  top : 8px
}

header.fixed .header-home nav ul li a:after {
  top : 22px
}

header.fixed .header-home nav ul li:hover .dropdown {
  height : 56px
}

header.fixed .header-home nav ul li:hover .dropdown ul li a span {
  padding : 13px 0
}

header.fixed .header-light .header-logo, header.fixed .header-light .menu-bg {
  background-color : rgba(255, 255, 255, .95)
}

header.fixed .header-light .header-logo svg #mozart_logo--head, header.fixed .header-light .header-logo svg #mozart_logo--lines {
  fill : #13395e
}

header.fixed .header-light .header-logo svg #mozart_logo--subtitle, header.fixed .header-light .header-logo svg #mozart_logo--title {
  fill : #000
}

header.fixed .header-light nav ul li a {
  color : #000
}

header.fixed .header-dark .menu-bg {
  background-color : rgba(0, 0, 0, .8)
}

header.fixed .header-dark nav ul li a {
  color : #FFF
}

main {
  transition : transform .2s cubic-bezier(.16, .68, .43, .99)
}

.module {
  position : relative
}


.module-destination {
  margin-top : 0
}

.module-destination__viewbox {
  color   : #FFF;
  padding : 10px 0;

  a {
	cursor         : pointer;
	opacity        : .6;
	padding        : 5px 15px;
	display        : inline-block;
	color          : #FFF;
	text-transform : uppercase;

	svg {
	  width  : auto;
	  height : 22px
	}

	&.active {
	  opacity : 1
	}

	&:hover {
	  color : #f2d7c1
	}

	&.dest_view_1 {
	  margin-right : 30px
	}

	&.dest_view_2 {
	  margin-left : 30px
	}
  }
}

.module-destination__viewall {
  z-index       : 100;
  position      : absolute;
  top           : 10px;
  left          : 200px;
  display       : inline-block;
  max-height    : 40px;
  overflow      : hidden;
  border-radius : 1px;

  a {
	cursor           : pointer;
	display          : inline-block;
	padding          : 2px 10px;
	background-color : #FFF;
	color            : #333;

	span {
	  display : inline-block;

	  img {
		margin-top : -3px;
		width      : auto;
		height     : 40px;
		transform  : rotate(180deg)
	  }
	}

  }

  &.hidden {
	display : none
  }
}

.module-destination__content {
  display          : block;
  position         : relative;
  z-index          : 1;
  height           : 700px;
  width            : 560px;
  max-width        : 100%;
  background-color : rgba(255, 255, 255, .95);
  margin-top       : -700px
}

.module-destination__content--desc {
  padding-top   : 30px;
  padding-left  : 70px;
  padding-right : 70px
}

.module-destination__content--hotels {
  position : absolute;
  bottom   : -30px;
  left     : 0;
  right    : 0
}

.module-destination__content--item {
  max-width  : 280px;
  margin     : auto auto 30px;
  text-align : center;
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.module-destination__content--item a.button {
  position    : absolute;
  left        : 50%;
  transform   : translateX(-50%);
  bottom      : 30px;
  white-space : nowrap
}

.module-destination__content--item img {
  width  : 100%;
  height : auto
}

.module-destination__content--item .logo {
  background-color : #333;
  padding          : 10px 30px 40px;
  height           : 140px;
  text-align       : center
}

.module-destination__content--item .logo img {
  height : 100%;
  width  : auto;
  margin : auto
}

.module-destination__list {
  padding-bottom : 30px
}

.module-destination__list--image {
  img {
	width   : 100%;
	height  : auto;
	display : block;
  }
}

.module-destination__map {
  position : relative
}

.module-destination__map--bg {
  position            : absolute;
  top                 : 0;
  left                : 0;
  right               : 0;
  bottom              : 0;
  background-position : center;
  background-size     : 100% auto;
  background-repeat   : no-repeat
}

.module-authentic__parallax, .module-heritage__parallax {
  background-position : center;
  background-size     : cover;
  left                : 0;
  background-repeat   : no-repeat
}

.module-destination__map--menu {
  background-color : #FFF;
  overflow         : hidden;
  display          : inline-block;
  position         : absolute;
  z-index          : 10;
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0
}

.module-destination__map--menu ul {
  margin          : 0;
  padding         : 0;
  display         : block;
  list-style-type : none;
  width           : 100%;
  height          : 100%
}

.module-destination__map--menu ul li {
  display : block;
  width   : 100%
}

.module-destination__map--menu ul li a {
  color          : #13395e;
  padding        : 15px 20px;
  text-transform : uppercase;
  letter-spacing : 1px;
  display        : block
}

.module-destination__map--menu ul li:hover {
  cursor           : pointer;
  background-color : #13395e
}

.module-destination__map--menu ul li:hover a {
  color : #FFF
}

.module-destination__map--menu ul li:first-child {
  background-color : #FFF
}

.module-destination__map--menu ul li:first-child a {
  color   : #13395e;
  display : inline-block;
  padding : 0
}

.module-destination__map--menu ul li:first-child a svg {
  display          : inline-block;
  background-color : #13395e;
  height           : 60px;
  width            : 65px;
  padding          : 20px
}

.module-destination__map--menu ul li:first-child a label {
  width          : 190px;
  text-align     : center;
  text-transform : uppercase;
  letter-spacing : 1px;
  color          : #13395e;
  margin-bottom  : 0
}

.module-authentic__title h2, .module-heritage__title h2 {
  letter-spacing : 10px;
  text-shadow    : 2px 2px 8px #000;
  text-transform : uppercase
}

.module-destination__map--menu ul li:first-child a label img {
  height  : 50px;
  width   : auto;
  margin  : auto;
  display : inline-block
}

.module-authentic__title h2.ml-50, .module-heritage__title h2.ml-50 {
  margin-left : -50px
}

.module-destination__map--menu ul li:first-child a.mhg {
  color            : #FFF;
  background-color : #6D6D6D;
  text-align       : center;
  display          : block;
  width            : 100%;
  padding          : 20px 0
}

.module-destination__map--menu ul li:first-child.bggray {
  background-color : #6D6D6D
}

.module-destination__map--menu ul li:first-child.bggray a label {
  color : #FFF
}

.module-destination #map_content {
  height : 700px;
  width  : 100%
}

.module-destination #map_content .gm-style-iw-c {
  position      : absolute;
  box-sizing    : border-box;
  overflow      : hidden;
  top           : 0;
  left          : 0;
  width         : 420px;
  max-width     : 100% !important;
  height        : 175px;
  transform     : translate(-50%, -100%);
  border-radius : 1px;
  padding       : 10px !important;
  box-shadow    : 0 2px 7px 1px rgba(0, 0, 0, .3)
}

.border-shadow, .module-offers__item--img {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.module-destination #map_content .gm-style-iw-d {
  overflow : hidden !important
}

.img-zoom {
  overflow : hidden
}

.module-destination #map_content .gm-style-iw-d b, .module-destination #map_content .gm-style-iw-d strong {
  font-weight : 700 !important
}

.module-destination #map_content .gm-style div > div > div > div > div > img {
  width  : 100% !important;
  height : auto !important
}

.module-destination.theme-black .module-destination__map--menu ul li:first-child,
.module-destination.theme-black .module-destination__map--menu ul li:first-child a.mhg,
.module-destination.theme-black .module-destination__map--menu ul li:first-child.bggray,
.module-destination.theme-black .module-destination__viewbox {
  background-color : #333

}

.module-heritage__title {
  padding-right : 100px;
  margin-top    : 100px
}

.module-heritage__title h2 {
  font-size : 50px;
  color     : #FFF;
  position  : relative
}

.module-heritage__title h2.fs40 {
  font-size : 40px
}

.module-heritage__title h2.line:after, .module-heritage__title h2.line:before {
  content          : "";
  height           : 2px;
  background-color : #FFF;
  width            : 0;
  position         : absolute;
  right            : 450px
}

.module-heritage__title h2.line:before {
  top                : 16px;
  -webkit-transition : width 1.5s;
  transition         : width 1.5s
}

.module-heritage__title h2.line:after {
  top                : 20px;
  -webkit-transition : width 2s;
  transition         : width 2s
}

.module-heritage__title.animation h2.line:after, .module-heritage__title.animation h2.line:before {
  width : 3000px !important
}

.module-heritage__parallax {
  z-index  : -1;
  position : absolute;
  right    : 0;
  top      : 0;
  height   : 600px
}

.module-heritage__parallax .overlay {
  position         : absolute;
  left             : 0;
  bottom           : 0;
  height           : 100%;
  width            : 100%;
  z-index          : 5;
  background-color : rgba(0, 0, 0, .4)
}

.module-heritage__images {
  margin-top : 190px
}

.module-heritage__images .first {
  margin-top : -190px;
  opacity    : 0;
  transform  : translate3d(600px, 0, 0);
  transition : opacity .5s, transform .5s
}

.module-heritage__images .first.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-heritage__images .second {
  opacity    : 0;
  transform  : translate3d(400px, 0, 0);
  transition : opacity .5s, transform 1s
}

.module-heritage__images .second.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-heritage__images .third {
  opacity    : 0;
  transform  : translate3d(200px, 0, 0);
  transition : opacity .35s, transform 1.5s
}

.module-heritage__images .third.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-authentic__title {
  padding-right : 100px;
  margin-top    : 100px
}

.module-authentic__title h2 {
  font-size : 50px;
  color     : #FFF;
  position  : relative
}

.module-authentic__title h2.fs40 {
  font-size : 40px
}

.module-authentic__title h2.line:after, .module-authentic__title h2.line:before {
  content          : "";
  height           : 2px;
  background-color : #FFF;
  width            : 0;
  position         : absolute;
  right            : 450px
}

.module-authentic__title h2.line:before {
  top                : 16px;
  -webkit-transition : width 1.5s;
  transition         : width 1.5s
}

.module-authentic__title h2.line:after {
  top                : 20px;
  -webkit-transition : width 2s;
  transition         : width 2s
}

.module-authentic__title.animation h2.line:after, .module-authentic__title.animation h2.line:before {
  width : 3000px !important
}

.module-authentic__parallax {
  z-index  : -1;
  position : absolute;
  right    : 0;
  top      : 0;
  height   : 600px
}

.module-authentic__parallax .overlay {
  position         : absolute;
  left             : 0;
  bottom           : 0;
  height           : 100%;
  width            : 100%;
  z-index          : 5;
  background-color : rgba(0, 0, 0, .4)
}

.module-authentic__images {
  margin-top : 250px
}

.module-authentic__images .first {
  margin-top : -190px;
  opacity    : 0;
  transform  : translate3d(600px, 0, 0);
  transition : opacity .5s, transform .5s
}

.module-authentic__images .first.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-authentic__images .second {
  opacity    : 0;
  transform  : translate3d(400px, 0, 0);
  transition : opacity .5s, transform 1s
}

.module-authentic__images .second.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-authentic__images .third {
  opacity    : 0;
  transform  : translate3d(200px, 0, 0);
  transition : opacity .35s, transform 1.5s
}

.module-authentic__images .third.animation {
  opacity   : 1;
  transform : translate3d(0, 0, 0)
}

.module-featuredspots {
  margin-top       : 20px;
  padding          : 10px 0 30px;
  background-color : #EAEAEA
}

.module-featuredspots__title {
  position   : relative;
  text-align : center;
  margin-top : -85px;

  h2 {
	color            : #666;
	background-color : #FFF;
	text-align       : center;
	display          : inline-block;
	box-sizing       : border-box;
	margin           : 30px auto;
	font-size        : 35px;
	line-height      : 35px;
	letter-spacing   : 5px;
	text-transform   : uppercase;
	padding          : 20px;
	position         : relative;
	z-index          : 1;
	@media screen and (max-width : 420px) {
	  padding   : 20px 10px;
	  font-size : 28px;
	}
  }
}


.module-featuredspots__title:after, .module-featuredspots__title:before {
  content          : "";
  width            : 100%;
  background-color : #CACACA;
  position         : absolute;
  left             : 0
}

.module-featuredspots__title:before {
  height : 1px;
  top    : 60px
}

.module-featuredspots__title:after {
  top    : 63px;
  height : 2px
}

.module-featuredspots__item .desc-spots {
  padding          : 5px 10px;
  display          : block;
  font-size        : 14px;
  background-color : #FFF;
  color            : #13395e;
  text-transform   : uppercase
}

.module-featuredspots__item--table {
  display : table;
  width   : 100%;
  height  : 100%
}

.module-featuredspots__item--tablecell {
  display        : table-cell;
  width          : 100%;
  height         : 100%;
  vertical-align : middle;
  text-align     : center
}

.module-featuredspots__item--tablecell h3 {
  text-transform   : uppercase;
  letter-spacing   : 3px;
  display          : inline-block;
  padding          : 20px 25px;
  color            : #FFF;
  background-color : rgba(0, 0, 0, .7);
  position         : relative;
  font-size        : 16px
}

.module-featuredspots__item--tablecell h3:before {
  content            : "";
  left               : 50%;
  margin-left        : -35px;
  bottom             : -4px;
  width              : 70px;
  height             : 5px;
  position           : absolute;
  z-index            : 10;
  background-color   : #13395e;
  -webkit-transition : bottom .2s ease-in;
  transition         : bottom .2s ease-in
}

.module-featuredspots__item--tablecell h3:after {
  content            : "";
  position           : absolute;
  left               : 15px;
  top                : -5px;
  right              : 15px;
  bottom             : -5px;
  border             : 1px solid #FFF;
  z-index            : 10;
  -webkit-transition : bottom .2s ease-in, top .2s ease-in;
  transition         : bottom .2s ease-in, top .2s ease-in
}

.module-featuredspots__item:hover h3:before {
  bottom : 6px
}

.module-featuredspots__item:hover h3:after {
  top    : -15px;
  bottom : 5px
}

.module-featuredspots__item p {
  text-align : center
}


.module-offers--bg {
  position            : absolute;
  z-index             : 0;
  top                 : 0;
  left                : 0;
  right               : 0;
  height              : 300px;
  background-position : top center;
  background-size     : 100%;
  background-repeat   : no-repeat;
  background-color    : transparent
}

.module-offers__title {
  text-align : center;

  .title {
	position       : relative;
	display        : inline-block;
	text-transform : uppercase;
	padding        : 50px 0;
	margin         : 0;
	font-size      : 45px;
	line-height    : 1;
	letter-spacing : 15px;
	color          : #666;
	@media screen and (max-width : 600px) {
	  font-size      : 28px;
	  letter-spacing : 5px;
	  padding        : 50px 20px;
	}

	span {
	  position         : absolute;
	  bottom           : 35px;
	  left             : 50%;
	  transform        : translateX(-50%);
	  z-index          : 2;
	  height           : 6px;
	  width            : 70px;
	  background-color : #13395e
	}

	&:after,
	&:before {
	  content          : "";
	  position         : absolute;
	  z-index          : 1;
	  width            : 100%;
	  height           : 2px;
	  background-color : #CACACA;
	  left             : 0
	}

	&:before {
	  bottom : 39px
	}

	&:after {
	  bottom : 35px
	}
  }
}


.module-offers__item--desc h3 span,
.module-offers__item--price p {
  color : #13395e
}


.module-offers__content {
  position : relative;
  z-index  : 1;
  padding  : 70px 0 10px
}

.module-offers__item--img {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.module-offers__item--desc {
  padding : 15px 0;

  h3 {
	font-size : 34px
  }

  .date {
	font-size      : 14px;
	line-height    : 14px;
	text-transform : uppercase;
	color          : #13395e;
	margin         : 10px 0;
	display        : block
  }
}

.module-offers__item--price {
  background-color : #333
}

.module-hotels {
  background-color : #FFF;
  padding          : 15px 0 0;
  text-align       : center
}

.module-hotels--mhg {
  position : relative;

  img {
	display  : inline-block;
	height   : 130px;
	width    : auto;
	margin   : auto;
	position : inherit;
	z-index  : 10
  }

  &:before {
	content          : "";
	width            : 100%;
	position         : absolute;
	z-index          : 0;
	top              : 50%;
	left             : 0;
	height           : 2px;
	background-color : #D0D0D0
  }
}

.block {
  position : relative
}

.module-hotels {
  ul {
	list-style-type : none;
	margin          : 0 auto;
	padding         : 0;
	display         : table;
	table-layout    : fixed;
	width           : 100%;

	li {
	  display    : table-cell;
	  text-align : center
	}
  }
}

.module-contact {
  color            : #FFF;
  background-color : #333;
  padding          : 50px 0
}

.module-contact__logo {
  z-index    : 1;
  position   : relative;
  text-align : center;
  padding    : 40px
}

.module-contact__logo img {
  max-width : 100%;
  width     : 100%;
  height    : auto;
  margin    : auto
}

.module-contact__social {
  position : relative;
  z-index  : 1;

  h5 {
	text-align     : center;
	text-transform : uppercase;
	padding        : 20px
  }

  ul {
	display         : table;
	table-layout    : fixed;
	list-style-type : none;
	margin          : 0 auto;
	padding         : 0;
	max-width       : 300px;

	li {
	  display    : table-cell;
	  text-align : center;
	  padding    : 0 10px;

	  img {
		height : 40px;
		width  : auto;
		margin : auto
	  }
	}
  }
}

.block-booking {
  z-index          : 2;
  margin-top       : -70px;
  background-color : rgba(0, 0, 0, .8);
  padding          : 0;
  max-height       : 70px;
  @media screen and (max-width : 600px) {
	max-height : none;
  }

  label {
	width   : 100%;
	padding : 0;
	margin  : 0
  }

  .block-booking__input {
	text-transform   : uppercase;
	width            : 100%;
	text-align       : center;
	color            : #FFF;
	background-color : transparent;
	border           : 1px solid #FFF;
	padding          : 5px 10px
  }

  .block-booking__button {
	transition  : all 0s ease-in-out;
	margin      : 15px 0;
	width       : 100%;
	white-space : nowrap;
	display     : block;
	font-size   : 18px;
	line-height : 20px
  }

  .before {
	position : relative;

	&:after,
	&:before {
	  background-color : #B3B3B3;
	  opacity          : .7;
	  width            : 20px;
	  height           : 2px;
	  position         : absolute;
	  z-index          : 5;
	  left             : -25px;
	  content          : ""
	}

	&:before {
	  top : 31px
	}

	&:after {
	  bottom : 31px
	}

	input {
	  background-size : 20px 20px
	}
  }

  .dropdown {
	max-width : 100%
  }

  .dropdown-item {
	padding : 6px 10px
  }

  &.fixed {
	background-color : rgba(0, 0, 0, .9);
	position         : fixed;
	z-index          : 10;
	margin-top       : 0;
	top              : 45px;
	left             : 0;
	right            : 0;

	.block-booking__input,
	.button {
	  margin : 10px 0
	}
  }

}

.block-contact {
  position : relative;
  z-index  : 1
}

.block-contact__title {
  text-transform : uppercase;
  padding        : 20px 0
}

.block-contact__desc {
  [itemprop="telephone"] * {
	display        : inline-block;
	vertical-align : middle;
  }

  p {
	font-size     : 14px;
	line-height   : 16px;
	margin-bottom : 5px
  }
}

.block-newsletter {
  margin     : auto;
  padding    : 0 15px;
  z-index    : 1;
  text-align : center;

  .response {
	font-size     : 12px;
	margin-bottom : 6px;
	line-height   : 125%;
  }
}

.block-newsletter__input {
  width            : 100%;
  max-width        : 300px;
  border           : 1px solid #f2d7c1;
  border-radius    : 1px;
  color            : #FFF;
  margin           : auto auto 15px;
  text-align       : center;
  background-color : transparent;
  padding          : 7px;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
	text-transform : uppercase
  }
}


.block-title--hr {
  position      : relative;
  height        : 5px;
  width         : 100%;
  margin-bottom : 15px;

  &:after {
	content          : "";
	left             : 0;
	bottom           : 0;
	width            : 70px;
	height           : 5px;
	position         : absolute;
	z-index          : 10;
	background-color : #13395e
  }

  span {
	position : absolute;
	top      : 0;
	left     : 0;
	width    : 100%;
	height   : 100%;

	&:after, &:before {
	  position         : absolute;
	  left             : 0;
	  height           : 2px;
	  width            : 100%;
	  background-color : #CACACA;
	  z-index          : 10;
	  content          : ""
	}

	&:after {
	  top : 0
	}

	&:before {
	  bottom : 0
	}
  }
}


.block-title--line {
  text-align : center;

  h3 {
	font-family    : 'Playfair Display', serif;
	position       : relative;
	display        : inline-block;
	text-transform : uppercase;
	padding        : 50px 0;
	margin         : 0;
	font-size      : 35px;
	line-height    : 1;
	letter-spacing : 10px;
	color          : #666;
	@media screen and (max-width : 600px) {
	  font-size      : 24px;
	  padding        : 20px;
	  letter-spacing : 1px;
	  margin-bottom  : 10px;
	}

	span {
	  position         : absolute;
	  bottom           : 35px;
	  left             : 50%;
	  transform        : translateX(-50%);
	  z-index          : 2;
	  height           : 6px;
	  width            : 70px;
	  background-color : #13395e

	}

	&:after,
	&:before {
	  content          : "";
	  position         : absolute;
	  z-index          : 1;
	  width            : 100%;
	  height           : 2px;
	  background-color : #CACACA;
	  left             : 0
	}

	&:before {
	  bottom : 39px
	}

	&:after {
	  bottom : 35px
	}
  }
}

.block-image {
  .desc-spots {
	text-align       : center;
	text-transform   : uppercase;
	background-color : #FFF;
	color            : #13395e;
	display          : block;
	padding          : 5px 10px;
  }

  p {
	text-align : center;

	.button {
	  margin-top : 30px
	}
  }
}

.block-image__title {
  position : absolute;
  left     : 0;
  top      : 0;
  bottom   : 0;
  right    : 0;
  z-index  : 5;
  display  : block;

  .title-spots {
	text-transform : uppercase;
	letter-spacing : 3px;
	display        : inline-block;
	margin         : auto auto 0;
	color          : #FFF;
	position       : relative;
	font-size      : 16px;
	max-width      : 100%;
  }

  &:hover {
	&:hover .block-image__title--box:before {
	  bottom : 6px
	}

	&:hover .block-image__title--box:after {
	  top    : -15px;
	  bottom : 5px
	}
  }
}

.block-image__title--box {
  position         : relative;
  display          : table;
  height           : 70px;
  width            : 100%;
  max-width        : 260px;
  background-color : rgba(0, 0, 0, .7);
  text-align       : center;
  margin-left      : auto;
  margin-right     : auto;

  &:before {
	content          : "";
	left             : 50%;
	margin-left      : -35px;
	bottom           : -4px;
	width            : 70px;
	height           : 5px;
	position         : absolute;
	z-index          : 10;
	background-color : #13395e;
	transition       : bottom .2s ease-in
  }

  &:after {
	content    : "";
	position   : absolute;
	left       : 15px;
	top        : -5px;
	right      : 15px;
	bottom     : -5px;
	border     : 1px solid #FFF;
	z-index    : 10;
	transition : bottom .2s ease-in, top .2s ease-in
  }

}


.block-image__desc {
  display : table;
  width   : 100%;
  height  : 100px;
  margin  : 5px 0;
  padding : 10px 20px;

  .cell {
	display        : table-cell;
	vertical-align : middle;
	width          : 100%;
	height         : 100%
  }

  p {
	color         : #000 !important;
	margin-bottom : 0
  }
}

.shuffleEmpty {
  width : 100%;
}

.block-pagelist--item {
  position      : relative;
  margin-bottom : 30px;

  .d-table {
	display : table;
	height  : 100%;
	width   : 100%
  }

  .d-tablecell {
	width          : 100%;
	display        : table-cell;
	vertical-align : middle;
	text-align     : center
  }

  a.button {
	z-index     : 10;
	bottom      : -10px;
	white-space : nowrap
  }
}

.block-pagelist--image {
  img {
	display    : block;
	width      : 100%;
	height     : auto;
	transition : all .3s ease;

	&:hover {
	  transform : scale(1.1)
	}
  }
}

.block-pagelist--place {
  width            : 100%;
  text-align       : center;
  background-color : #13395e;
  color            : #FFF;
  padding          : 3px 0;
  text-transform   : uppercase;
  font-size        : 14px
}

.block-pagelist--desc {
  text-align       : center;
  background-color : #333;
  padding          : 5px;
  height           : 50px;
  color            : #FFF;
  text-transform   : uppercase;
  line-height      : 20px;

  .title {
	font-size : 18px;
  }

  .subtitle {
	font-size      : 14px;
  }
}

.block-pagelist--filter {
  background-color : #6D6D6D;
  color            : #FFF;

  .button-white {
	background-color : transparent;
	color            : #FFF;
	border-color     : #FFF
  }
}


footer {
  margin-top : 30px;

  .footer {
	ul {
	  list-style-type : none;
	  display         : table;
	  width           : 100%;
	  margin          : 0;
	  padding         : 0;
	  font-size       : 14px;
	  line-height     : 17px;
	  @media screen and (max-width : 420px) {
		display : block;
	  }

	  li {
		display    : table-cell;
		text-align : center;

		&:first-child {
		  @media screen and (max-width : 420px) {
			padding : 10px 0;
		  }

		}

		@media screen and (max-width : 420px) {
		  display : block;
		  padding : 0;
		}

		a {
		  display : inline-block;
		  padding : 15px 32px;
		  @media screen and (max-width : 420px) {
			display : block;
			padding : 10px 0;
		  }

		  &:hover {
			color : #f2d7c1 !important;
		  }
		}

		&:first-child {
		  a {
			padding-left : 0
		  }
		}

		&:last-child {
		  a {
			padding-right : 0
		  }
		}
	  }

	}
  }
}

[type=checkbox] {
  &:checked,
  &:not(:checked) {
	position       : absolute;
	opacity        : 0;
	pointer-events : none
  }

  & + span:not(.lever) {
	position     : relative;
	padding-left : 35px;
	cursor       : pointer;
	display      : inline-block;
	height       : 25px;
	line-height  : 25px;
	user-select  : none
  }

  & + span:not(.lever):before,
  &:not(.filled-in) + span:not(.lever):after {
	content       : '';
	position      : absolute;
	top           : 0;
	left          : 0;
	width         : 18px;
	height        : 18px;
	z-index       : 0;
	border        : 2px solid #5A5A5A;
	border-radius : 1px;
	margin-top    : 3px;
	transition    : .2s
  }
}


[type=checkbox]:not(.filled-in) + span:not(.lever):after {
  border    : 0;
  transform : scale(0)
}

[type=checkbox]:not(:checked):disabled + span:not(.lever):before {
  border           : none;
  background-color : rgba(0, 0, 0, .42)
}

[type=checkbox].tabbed:focus + span:not(.lever):after {
  transform        : scale(1);
  border           : 0;
  border-radius    : 50%;
  box-shadow       : 0 0 0 10px rgba(0, 0, 0, .1);
  background-color : rgba(0, 0, 0, .1)
}

[type=checkbox]:checked + span:not(.lever):before {
  top                 : -4px;
  left                : -5px;
  width               : 12px;
  height              : 22px;
  border-top          : 2px solid transparent;
  border-left         : 2px solid transparent;
  border-right        : 2px solid #C58B39;
  border-bottom       : 2px solid #C58B39;
  transform           : rotate(40deg);
  backface-visibility : hidden;
  transform-origin    : 100% 100%
}

[type=checkbox]:checked:disabled + span:before {
  border-right  : 2px solid rgba(0, 0, 0, .42);
  border-bottom : 2px solid rgba(0, 0, 0, .42)
}

[type=checkbox]:indeterminate + span:not(.lever):before {
  top                 : -11px;
  left                : -12px;
  width               : 10px;
  height              : 22px;
  border-top          : none;
  border-left         : none;
  border-right        : 2px solid #C58B39;
  border-bottom       : none;
  transform           : rotate(90deg);
  backface-visibility : hidden;
  transform-origin    : 100% 100%
}

[type=checkbox]:indeterminate:disabled + span:not(.lever):before {
  border-right     : 2px solid rgba(0, 0, 0, .42);
  background-color : transparent
}

[type=checkbox].filled-in + span:not(.lever):after {
  border-radius : 2px
}

[type=checkbox].filled-in + span:not(.lever):after, [type=checkbox].filled-in + span:not(.lever):before {
  content    : '';
  left       : 0;
  position   : absolute;
  transition : border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
  z-index    : 1
}

[type=checkbox].filled-in:not(:checked) + span:not(.lever):before {
  width            : 0;
  height           : 0;
  border           : 3px solid transparent;
  left             : 6px;
  top              : 10px;
  transform        : rotateZ(37deg);
  transform-origin : 100% 100%
}

[type=checkbox].filled-in:not(:checked) + span:not(.lever):after {
  height           : 20px;
  width            : 20px;
  background-color : transparent;
  border           : 2px solid #5A5A5A;
  top              : 0;
  z-index          : 0
}

[type=checkbox].filled-in:checked + span:not(.lever):before {
  top              : 0;
  left             : 1px;
  width            : 8px;
  height           : 13px;
  border-top       : 2px solid transparent;
  border-left      : 2px solid transparent;
  border-right     : 2px solid #FFF;
  border-bottom    : 2px solid #FFF;
  transform        : rotateZ(37deg);
  transform-origin : 100% 100%
}

[type=checkbox].filled-in:checked + span:not(.lever):after {
  top              : 0;
  width            : 20px;
  height           : 20px;
  border           : 2px solid #C58B39;
  background-color : #C58B39;
  z-index          : 0
}

[type=checkbox].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius    : 2px;
  border-color     : #5A5A5A;
  background-color : rgba(0, 0, 0, .1)
}

[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius    : 2px;
  background-color : #C58B39;
  border-color     : #C58B39
}

[type=checkbox].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color : transparent;
  border           : 2px solid transparent
}

[type=checkbox].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color     : transparent;
  background-color : #949494
}

[type=checkbox].filled-in:disabled:checked + span:not(.lever):before {
  background-color : transparent
}

[type=checkbox].filled-in:disabled:checked + span:not(.lever):after {
  background-color : #949494;
  border-color     : #949494
}

.lh24 {
  line-height : 24px
}

.overlay {
  background-color : rgba(0, 0, 0, .2);
  position         : absolute;
  z-index          : 2;
  top              : 0;
  left             : 0;
  right            : 0;
  bottom           : 0
}

.float-right {
  float : right
}

.gradient {
  background : rgba(0, 0, 0, 0);
  background : linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
}

.border-shadow {
  box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.dtable {
  display : table;
  width   : 100%;
  height  : 100%;

  .cell {
	display : table-cell;
	height  : 100%
  }

  .cell--mid {
	vertical-align : middle
  }

  .cell-bot {
	vertical-align : bottom
  }
}

.dropdown {
  max-width : 300px;
  width     : 100%;
  margin    : 0 auto;
}

.dropdown-toggle {
  width : 100%;

  &:after {
	content : none
  }
}

.dropdown-menu {
  padding          : 10px 15px;
  right            : 0;
  border-radius    : 1px;
  color            : #FFF;
  background-color : rgba(0, 0, 0, .8);
  box-shadow       : 0 5px 5px 1px rgba(0, 0, 0, .2)
}

.dropdown-item {
  position      : relative;
  padding-left  : 0;
  padding-right : 0;
  border-bottom : grey solid 1px;
  cursor        : pointer;
  transition    : all .2s ease-in-out;

  &:before {
	content    : "";
	height     : 0;
	transition : all .2s ease-in-out
  }

  &:active {
	background-color : transparent !important
  }

  &.current,
  &:hover {
	background-color : transparent;
	padding-left     : 10px;

	&:before {
	  background-color : #13395e;
	  position         : absolute;
	  bottom           : 5px;
	  left             : 0;
	  width            : 2px;
	  height           : 21px
	}
  }
}

.dropdown-header {
  padding-left     : 20px;
  text-transform   : uppercase;
  letter-spacing   : 5px;
  background-color : rgba(0, 0, 0, .1);

  span {
	display : inline-block;
	width   : 20px;

	img {
	  width     : 100%;
	  max-width : 100%;
	  height    : auto
	}
  }

}


.dropdown .chevron {
  /*    background: url(../images/svg/chevron-down.svg) 95% no-repeat;*/
  background-size : 20px 20px
}

.img-zoom img {
  transition : all .3s ease;

  &:hover {
	transform : scale(1.1)
  }
}


.countdown {
  background-color : #FFF;
  padding          : 10px;
  position         : fixed;
  bottom           : 50px;
  left             : 15px;
  display          : inline-block;
  font-size        : 50px;
  color            : red;
  text-align       : center;
  border           : 2px solid #333;
  z-index          : 100000
}

.hidden,
.row:after,
.row:before,
.dtp-btn-ok {
  display : none
}

@media (min-width : 576px) {
  .hidden-sm {
	display : none !important
  }
}

@media (min-width : 768px) {
  .block-booking:not(.fixed) form {
	height : 70px
  }

  .block-booking .before {
	height : 100%
  }

  .block-booking label {
	display         : flex;
	height          : 100%;
	justify-content : center;
	align-items     : center
  }

  .block-booking__input {
	height : 38px
  }

  .hidden-md {
	display : none !important
  }
}

@media (min-width : 992px) {
  .hidden-lg {
	display : none !important
  }

  .module-slider {
	height     : 100vh;
	max-height : 1080px
  }
}

@media (min-width : 1200px) {
  .hidden-xl {
	display : none !important
  }

  .module-hotels ul li img {
	margin    : auto;
	max-width : 100%;
  }
}

@media (max-width : 1240.98px) {
  .module-slider .owl-item img {
	width  : 100%;
	height : auto
  }
}

@media (max-width : 1199.98px) {
  .module-authentic__title h2.line:after, .module-authentic__title h2.line:before, .module-heritage__title h2.line:after, .module-heritage__title h2.line:before {
	right : 370px
  }

  .module-authentic__images .first, .module-heritage__images .first {
	margin-top : -158px
  }

  .hide-lg {
	display : none
  }

  header .header-home--en nav ul li a {
	padding : 15px 38px 15px 29px
  }

  header .header-home--en nav ul li a:before {
	top : 14px
  }

  header .header-home--en nav ul li a:after {
	top : 28px
  }

  header .header-logo svg {
	height : 70px
  }

  header .header nav ul li .dropdown ul li a span {
	padding : 15px 0
  }

  header .header nav ul li:hover .dropdown {
	height : 58px
  }

  .module-slider__desc .title-slider {
	font-size   : 40px;
	line-height : 40px
  }

  .module-slider .owl-nav img {
	height : 70px
  }

  .module-destination__content {
	width : 500px
  }

  .module-destination__content--item .logo {
	padding : 10px 0 40px
  }

  .module-offers--bg {
	background-size     : auto 100%;
	background-position : top center;
	height              : 250px
  }

  .module-offers__item--desc h3 {
	font-size : 30px
  }

  .module-offers .owl-prev {
	margin-left : -40px
  }

  .module-offers .owl-next {
	margin-right : -40px
  }

  #cModal1 {
	display         : flex;
	height          : 100%;
	width           : 100%;
	justify-content : center;
	align-items     : center
  }
}

@media (max-width : 991.98px) {
  .hide-md, header .header-light .menu-bg {
	display : none
  }

  header .header-light .header-logo, header .header-light .menu-bg {
	background-color : rgba(255, 255, 255, .95)
  }

  .module-authentic__title h2.line:after, .module-authentic__title h2.line:before, .module-heritage__title h2.line:after, .module-heritage__title h2.line:before {
	right : 250px
  }

  .mt0-md {
	margin-top : 0
  }

  .mt30-md {
	margin-top : 30px
  }

  header {
	position : fixed
  }

  .module-destination__content--hotels, header.relative {
	position : relative
  }

  header .header {
	box-shadow : 0 5px 5px 1px rgba(0, 0, 0, .2)
  }

  header .header-light .header-logo svg #mozart_logo--head, header .header-light .header-logo svg #mozart_logo--lines {
	fill : #13395e
  }

  header .header-light .header-logo svg #mozart_logo--subtitle, header .header-light .header-logo svg #mozart_logo--title {
	fill : #000
  }

  header .header-logo svg {
	max-height : 50px;
	width      : 100%;
	height     : auto
  }

  header.relative .header-logo {
	display : block
  }

  .module-slider {
	width      : 100%;
	height     : auto;
	margin-top : 65px
  }

  .module-slider .owl-item img {
	width  : 100%;
	height : auto
  }

  .module-destination {
	background-color : #FFF;
	height           : auto
  }

  .module-destination__content {
	width      : 100%;
	height     : auto;
	position   : relative;
	margin-top : 0
  }

  .module-destination__content--item {
	max-width : 100%
  }

  .module-destination__content--item .logo {
	height : auto
  }

  .module-destination__content--item .logo img {
	max-width : 100%;
	height    : auto
  }

  .module-authentic, .module-heritage {
	height : 600px
  }

  .module-destination__list--nav ul li {
	padding   : 10px;
	font-size : 14px
  }

  .module-heritage__images {
	margin-top : 60px
  }

  .module-heritage__images .first {
	margin-top : 0
  }

  .module-authentic__images {
	margin-top : 60px
  }

  .block-booking, .module-authentic__images .first {
	margin-top : 0
  }

  .module-offers .owl-nav img {
	height : 55px
  }

  .module-hotels ul li {
	display   : inline-block;
	max-width : 25%
  }

  .block-booking__input {
	font-size : 14px
  }

  .block-booking__button {
	margin         : 10px 0;
	font-size      : 16px;
	line-height    : 18px;
	letter-spacing : 2px;
	padding        : 7px 0;
	text-align     : center;
	border         : 0
  }
}

@media (max-width : 767.98px) {
  .hide-sm {
	display : none
  }

  .module-destination__map, .t-center-sm {
	text-align : center
  }

  header .header .dropdowns .overlay-menu {
	max-width : 100%
  }

  .block-booking .before:after, .block-booking .before:before {
	content : none
  }

  .block-booking.open {
	max-height       : inherit;
	position         : fixed;
	background-color : rgba(0, 0, 0, .9);
	z-index          : 1000;
	top              : 0;
	bottom           : 0;
	margin           : 0;
	display          : table;
	height           : 100%
  }

  .block-booking__form {
	display        : table-cell;
	height         : 100%;
	vertical-align : middle;
	position       : relative
  }

  .block-booking__form .close {
	font-size : 40px;
	position  : absolute;
	top       : 30px;
	right     : 20px;
	color     : #FFF
  }

  .block-booking__input {
	font-size : 16px
  }

  .block-booking__button {
	margin-top : 10px
  }

  .block-contact__title {
	padding    : 20px 0;
	text-align : center
  }

  .module-slider__desc .title-slider {
	font-size   : 40px;
	line-height : 40px
  }

  .module-slider .owl-nav img {
	height : 50px;
	width  : auto
  }

  .module-destination__map #map_content {
	height : 500px
  }

  .module-destination__map--menu {
	margin   : auto;
	position : relative;
	top      : unset;
	left     : unset;
	right    : unset;
	bottom   : unset
  }

  .module-destination__map--menu ul li a {
	text-align : left
  }

  .module-authentic__title, .module-heritage__title {
	padding    : 0;
	text-align : right
  }

  .module-destination__map--menu ul li.active a {
	width : 100%
  }

  .module-destination__list--nav {
	display : none
  }

  .module-heritage__title h2.line:after, .module-heritage__title h2.line:before {
	right : 330px
  }

  .module-authentic__title h2.line:after, .module-authentic__title h2.line:before {
	right : 290px
  }

  .module-offers--bg {
	background-image : none;
	background-color : #FFF
  }

  .module-contact {
	padding : 25px 0
  }

  .module-hotels {
	padding : 30px 0
  }

  footer .footer-home:after {
	border-width : 0 250px 100px 0
  }
}

@media (max-width : 575.98px) {
  .module-authentic__title h2.line:after, .module-authentic__title h2.line:before, .module-heritage__title h2.line:after, .module-heritage__title h2.line:before, .module-slider__desc .title-slider:after, .module-slider__desc .title-slider:before, .module-slider__desc .desc-slider:after, .module-slider__desc .desc-slider:before {
	content : none
  }

  .hidden-xs {
	display : none !important
  }

  .hide-xs, .module-offers .owl-nav, .module-slider .owl-nav {
	display : none
  }

  .ml0-xs {
	margin-left : 0
  }

  .text-center-xs {
	text-align : center !important
  }

  header .header .button--booking {
	width     : 150px;
	font-size : 22px
  }

  header .header-logo {
	max-height : 70px
  }

  .module-authentic__parallax, .module-heritage__parallax {
	max-height : 100%
  }

  header .header-logo__block {
	margin-right : -15px
  }

  .module-slider__desc {
	bottom    : 10px;
	top       : inherit;
	transform : none
  }

  .module-slider__desc .title-slider {
	font-size   : 22px;
	line-height : 22px;
	padding     : 10px
  }

  .module-slider__desc .desc-slider {
	font-size : 18px;
	padding   : 10px
  }

  .module-authentic__title h2, .module-destination__content--desc h1, .module-heritage__title h2 {
	font-size : 30px
  }

  .module-slider .owl-item .title-slider, .module-slider .owl-item .desc-slider {
	letter-spacing : 5px
  }

  .module-slider .owl-item img {
	width  : 100% !important;
	height : auto !important
  }

  .module-destination {
	margin-bottom : 30px
  }

  .module-destination__content--desc {
	padding : 20px 0
  }

  .module-destination__viewbox .dest_view_1 {
	margin-right : 10px !important
  }

  .module-destination__viewbox .dest_view_2 {
	margin-left : 10px !important
  }

  .module-authentic__title h2.ml-50, .module-heritage__title h2.ml-50 {
	margin-left : 0
  }

  .module-heritage {
	height : 600px
  }

  .module-heritage__title {
	text-align : center;
	margin-top : 50px
  }

  .module-heritage__images {
	margin-top : 30px
  }

  .module-heritage .mt35.pl60 {
	text-align   : center;
	padding-left : 0
  }

  .module-authentic {
	height : 500px
  }

  .module-authentic__title {
	text-align : center;
	margin-top : 50px
  }

  .module-authentic__images {
	margin-top : 30px
  }

  .module-offers__content {
	padding : 0
  }

  .module-offers__item--desc {
	padding : 5px
  }

  .module-hotels ul {
	max-width : 400px
  }

  .module-hotels ul li {
	max-width : none;
	width     : 50%
  }

  .module-hotels ul li img {
	height : 60px;
	width  : auto
  }


  .block-title--line h3:before {
	bottom : 9px
  }

  .block-title--line h3 span, .block-title--line h3:after {
	bottom : 5px
  }

  .block-image .desc-spots {
	font-size  : 14px;
	text-align : center
  }

  .block-contact {
	text-align : center
  }

  .block-contact__desc p {
	font-size   : 16px;
	line-height : 20px
  }

  .block-contact__social ul li {
	display : inline-block !important
  }

  .block-newsletter {
	padding : 15px
  }

  .block-newsletter form {
	max-width : 450px;
	margin    : auto
  }

  footer .footer-home ul {
	width   : 100%;
	display : block
  }

  footer .footer-home ul li {
	display : block
  }

  .dtp-content {
	max-width   : 300px !important;
	margin-left : inherit !important;
	transform   : translateX(-50%) !important
  }

  #cModal1 .modal-dialog {
	margin-top : 40% !important
  }
}

@media (max-width : 480px) {
  header .header .dropdowns .overlay-menu {
	max-width : 100%;
	right     : 10px;
	left      : 10px;
	width     : auto
  }
}

#ccm-account-menu {
  display : none !important;
  top     : 4px;
  right   : 4px;
  z-index : 9999
}