// Variables
// ---------
$body-font-size           : 16px;
$body-font                : 'Commissioner', system-ui, sans-serif;
$body-font-alt            : Georgia, Times, Times New Roman, serif;
$body-line-height         : 1.3;
$body-color               : #003B64;
$body-background          : #ECF0F3;


// Table
$table-header-font-weight : 700;
$table-cell-padding       : 5px 10px;

// Text
$heading-font-weight      : 700;
$heading-line-height      : 1.2;
$heading-margin           : 0 0 1em;
$paragraph-margin         : 0 0 1.7em;
$list-margin              : 2.6em 0;
$h1                       : 28px;
$h2                       : 24px;
$h3                       : 20px;
$h4                       : 18px;
$h5                       : 16px;
$bold-font-weight         : 700;
$space-1                  : 1em;
$space-2                  : 2em;
$space-3                  : 3em;
$text-small               : 14px;

// Color
$main                     : $body-color;
$red                      : #C21513;
$orange                   : #F65A3E;
$green                    : #00F5D4;
$blueLight                : #3BA6ED;
$blue                     : #003B64;
$marengo                  : #466562;
$dark                     : #2D333E;
$grey                     : #9E9E9E;

$link-color               : $blue;
$link-color-hover         : lighten($blue, 10%);

$break-desktop            : 1081px;
$break-desktop-max        : 1080px;
$break-table              : 681px;
$break-table-max          : 680px;