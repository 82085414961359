html {
	@media screen and (max-width : 1145px) {
		&.is-mobile-static {
			overflow : hidden;
		}
	}
}

html,
body {
	min-height : 100%;

	&.is-preload,
	&.is-overflow {
		overflow : hidden;
	}

	&.is-mobile-overflow {
		@media screen and (max-width : $break-desktop-max) {
			overflow : hidden;
		}
	}
}

body {
	position           : relative;
	overflow-x         : hidden;
	overflow-y         : auto;
	-moz-box-sizing    : border-box;
	-webkit-box-sizing : border-box;
	box-sizing         : border-box;
	min-width          : 320px;
	padding            : 0;
	background         : $body-background;
	overflow-anchor    : none;

	&.is-offcanvas {
		overflow : hidden;
	}
}

// Images
img {
	max-width      : 100%;
	height         : auto;
	vertical-align : top;
	border         : 0;
}

svg {
	max-height : 100%
}

select:not([multiple]) {
	vertical-align : middle;
}

// Table
table {
	width           : 100%;
	max-width       : 100%;
	border-spacing  : 0;
	border-collapse : separate;
}

th {
	font-weight : $table-header-font-weight;
	text-align  : left;
}

th,
td {
	line-height : inherit;
	padding     : $table-cell-padding;
}

th {
	vertical-align : bottom
}

td {
	vertical-align : top
}

// Text
a {
	text-decoration : underline;
	color           : $link-color;
	outline         : 0;

	&:hover {
		text-decoration : none;
		color           : $link-color-hover;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight : $heading-font-weight;
	line-height : $heading-line-height;
	margin      : $heading-margin;
}

p {
	margin : $paragraph-margin;

	&:last-child {
		margin-bottom : 0;
	}
}

dl,
ol,
ul {
	margin : $list-margin;
}

ol {
	list-style : decimal;
}

h1 {
	font-size : $h1;
}

h2 {
	font-size : $h2;
}

h3 {
	font-size : $h3;
}

h4 {
	font-size : $h4;
}

h5 {
	font-size : $h5;
}

hr {
	display    : block;
	height     : 1px;
	margin     : 30px 0;
	border     : 0;
	background : #DEDEDE;;
}

::-moz-focus-inner {
	padding : 0;
	border  : 0;
}

.hidden-box {
	display    : none;
	visibility : hidden;
}

iframe {
	border  : 0;
	outline : 0;
}

.videoWrapper {
	position       : relative;
	height         : 0;
	padding-bottom : 56.25%; /* 16:9 */
}

.videoWrapper iframe {
	position : absolute;
	top      : 0;
	left     : 0;
	width    : 100%;
	height   : 100%;
}

.text-lead {
	font-size   : 18px;
	line-height : 1.67;
	@media screen and (min-width : $break-desktop) {
		font-weight : 500;
	}
}

.text-center {
	text-align : center;
}

header,
nav,
section {
	display : block;
}

@keyframes dropdown-display {
	0% {
		opacity   : 0;
		transform : scale(.98) translateY(-.6em);
	}
	100% {
		opacity   : 1;
		transform : scale(1) translateY(0);
	}
}

@keyframes dropdown-slide {
	0% {
		opacity   : 0;
		transform : translateY(-50%);
	}
	100% {
		opacity   : 1;
		transform : translateY(0);
	}
}

@keyframes dropdown-display-up {
	0% {
		opacity   : 0;
		transform : scale(.98) translateY(.6em);
	}
	100% {
		opacity   : 1;
		transform : scale(1) translateY(0);
	}
}

blockquote {
	font-size    : 18px;
	font-weight  : 500;
	font-style   : normal;
	line-height  : 189%;
	position     : relative;
	max-width    : 830px;
	margin       : 42px 0;
	padding-left : 27px;
	color        : #000;
	@media screen and (min-width : $break-table) {
		margin : 28px 0;
	}
	@media screen and (min-width : $break-desktop) {
		margin : 48px 0;
	}

	&:after {
		content    : '';
		position   : absolute;
		top        : 5px;
		bottom     : 5px;
		left       : 0;
		width      : 3px;
		background : #000;

	}
}