// -----------------------------------------------
//  Form config
// -----------------------------------------------

$input-background          : transparent;
$input-border              : 1px solid #C1C1C1;
$input-padding             : 10px 10px;
$input-radius              : 10px;

$input-font-size           : 14px;

$input-color               : #4B4B4E;
$input-transition          : border-color .3s ease;

// Placeholder
$input-placeholder         : #4B4B4E;

// Focus
$input-border-focus        : #4B4B4E;

// Error
$input-border-error        : $red;

// Disabled
$input-border-disabled     : #BCBCBC;
$input-background-disabled : #F9F9F9;
$input-color-disabled      : #939393;

// -----------------------------------------------
//  Form style
// -----------------------------------------------
input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="password"],
select,
textarea {
	background-color   : $input-background;
	border             : $input-border;
	border-radius      : $input-radius;
	outline            : 0;
	height             : auto;
	line-height        : 1.3;
	color              : $input-color;
	max-width          : 100%;
	font-weight        : 400;
	transition         : $input-transition;
	padding            : $input-padding;
	font-size          : $input-font-size;
	-webkit-appearance : none;
	-webkit-box-sizing : border-box;
	-moz-box-sizing    : border-box;
	box-sizing         : border-box;
	@include placeholder() {
		font-weight : 500;
		color       : $input-placeholder;
	}

	&:focus {
		border-color : $input-border-focus;
	}

	&.error {
		border-color : $input-border-error;
		color        : $red;
	}

	&.disabled,
	&[disabled] {
		cursor           : default;
		background-color : $input-background-disabled;
		border-color     : $input-border-disabled;
		color            : $input-color-disabled;
	}

	&[readonly] {
		cursor           : default;
		background-color : $input-background-disabled;
		border-color     : $input-border-disabled;
		color            : $input-color-disabled;
	}
}

select {
	padding-right       : 35px;
	background-image    : url('../images/select.svg');
	background-position : 100% 50%;
	background-repeat   : no-repeat;
}

input[type="radio"],
input[type="checkbox"] {
	display        : inline-block;
	vertical-align : middle;
}

textarea {
	height   : auto;
	overflow : auto;
}

.error-message {
	color      : $red;
	margin-top : 5px;
	order      : 1;
}

label.checkbox-style,
.checkbox-style {
	font-weight                 : 400;
	position                    : relative;
	display                     : block;
	overflow                    : hidden;
	margin                      : 0;
	cursor                      : pointer;
	user-select                 : none;
	-webkit-tap-highlight-color : transparent;
	outline                     : 0;

	> span {
		position       : relative;
		display        : inline-block;
		vertical-align : top;
		padding        : 1px 2px 3px 28px;

		&:before {
			content       : '';
			position      : absolute;
			z-index       : 1;
			top           : 3px;
			left          : 5px;
			box-sizing    : border-box;
			width         : 16px;
			height        : 16px;
			background    : #FFF;
			box-shadow    : 0 2px 6px rgba(0, 0, 0, 0.15);
			border-radius : 5px;
			transition    : border-color .3s ease, background-color .3s ease;
		}

		&:after {
			content       : '';
			position      : absolute;
			z-index       : 5;
			top           : 6px;
			left          : 8px;
			opacity       : 0;
			transition    : opacity .3s ease;
			width         : 10px;
			height        : 10px;
			background    : $blue;
			border-radius : 3px;
		}
	}

	input[type="checkbox"] {
		position                    : absolute;
		top                         : 0;
		left                        : -30px;
		width                       : 1px;
		height                      : 1px;
		outline                     : 0;
		-webkit-tap-highlight-color : transparent;

		&:checked {
			~ span {
				&:before {
				}

				&:after {
					opacity : 1;
				}
			}
		}

		&.disabled,
		&[disabled] {
			~ span {
				&:after {
					background : #E3E3E3;
				}
			}
		}
	}
}